import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  Avatar,
  Chip,
  ChipColor,
  DetailRowCellEnum,
  DetailsRow,
  DetailsRowProps,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { DiffDetailType } from "../../models/DiffDetailType";
import { useBorder } from "../../../../utils/BorderUtils";
import { getDiffDetailRow } from "../../utils/DiffDetailCellUtils";
import { Module } from "../../../../routes/RoutesEnum";

export interface ContractNoteHistoryRowProps {
  index: number;
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  profilePhoto: string | undefined | null;
  titleText: string | undefined | null;
  requestedAt: string | undefined | null;
  chipText: string | undefined | null;
  backgroundColor: ChipColor | undefined;
  note: string | undefined | null;
  diffData: Instance<typeof DiffDetailType>[];
  module: Module;
}

const Size = {
  container: {
    width: "100%",
  },
  row: {
    maxWidth: "730px",
  },
};

export const ContractNoteHistoryRow = ({
  index,
  firstName,
  lastName,
  profilePhoto,
  titleText,
  requestedAt,
  chipText,
  backgroundColor,
  note,
  diffData,
  module,
}: ContractNoteHistoryRowProps): React.ReactElement => {
  const typography = useTypography();
  const border = useBorder();
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const { t } = useTranslation();

  const getRows = (): DetailsRowProps[] => {
    const rows: DetailsRowProps[] = [
      {
        label: "",
        cellType: {
          currentDetailsLabel: t(`contractNotes.sourceData`),
          updatedDetailsLabel: t(`contractNotes.suggestedEdits`),
          type: DetailRowCellEnum.DiffHeader,
        },
      },
    ];

    rows.push(...getDiffDetailRow(diffData, module));

    return rows;
  };

  return (
    <Stack
      key={index}
      width={Size.container.width}
      padding={spacing.spaceXL}
      sx={{ borderBottom: border.default }}
    >
      <Stack flexDirection={"row"}>
        <Avatar
          userName={`${firstName} ${lastName ?? ""}`}
          size="large"
          src={profilePhoto}
        />
        <Box sx={{ marginLeft: spacing.spaceMD }}>
          <Typography
            sx={{
              ...typography.sh3,
              color: tokens.labelHighEmphasis,
            }}
          >
            {titleText}
          </Typography>
          <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
            {requestedAt}
          </Typography>
        </Box>
      </Stack>
      <Stack
        padding={spacing.spaceXL}
        gap={spacing.spaceXL}
        marginLeft={spacing.space3XL}
      >
        {chipText && backgroundColor && (
          <Box>
            <Chip label={chipText} color={backgroundColor} />
          </Box>
        )}
        {note && (
          <Stack>
            <Typography {...typography.s1}>{t("common.note")}</Typography>
            <Typography {...typography.b1}>{note}</Typography>
          </Stack>
        )}
        {diffData.length > 0 && (
          <Stack maxWidth={Size.row.maxWidth}>
            {getRows().map(({ label, cellType }) => (
              <Box key={label} borderBottom={border.default}>
                <DetailsRow label={label} cellType={cellType} />
              </Box>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
