import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import {
  Button,
  LoadingIndicator,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import { useBorder } from "../../../../utils/BorderUtils";
import { useTranslation } from "react-i18next";
import { EmptyState } from "../emptyState/EmptyState";

export const Size = {
  loadingIndicatorSize: "16px",
};

export interface DataSectionItem {
  label: string;
  value: string | { label: string; value: string | undefined }[] | undefined;
  isSummary?: boolean;
  isBold?: boolean;
}

export interface DataSectionProps {
  title: string;
  items: DataSectionItem[];
  displayRows?: number;
  isLoading?: boolean;
  emptyStateMessage?: string;
}

export const DataSection = ({
  title,
  items,
  displayRows = 3,
  isLoading = false,
  emptyStateMessage,
}: DataSectionProps): React.ReactElement => {
  const { t } = useTranslation();
  const typography = useTypography();
  const spacing = useSpacing();
  const border = useBorder();
  const tokens = useFoundationColorTokens();
  const [showMore, setShowMore] = useState(false);
  const [showMoreButtonTitle, setShowMoreButtonTitle] = useState(
    t("common.viewMoreButtonTitle"),
  );

  const getItems = (): DataSectionItem[] => {
    if (showMore) {
      return items;
    } else {
      const filteredItems = items.filter((item) => item.isSummary === true);
      displayRows = filteredItems.length;
      return filteredItems;
    }
  };

  const emptyState = (): React.ReactElement => {
    if (isLoading && items.length === 0) {
      return (
        <Stack paddingBottom={spacing.spaceMD}>
          <LoadingIndicator
            isLoading={isLoading}
            variant={"container"}
            loadingText={t("common.loading")}
          />
        </Stack>
      );
    } else {
      return (
        <Stack paddingBottom={spacing.spaceMD}>
          <EmptyState message={emptyStateMessage} />
        </Stack>
      );
    }
  };

  return (
    <Stack spacing={spacing.spaceMD} borderBottom={border.default}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={`${spacing.spaceMD} 0 0 ${spacing.spaceMD}`}
      >
        <Typography sx={{ ...typography.sh3, color: tokens.labelHighEmphasis }}>
          {title}
        </Typography>
        <Stack direction={"row"} alignItems={"center"} width={"fit-content"}>
          {isLoading && items.length > 0 && (
            <LoadingIndicator
              isLoading={isLoading}
              variant={"container"}
              style={{
                height: Size.loadingIndicatorSize,
                width: Size.loadingIndicatorSize,
              }}
            />
          )}
          {items.length > displayRows && (
            <Stack sx={{ width: "fit-content", flexGrow: 1 }}>
              <Button
                label={showMoreButtonTitle}
                name={"viewMore"}
                size={"small"}
                variant={"plain-color"}
                onClick={() => {
                  if (showMore) {
                    setShowMore(false);
                    setShowMoreButtonTitle(t("common.viewMoreButtonTitle"));
                  } else {
                    setShowMore(true);
                    setShowMoreButtonTitle(t("common.viewLessButtonTitle"));
                  }
                }}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      {items.length === 0 && emptyState()}
      {getItems().map((item, index) => {
        if (index >= displayRows && !showMore) {
          return null;
        }
        return (
          <Stack
            key={index}
            direction={typeof item.value === "string" ? "row" : "column"}
            justifyContent={"space-between"}
            paddingX={spacing.spaceMD}
            paddingBottom={
              index === getItems().length - 1 ? spacing.spaceMD : "unset"
            }
          >
            <Typography
              sx={{
                typography: item.isBold ? typography.s2 : typography.b2,
                color: item.isBold ? tokens.labelHighEmphasis : tokens.label,
              }}
            >
              {item.label}
            </Typography>
            {typeof item.value === "string" ? (
              <Typography
                sx={{
                  typography: item.isBold ? typography.s2 : typography.b2,
                  color: item.isBold ? tokens.labelHighEmphasis : tokens.label,
                }}
              >
                {item.value}
              </Typography>
            ) : (
              (item.value as { label: string; value: string }[]).map(
                (i, idx) => (
                  <Stack
                    key={idx}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    padding={`${spacing.spaceSM} 0 0 ${spacing.spaceSM}`}
                    paddingBottom={
                      index === getItems().length - 1
                        ? spacing.spaceMD
                        : "unset"
                    }
                  >
                    <Typography
                      sx={{
                        typography: item.isBold ? typography.s2 : typography.b2,
                        color: item.isBold
                          ? tokens.labelHighEmphasis
                          : tokens.label,
                      }}
                    >
                      {i.label}
                    </Typography>
                    <Typography
                      sx={{
                        typography: item.isBold ? typography.s2 : typography.b2,
                        color: item.isBold
                          ? tokens.labelHighEmphasis
                          : tokens.label,
                      }}
                    >
                      {i.value}
                    </Typography>
                  </Stack>
                ),
              )
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
