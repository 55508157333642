import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  FileExtension,
  FixedDepositType,
  GetFixedDepositInvestmentRegisterDownloadURLRPC,
  GetFixedDepositInvestmentRegisterReportRPC,
  ModuleType,
  PeriodInput,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import {
  Instance,
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  types,
} from "mobx-state-tree";
import {
  useGetFixedDepositInvestmentRegisterDownloadURLRPCClientImpl,
  useGetFixedDepositInvestmentRegisterReportRPCClientImpl,
} from "../rpcs/RPC";
import { getInvestmentRegisterSummarySortOrder } from "../utils/SortUtils";
import {
  DateRangePickerInput,
  TableSortOption,
} from "@surya-digital/leo-reactjs-core";
import {
  PortfolioDropdownStore,
  createPortfolioDropdownStore,
} from "../../../components/portfolio/PortfolioDropdownStore";
import {
  EntityDropdownStore,
  createEntityDropdownStore,
} from "../../../components/entity/EntityDropdownStore";
import {
  ReportExportFormatEnum,
  getLeoDateOrNull,
  getIdOrNull,
  getDateOrNull,
} from "../../../utils/ReportUtils";
import { createPortfolioModel } from "../../../models/PortfolioModel";
import {
  FdInvestmentRegisterSummaryModel,
  createFdInvestmentRegisterSummaryModel,
} from "../models/FdInvestmentRegisterSummaryModel";
import i18next from "i18next";

export const FdInvestmentRegisterStore = types
  .model("FdInvestmentRegisterStore", {
    entityDropdownStore: EntityDropdownStore,
    portfolioDropdownStore: PortfolioDropdownStore,
    hasError: types.boolean,
    totalItems: types.number,
    downloadURL: types.maybe(types.string),
    fdInvestmentRegisterSummaryList: types.array(
      FdInvestmentRegisterSummaryModel,
    ),
    fromDate: types.maybe(types.string),
    toDate: types.maybe(types.string),
    isScreenLoading: types.boolean,
    isDataFetched: types.boolean,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    clearError: (): void => {
      store.hasError = false;
    },
    getInvestmentRegisterSummary: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      sort: TableSortOption | undefined,
      period?: PeriodInput.PeriodInput,
    ) {
      store.isScreenLoading = true;
      if (period instanceof PeriodInput.BetweenTwoDates) {
        store.toDate = period.endDate.date;
        store.fromDate = period.startDate.date;
      }
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        yield store.entityDropdownStore.getEntityList();
        yield store.portfolioDropdownStore.getPortfolioList(
          store.entityDropdownStore.entityList[0].id,
          ModuleType.ModuleType.FIXED_DEPOSIT,
        );
        const request = new GetFixedDepositInvestmentRegisterReportRPC.Request(
          getIdOrNull(store.entityDropdownStore.selectedEntity),
          getIdOrNull(store.portfolioDropdownStore.selectedPortfolio?.id),
          getLeoDateOrNull(store.fromDate),
          getLeoDateOrNull(store.toDate),
          pageIndex,
          itemsPerPage,
          getInvestmentRegisterSummarySortOrder(sort),
        );
        const result: LeoRPCResult<
          GetFixedDepositInvestmentRegisterReportRPC.Response,
          GetFixedDepositInvestmentRegisterReportRPC.Errors.Errors
        > =
          yield useGetFixedDepositInvestmentRegisterReportRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.totalItems = response.totalItems;
          store.entityDropdownStore.selectedEntity = response.entity.id.uuid;
          store.fromDate = response.fromDate.date;
          store.toDate = response.toDate.date;
          store.portfolioDropdownStore.selectedPortfolio = createPortfolioModel(
            response.portfolio,
          );
          store.fdInvestmentRegisterSummaryList = cast(
            response.investmentRegisterResponse.map((item) => {
              return createFdInvestmentRegisterSummaryModel(item);
            }),
          );
          store.isDataFetched = true;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetFixedDepositInvestmentRegisterReportRPC`,
          );
        }
        store.isScreenLoading = false;
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in getInvestmentRegisterSummary`,
          );
        }
        store.isScreenLoading = false;
      }
    }),
    getDownloadURL: flow(function* (fileExtention: string) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request =
          new GetFixedDepositInvestmentRegisterDownloadURLRPC.Request(
            new LeoUUID(store.entityDropdownStore.selectedEntity),
            new LeoUUID(store.portfolioDropdownStore.selectedPortfolio?.id),
            new LeoDate(store.fromDate),
            new LeoDate(store.toDate),
            fileExtention === ReportExportFormatEnum.CSV
              ? FileExtension.FileExtension.CSV
              : FileExtension.FileExtension.XLSX,
          );
        const result: LeoRPCResult<
          GetFixedDepositInvestmentRegisterDownloadURLRPC.Response,
          GetFixedDepositInvestmentRegisterDownloadURLRPC.Errors.Errors
        > =
          yield useGetFixedDepositInvestmentRegisterDownloadURLRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.downloadURL = response.downloadUrl.toString();
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetFixedDepositInvestmentRegisterDownloadURLRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetFixedDepositInvestmentRegisterDownloadURLRPC`,
          );
        }
      }
    }),
  }))
  .views((store) => ({
    itemsPerPage: (): number => 10,
    getFdTypeText: (
      fdType: FixedDepositType.FixedDepositType | undefined,
    ): string | undefined => {
      return fdType === FixedDepositType.FixedDepositType.CUMULATIVE
        ? i18next.t("fd.reports.cumulative")
        : fdType === undefined
          ? undefined
          : i18next.t("fd.reports.nonCumulative");
    },
    getIsCallableText: (
      isCallable: Boolean | undefined,
    ): string | undefined => {
      return isCallable === true
        ? i18next.t("fd.reports.true")
        : isCallable === undefined
          ? undefined
          : i18next.t("fd.reports.false");
    },
    getDateRange: (): DateRangePickerInput => {
      return {
        startDate: getDateOrNull(store.fromDate),
        endDate: getDateOrNull(store.toDate),
      };
    },
  }));

export const createFdInvestmentRegisterStore = (): Instance<
  typeof FdInvestmentRegisterStore
> => {
  return FdInvestmentRegisterStore.create({
    entityDropdownStore: createEntityDropdownStore(),
    portfolioDropdownStore: createPortfolioDropdownStore(),
    totalItems: 0,
    hasError: false,
    isScreenLoading: false,
    isDataFetched: false,
  });
};
