import React from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { InvestmentCashflowBannerStore } from "./InvestmentCashflowBannerStore";
import { observer } from "mobx-react";
import { ReportSummary } from "../reports/ReportSummary";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-core";
import { useBorder } from "../../../../utils/BorderUtils";
import styled from "@emotion/styled";

interface InvestmentCashflowBannerProps {
  store: Instance<typeof InvestmentCashflowBannerStore>;
  isSummaryHidden?: boolean;
  isSettledDetailsHidden?: boolean;
  isPendingDetailsHidden?: boolean;
}

const Size = {
  container: "100%",
  field: {
    width: "33%",
    height: "33%",
  },
};

export const InvestmentCashflowBanner = observer(
  ({
    store,
    isSummaryHidden = false,
    isSettledDetailsHidden = false,
    isPendingDetailsHidden = false,
  }: InvestmentCashflowBannerProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const spacing = useSpacing();
    const border = useBorder();
    const tokens = useFoundationColorTokens();
    const cornerRadius = useCornerRadius();

    const StyledStack = styled(Stack)(() => ({
      flexGrow: 1,
      padding: spacing.space2XL,
      borderRadius: cornerRadius.radiusXS,
      background: tokens.background,
      border: border.default,
    }));

    const HeaderTypography = styled(Typography)(() => ({
      typography: { ...typography.sh2 },
      color: tokens.label,
      borderRadius: `${cornerRadius.radiusXXS} ${cornerRadius.radiusXXS} 0 0`,
    }));

    const getStyledTypography = (
      value: number | string | undefined,
    ): React.ReactElement => {
      return (
        <Stack
          padding={spacing.spaceSM}
          alignItems="end"
          borderLeft={border.default}
        >
          <Typography
            sx={{
              ...typography.b2,
              color: tokens.labelHighEmphasis,
            }}
          >
            {value}
          </Typography>
        </Stack>
      );
    };

    const getAmountInfoField = (
      label: string,
      values: (string | undefined)[],
    ): React.ReactElement => {
      return (
        <Stack width={Size.field.width}>
          <Stack
            padding={spacing.spaceSM}
            borderBottom={border.default}
            borderLeft={border.default}
          >
            <Typography
              sx={{
                display: "flex",
                ...typography.s3,
                color: tokens.labelLowEmphasis,
                justifyContent: "flex-end",
              }}
            >
              {label}
            </Typography>
          </Stack>
          {/* If there are no units or there is no portfolio percentage then we are showing 0 */}
          {values.map((item) =>
            getStyledTypography(
              item ? t("common.decimal2", { val: item }) : "-",
            ),
          )}
        </Stack>
      );
    };

    const getStyledLabel = (label: string): React.ReactElement => {
      return (
        <Stack padding={spacing.spaceSM}>
          <Typography
            sx={{
              ...typography.s2,
              color: tokens.labelLowEmphasis,
            }}
          >
            {label}
          </Typography>
        </Stack>
      );
    };

    return (
      <Stack spacing={spacing.space2XL}>
        {!isSummaryHidden && !store.isLoading && (
          <Stack width={Size.container}>
            <ReportSummary
              summary={[
                {
                  title: `${t(
                    "common.investmentCashflowBanner.totalInvestedValue",
                  )} (${store.totalInvestedValue?.currency?.symbol})`,
                  value: t("common.decimal2", {
                    val: store.totalInvestedValue?.amount,
                  }),
                },
                {
                  title: `${t(
                    "common.investmentCashflowBanner.amountPaidOut",
                  )} (${store.amountPaidOut?.currency?.symbol})`,
                  value: t("common.decimal2", {
                    val: store.amountPaidOut?.amount,
                  }),
                },
                {
                  title: `${t(
                    "common.investmentCashflowBanner.accrualsTillDate",
                  )} (${store.accrualTillDate?.currency?.symbol})`,
                  value: t("common.decimal2", {
                    val: store.accrualTillDate?.amount,
                  }),
                },
              ]}
            />
          </Stack>
        )}
        {
          <Stack
            width={Size.container}
            flexWrap={"wrap"}
            direction={"row"}
            spacing={spacing.space2XL}
            display={
              isSettledDetailsHidden && isPendingDetailsHidden
                ? "none"
                : undefined
            }
          >
            {!isSettledDetailsHidden && !store.isLoading && (
              <StyledStack>
                <HeaderTypography>
                  {t("common.investmentCashflowBanner.settledCashflowTitle")}
                </HeaderTypography>
                <Stack flexDirection="row">
                  <Stack width={Size.field.width}>
                    <Stack
                      height={Size.field.height}
                      borderBottom={border.default}
                    />
                    {getStyledLabel(
                      t("common.investmentCashflowBanner.coupon"),
                    )}
                    {getStyledLabel(
                      t("common.investmentCashflowBanner.principal"),
                    )}
                  </Stack>
                  {getAmountInfoField(
                    t("common.investmentCashflowBanner.received"),
                    [
                      store.settledCashflow?.couponReceived?.amount,
                      store.settledCashflow?.principalReceived?.amount,
                    ],
                  )}
                  {getAmountInfoField(
                    t("common.investmentCashflowBanner.tds"),
                    [
                      store.settledCashflow?.couponTds?.amount,
                      store.settledCashflow?.principalTds?.amount,
                    ],
                  )}
                </Stack>
              </StyledStack>
            )}
            {!isPendingDetailsHidden && !store.isLoading && (
              <StyledStack>
                <HeaderTypography>
                  {t("common.investmentCashflowBanner.pendingCashflowTitle")}
                </HeaderTypography>
                <Stack flexDirection="row">
                  <Stack width={Size.field.width}>
                    <Stack
                      height={Size.field.height}
                      borderBottom={border.default}
                    />
                    {getStyledLabel(
                      t("common.investmentCashflowBanner.booked"),
                    )}
                    {getStyledLabel(
                      t("common.investmentCashflowBanner.pending"),
                    )}
                  </Stack>
                  {getAmountInfoField(
                    t("common.investmentCashflowBanner.accruedCoupon"),
                    [
                      store.pendingCashflow?.accruedCouponBooked?.amount,
                      store.pendingCashflow?.accruedCouponPending?.amount,
                    ],
                  )}
                  {getAmountInfoField(
                    t("common.investmentCashflowBanner.accruedTDS"),
                    [
                      store.pendingCashflow?.accruedTdsBooked?.amount,
                      store.pendingCashflow?.accruedTdsPending?.amount,
                    ],
                  )}
                </Stack>
              </StyledStack>
            )}
          </Stack>
        }
      </Stack>
    );
  },
);
