import React, { useState } from "react";
import { observer } from "mobx-react";
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import {
  TableHeader,
  TableOptions,
  TableRowItems,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-core";
import {
  AutoCompleteInputFieldSeparateLabel,
  Button,
  Dialog,
  Table,
} from "@surya-digital/leo-reactjs-material-ui";
import enZALocale from "date-fns/locale/en-ZA";
import { useTranslation } from "react-i18next";
import { useFiViewCashflowsStore } from "../store/hooks";
import { useBorder } from "../../../../../utils/BorderUtils";
import { NoteTextArea } from "../../../components/NoteTextArea";
import { DATE_PICKER_FORMAT, getFormattedDate } from "../../../../../utils";
import { DatePickerFieldSeparateLabel } from "../../../components/date-picker/DatePickerFieldSeparateLabel";
import { FiUserPrivileges } from "../../../../user/UserPrivileges";
import { useUserStore } from "../../../store/hooks";

interface CashflowTableProps {
  requestId: number | undefined;
  getDetails: () => Promise<void>;
}

const Size = {
  containerWidth: "100%",
  halfWidth: "50%",
  table: {
    default: "max-content",
  },
  styledTypography: {
    container: "100%",
    header1: "200px",
    header2: "200px",
  },
  dialogWidth: "660px",
};

export const FiCashflowTable = observer(
  ({ requestId, getDetails }: CashflowTableProps): React.ReactElement => {
    const spacing = useSpacing();
    const { t } = useTranslation();
    const store = useFiViewCashflowsStore();
    const [isCashflowDialogOpen, setIsCashflowDialogOpen] = useState(false);
    const [isCashflowDialogConfirmation, setIsCashflowDialogConfirmation] =
      useState(false);
    const cornerRadius = useCornerRadius();
    const tokens = useFoundationColorTokens();
    const typography = useTypography();
    const border = useBorder();
    const privileges = useUserStore().privileges;

    const getCashflowData = async (
      option: TableOptions<undefined>,
      setTotalItems: React.Dispatch<React.SetStateAction<number>>,
    ): Promise<string | TableRowItems> => {
      store.cashflowsStore.setCashflowPage(option.page ? option.page - 1 : 0);
      await store.cashflowsStore.getCashflows(requestId);
      setTotalItems(store.cashflowsStore.cashflowTotalItems);
      return store.cashflowsStore.cashflowList.map((cashflows) => {
        return [
          {
            data: cashflows.cashFlowId,
          },
          {
            data: getFormattedDate(cashflows.cashflowDate),
          },
          {
            data: cashflows.cashflowType,
          },
          {
            data: t("common.decimal2", { val: cashflows.rate }),
            align: "right",
          },
          {
            data: cashflows.isCredited
              ? `+${t("common.decimal2", { val: cashflows.amountCCY })}`
              : t("common.decimal2", { val: cashflows.amountCCY }),
            align: "right",
          },
          {
            data: cashflows.settlementDate
              ? getFormattedDate(cashflows.settlementDate)
              : "-",
          },
          {
            data: cashflows.settlementBank ?? "-",
          },
          {
            data: cashflows.settlementBankAccountNumber ?? "-",
          },
          {
            data: (
              <Stack width={Size.containerWidth} alignItems={"center"}>
                <Button
                  name="settle"
                  size="medium"
                  variant="filled"
                  color="primary"
                  onClick={async (): Promise<void> => {
                    store.cashflowsStore.resetCashflowDetail();
                    setIsCashflowDialogOpen(true);
                    store.cashflowsStore.setSelectedCashflow(cashflows);
                    await store.cashflowsStore.getCashflowDetail();
                  }}
                  disabled={
                    cashflows.settlementDate !== undefined ||
                    !privileges.includes(
                      FiUserPrivileges.SettleFixedIncomeCashFlow,
                    )
                  }
                  label={
                    !cashflows.settlementDate
                      ? t("cashflow.cashflowTable.settleButtonTitle")
                      : t("cashflow.cashflowTable.settledButtonTitle")
                  }
                />
              </Stack>
            ),
          },
        ];
      });
    };

    const getCashflowHeaders = (): TableHeader => {
      return [
        {
          id: "id",
          name: t("cashflow.cashflowTable.headers.id"),
          width: Size.table.default,
        },
        {
          id: "cashflowDate",
          name: t("cashflow.cashflowTable.headers.cashFlowDate"),
          width: Size.table.default,
        },
        {
          id: "cashflowType",
          name: t("cashflow.cashflowTable.headers.cashFlowType"),
          width: Size.table.default,
        },
        {
          id: "rate",
          name: t("cashflow.cashflowTable.headers.rate"),
          width: Size.table.default,
          align: "right",
        },
        {
          id: "amount",
          name: `${t("cashflow.cashflowTable.headers.amount")} (${store
            .cashflowsStore.currency?.symbol})`,
          width: Size.table.default,
          align: "right",
        },
        {
          id: "settlementDate",
          name: t("cashflow.cashflowTable.headers.settlementDate"),
          width: Size.table.default,
        },
        {
          id: "settlementBank",
          name: t("cashflow.cashflowTable.headers.settlementBank"),
          width: Size.table.default,
        },
        {
          id: "settlementBankAccountNumber",
          name: t("cashflow.cashflowTable.headers.settlementBankAccount"),
          width: Size.table.default,
        },
        {
          id: "settleButton",
          name: "",
          width: Size.table.default,
        },
      ];
    };

    const getCouponAccrualHeaders = (): TableHeader => {
      return [
        {
          id: "id",
          name: t("cashflow.accrualsTable.headers.id"),
          width: Size.table.default,
        },
        {
          id: "accrualType",
          name: t("cashflow.accrualsTable.headers.accrualType"),
          width: Size.table.default,
        },
        {
          id: "postedDate",
          name: t("cashflow.accrualsTable.headers.postedDate"),
          width: Size.table.default,
        },
        {
          id: "amount",
          name: `${t("cashflow.accrualsTable.headers.amount")} (${store
            .cashflowsStore.cashflowDetails?.amount.currency?.symbol})`,
          width: Size.table.default,
          align: "right",
        },
      ];
    };

    const getCouponAccrualData = async (): Promise<string | TableRowItems> => {
      return (
        store.cashflowsStore.cashflowDetails?.accruals.map((accrual) => {
          return [
            {
              data: accrual.accrualId,
            },
            {
              data: accrual.accrualType,
            },
            {
              data: getFormattedDate(accrual.accrualPostedDate),
            },
            {
              data: t("common.decimal2", { val: accrual.accrualAmount.amount }),
              align: "right",
            },
          ];
        }) ?? []
      );
    };

    const CashflowDialog = observer((): React.ReactElement => {
      const isPrincipal =
        store.cashflowsStore.cashflowDetails?.accruals.length === 0;
      const isUpdated =
        store.cashflowsStore.cashflowDetails?.bankId !==
          store.cashflowsStore.bank.selectedBankId ||
        store.cashflowsStore.cashflowDetails?.bankAccountNumber !==
          store.cashflowsStore.bankAccount.selectedBankAccount
            ?.bankAccountNumber;
      return (
        <Dialog
          dialogWidth={Size.dialogWidth}
          open={isCashflowDialogOpen}
          title={
            isCashflowDialogConfirmation || isPrincipal
              ? t("cashflow.dialogs.settleCashFlowTitle")
              : t("cashflow.dialogs.cashflowDialogTitle")
          }
          isCloseIconPresent={isCashflowDialogConfirmation}
          onClose={() => {
            setIsCashflowDialogOpen(false);
            setIsCashflowDialogConfirmation(false);
            store.accrualStore.setNote(undefined);
          }}
          onPrimaryButtonClick={async (): Promise<void> => {
            if (isCashflowDialogConfirmation || isPrincipal) {
              await store.cashflowsStore.settleCashflow();
              setIsCashflowDialogOpen(false);
              setIsCashflowDialogConfirmation(false);
              store.accrualStore.setNote(undefined);
              if (!store.error) {
                getDetails();
              }
            } else {
              setIsCashflowDialogConfirmation(true);
            }
          }}
          primaryButtonText={
            store.cashflowsStore.cashflowDetails === undefined
              ? undefined
              : isPrincipal || isCashflowDialogConfirmation
                ? t("cashflow.dialogs.settleButtonTitle")
                : t("common.confirm")
          }
          onSecondaryButtonClick={() => {
            store.accrualStore.setNote(undefined);
            if (isCashflowDialogConfirmation) {
              setIsCashflowDialogConfirmation(false);
            } else {
              setIsCashflowDialogOpen(false);
            }
          }}
          secondaryButtonText={
            store.cashflowsStore.cashflowDetails === undefined
              ? undefined
              : isCashflowDialogConfirmation
                ? t("common.cancel")
                : t("common.close")
          }
          isPrimaryButtonDisabled={
            store.cashflowsStore.bank.selectedBankId === undefined ||
            store.cashflowsStore.bankAccount.selectedBankAccount ===
              undefined ||
            store.cashflowsStore.settlementDate === undefined
          }
          disableBackdropClick={true}
        >
          {store.cashflowsStore.cashflowDetails === undefined ? (
            <Stack width={Size.containerWidth} alignItems={"center"}>
              <CircularProgress sx={{ color: tokens.iconPrimary }} />
            </Stack>
          ) : (
            <Stack spacing={spacing.spaceLG} flexGrow={1} flexBasis={0}>
              {(isCashflowDialogConfirmation || isPrincipal) && isUpdated && (
                <Alert
                  icon={false}
                  variant="filled"
                  sx={{
                    background: tokens.backgroundInfoSubtle,
                    border: border.infoSubtle,
                    boxSizing: "border-box",
                    color: tokens.labelInfo,
                    width: `${Size.containerWidth} !important`,
                    marginBottom: spacing.spaceLG,
                    borderRadius: `${cornerRadius.radiusXXS} !important`,
                    ...typography.b1,
                  }}
                >
                  {t("cashflow.dialogs.accountChangeInfoMessage")}
                </Alert>
              )}
              {!isCashflowDialogConfirmation && (
                <Stack
                  direction={"row"}
                  width={Size.containerWidth}
                  spacing={spacing.spaceSM}
                  flexGrow={1}
                  flexBasis={0}
                >
                  <Stack flexGrow={1} flexBasis={0}>
                    <AutoCompleteInputFieldSeparateLabel
                      id={"bank"}
                      label={t("cashflow.dialogs.bankTitle")}
                      placeholder={t("cashflow.dialogs.bankPlaceholder")}
                      isRequired
                      value={store.cashflowsStore.getBank()}
                      options={store.cashflowsStore.bank.bankList.map(
                        (bank) => {
                          return { id: bank.id, label: bank.name };
                        },
                      )}
                      error={store.cashflowsStore.bank.error !== undefined}
                      helperText={store.cashflowsStore.bank.error}
                      isLoading={store.cashflowsStore.bank.isLoading}
                      loadingText={t("common.loading")}
                      onSelect={(bank) => {
                        if (typeof bank !== "string") {
                          store.cashflowsStore.bank.setError(undefined);
                          store.cashflowsStore.setBank(bank?.id ?? "");
                        }
                      }}
                    />
                  </Stack>
                  <Stack flexGrow={1} flexBasis={0}>
                    <AutoCompleteInputFieldSeparateLabel
                      id={"paymentBankAccount"}
                      label={t("cashflow.dialogs.bankAccountNumberTitle")}
                      placeholder={t(
                        "cashflow.dialogs.bankAccountNumberPlaceholder",
                      )}
                      isRequired
                      value={{
                        id:
                          store.cashflowsStore.bankAccount.selectedBankAccount
                            ?.bankAccountNumber ?? "",
                        label:
                          store.cashflowsStore.bankAccount.selectedBankAccount
                            ?.bankAccountNumber ?? "",
                      }}
                      options={store.cashflowsStore.bankAccount.bankAccountList.map(
                        (account) => {
                          return {
                            id: account.bankAccountNumber,
                            label: account.bankAccountNumber,
                          };
                        },
                      )}
                      isLoading={store.cashflowsStore.bankAccount.isLoading}
                      isDisabled={!store.cashflowsStore.bank.selectedBankId}
                      loadingText={t("common.loading")}
                      onSelect={(value) => {
                        if (typeof value !== "string") {
                          store.cashflowsStore.bankAccount.setError(undefined);
                          store.cashflowsStore.bankAccount.setSelectedAccount(
                            value?.id ?? undefined,
                          );
                        }
                      }}
                    />
                  </Stack>
                </Stack>
              )}
              {!isCashflowDialogConfirmation && (
                <Stack width={Size.halfWidth} paddingRight={spacing.spaceXS}>
                  <DatePickerFieldSeparateLabel
                    isRequired={true}
                    label={t("cashflow.dialogs.settlementDateTitle")}
                    value={store.cashflowsStore.settlementDate ?? null}
                    onChange={store.cashflowsStore.setSettlementDate}
                    locale={enZALocale}
                    isDisabled={false}
                    onError={() => {}}
                    format={DATE_PICKER_FORMAT}
                  />
                </Stack>
              )}
              <Stack>
                <Stack spacing={spacing.spaceXS}>
                  <Typography sx={{ ...typography.s1 }}>
                    {isUpdated && isCashflowDialogConfirmation
                      ? t("cashflow.dialogs.updatedCashFlowDetailsTitle")
                      : t("cashflow.dialogs.expectedCashflowDetailsTitle")}
                  </Typography>
                  <Divider />
                </Stack>
                <Stack
                  sx={{
                    flexDirection: "row",
                    width: Size.styledTypography.container,
                    borderBottom: border.default,
                  }}
                >
                  <Typography
                    sx={{
                      ...typography.s1,
                      width: Size.styledTypography.header1,
                      padding: `${spacing.spaceMD} 0`,
                      color: tokens.labelLowEmphasis,
                    }}
                  >
                    {`${t("common.amount")} (${
                      store.cashflowsStore.cashflowDetails.amount.currency
                        .symbol
                    })`}
                  </Typography>
                  <Typography
                    sx={{
                      ...typography.b1,
                      width: Size.styledTypography.header2,
                      padding: `${spacing.spaceMD} ${spacing.spaceSM}`,
                      color: tokens.label,
                      textAlign: "left",
                    }}
                  >
                    {t("common.decimal2", {
                      val: store.cashflowsStore.cashflowDetails.amount.amount,
                    })}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    flexDirection: "row",
                    width: Size.styledTypography.container,
                    borderBottom: border.default,
                  }}
                >
                  <Typography
                    sx={{
                      ...typography.s1,
                      width: Size.styledTypography.header1,
                      padding: `${spacing.spaceMD} 0`,
                      color: tokens.labelLowEmphasis,
                    }}
                  >
                    {isUpdated && isCashflowDialogConfirmation
                      ? t("cashflow.dialogs.updatedBankLabel")
                      : t("cashflow.dialogs.bankTitle")}
                  </Typography>
                  <Typography
                    sx={{
                      ...typography.b1,
                      width: Size.styledTypography.header2,
                      padding: `${spacing.spaceMD} ${spacing.spaceSM}`,
                      color: tokens.label,
                      textAlign: "left",
                    }}
                  >
                    {isUpdated && isCashflowDialogConfirmation
                      ? store.cashflowsStore.getBank()?.label
                      : store.cashflowsStore.cashflowDetails.bankName}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    flexDirection: "row",
                    width: Size.styledTypography.container,
                    borderBottom: border.default,
                  }}
                >
                  <Typography
                    sx={{
                      ...typography.s1,
                      width: Size.styledTypography.header1,
                      padding: `${spacing.spaceMD} 0`,
                      color: tokens.labelLowEmphasis,
                    }}
                  >
                    {isUpdated && isCashflowDialogConfirmation
                      ? t("cashflow.dialogs.updatedBankAccountNumberLabel")
                      : t("cashflow.dialogs.bankAccountNumberLabel")}
                  </Typography>
                  <Typography
                    sx={{
                      ...typography.b1,
                      width: Size.styledTypography.header2,
                      padding: `${spacing.spaceMD} ${spacing.spaceSM}`,
                      color: tokens.label,
                      textAlign: "left",
                    }}
                  >
                    {isUpdated && isCashflowDialogConfirmation
                      ? store.cashflowsStore.bankAccount.selectedBankAccount
                          ?.bankAccountNumber
                      : store.cashflowsStore.cashflowDetails.bankAccountNumber}
                  </Typography>
                </Stack>
              </Stack>
              {isPrincipal || isCashflowDialogConfirmation ? (
                <NoteTextArea
                  note={store.cashflowsStore.note}
                  setNote={store.cashflowsStore.setNote}
                />
              ) : (
                <Stack spacing={spacing.spaceSM}>
                  <Stack spacing={spacing.spaceXS}>
                    <Typography sx={{ ...typography.s1 }}>
                      {t("cashflow.dialogs.couponAccrualDetailsTitle")}
                    </Typography>
                    <Divider />
                  </Stack>
                  <Table
                    name={"couponAccrualDetails"}
                    headers={getCouponAccrualHeaders()}
                    onTableOptionsChange={getCouponAccrualData}
                    viewOverrides={{
                      empty: { message: t("common.noResultsFound") },
                      idle: { message: t("common.searchTableIdleState") },
                      loading: { message: t("common.searchTableLoadingState") },
                    }}
                    styleOverrides={{
                      divider: "cell",
                    }}
                    onRowClick={undefined}
                  />
                </Stack>
              )}
            </Stack>
          )}
        </Dialog>
      );
    });

    return (
      <>
        <CashflowDialog />
        <Box borderTop={border.lowEmphasis}>
          <Table
            key="Cashflow"
            name="fixedIncomeContractNotesTable"
            styleOverrides={{
              divider: "cell",
              border: "none",
            }}
            headers={getCashflowHeaders()}
            onTableOptionsChange={getCashflowData}
            viewOverrides={{
              empty: { message: t("common.noResultsFound") },
              idle: { message: t("common.searchTableIdleState") },
              loading: {
                message: t("cashflow.cashflowTable.searchTableLoadingState"),
              },
            }}
            paginationOption={{
              itemsPerPage: 10,
              getPageIndicatorText(startItem, endItem, totalItems): string {
                return t("common.paginationIndicationText", {
                  startItem,
                  endItem,
                  totalItems,
                });
              },
              initialPage: store.cashflowsStore.cashflowPageNumber + 1,
            }}
            filterOption={undefined}
            onRowClick={undefined}
          />
        </Box>
      </>
    );
  },
);
