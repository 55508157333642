import { Instance } from "mobx-state-tree";
import { DetailType } from "../models/DetailCellModel";
import {
  CompoundingFrequency,
  DetailCellType,
  EquityDealRequestStatus,
  EquityTransactionType,
  FDDealRequestStatus,
  FiContractNoteRequestStatus,
  FiDealRequestStatus,
  FiMaturityDealRequestStatus,
  FiTransactionType,
  InterestType,
  MFDealRequestStatus,
  MFTransactionType,
  PayoutFrequency,
  PeriodUnit,
} from "@khazana/khazana-rpcs";
import {
  getAmount,
  getAmountOrUndefined,
  getAmountStringOrHyphen,
  getFormattedAmountString,
  getFormattedDateOrHyphen,
  getFormattedTimeDateWithComma,
} from "../../../utils";
import {
  DetailRowCellEnum,
  DetailsRowProps,
} from "@surya-digital/leo-reactjs-material-ui";
import { Module } from "../../../routes/RoutesEnum";
import i18next from "i18next";
import { ChipColor } from "@surya-digital/leo-reactjs-core";
import { getEQStatusColor } from "../equity/deal-request/utils/UIUtils";
import {
  getFiMaturityStatusColor,
  getFiStatusColor,
} from "../fixed-income/deal-request/utils/UIUtils";
import {
  getFDRequestStatusValue,
  getFdStatusColor,
} from "../fixed-deposit/deal-request/utils/UIUtils";
import { getFiContractNoteRequestStatusValue } from "../fixed-income/contract-note/utils/SearchUtils";
import {
  getMFRequestStatusValue,
  getMFStatusColor,
} from "../mutual-fund/deal-request/utils/UIUtils";
import { getRequestStatusValue } from "../equity/utils/SearchUtils";
import { createCurrencyModel, CurrencyModel } from "../models/CurrencyModel";
import { getMaturityRequestStatusValue } from "../fixed-income/deal-request/utils/SearchUtils";

export interface Duration {
  duration: number;
  unit: PeriodUnit.PeriodUnit;
}

export const getCompoundingFrequencyName = (
  key: CompoundingFrequency.CompoundingFrequency,
): string => {
  switch (key) {
    case CompoundingFrequency.CompoundingFrequency.MONTHLY:
      return i18next.t("frequencyEnumValues.monthly");
    case CompoundingFrequency.CompoundingFrequency.ANNUALLY:
      return i18next.t("frequencyEnumValues.annually");
    case CompoundingFrequency.CompoundingFrequency.HALF_YEARLY:
      return i18next.t("frequencyEnumValues.halfYearly");
    case CompoundingFrequency.CompoundingFrequency.QUARTERLY:
      return i18next.t("frequencyEnumValues.quarterly");
  }
};

export const getPeriodUnitName = (key: PeriodUnit.PeriodUnit): string => {
  switch (key) {
    case PeriodUnit.PeriodUnit.DAYS:
      return i18next.t("periodUnitEnumValues.days");
    case PeriodUnit.PeriodUnit.MONTHS:
      return i18next.t("periodUnitEnumValues.months");
    case PeriodUnit.PeriodUnit.YEARS:
      return i18next.t("periodUnitEnumValues.years");
  }
};

export const getInterestTypeName = (key: InterestType.InterestType): string => {
  switch (key) {
    case InterestType.InterestType.CUMULATIVE:
      return i18next.t("interestTypeEnumValues.cumulative");
    case InterestType.InterestType.SIMPLE:
      return i18next.t("interestTypeEnumValues.simple");
  }
};

export const getPayoutFrequencyName = (
  key: PayoutFrequency.PayoutFrequency,
): string => {
  switch (key) {
    case PayoutFrequency.PayoutFrequency.BULLET:
      return i18next.t("frequencyEnumValues.bullet");
    case PayoutFrequency.PayoutFrequency.MONTHLY:
      return i18next.t("frequencyEnumValues.monthly");
    case PayoutFrequency.PayoutFrequency.ANNUALLY:
      return i18next.t("frequencyEnumValues.annually");
    case PayoutFrequency.PayoutFrequency.HALF_YEARLY:
      return i18next.t("frequencyEnumValues.halfYearly");
    case PayoutFrequency.PayoutFrequency.QUARTERLY:
      return i18next.t("frequencyEnumValues.quarterly");
  }
};

export type CellType =
  | string
  | number
  | Duration
  | InterestType.InterestType
  | CompoundingFrequency.CompoundingFrequency
  | undefined;

export const getCellValueFromCellType = (
  cellType: DetailCellType.DetailCellType,
): {
  value: CellType;
  displayValue?: string;
  hidden: boolean;
  currency: Instance<typeof CurrencyModel> | undefined;
} => {
  let value: CellType;
  let isHidden = false;
  let displayValue: string | undefined;
  let currency: Instance<typeof CurrencyModel> | undefined;
  if (cellType instanceof DetailCellType.TransactionType) {
    value = cellType.text;
    displayValue = cellType.text;
  }
  if (cellType instanceof DetailCellType.StatusType) {
    value = cellType.text;
    displayValue = cellType.text;
  }
  if (cellType instanceof DetailCellType.QuantityType) {
    if (cellType.quantity) {
      value = getAmountStringOrHyphen(cellType.quantity.quantity);
      displayValue = getFormattedAmountString(cellType.quantity.quantity);
    }
  }
  if (cellType instanceof DetailCellType.BigQuantityType) {
    value = i18next.t("common.decimal4", {
      val: cellType.quantity?.decimalValue,
    });
    displayValue = i18next.t("common.decimal4", {
      val: cellType.quantity?.decimalValue,
    });
  }
  if (cellType instanceof DetailCellType.BigAmountType) {
    value = i18next.t("common.decimal2", {
      val: cellType.amount?.amount.decimalValue,
    });
    displayValue = i18next.t("common.decimal2", {
      val: cellType.amount?.amount.decimalValue,
    });
    currency = cellType.amount
      ? createCurrencyModel(cellType.amount.currency)
      : undefined;
  }
  if (cellType instanceof DetailCellType.PercentageType) {
    value = getAmountStringOrHyphen(
      cellType.percentage ? getAmount(cellType.percentage) : undefined,
      2,
      2,
    );
    displayValue = getAmountStringOrHyphen(
      cellType.percentage ? getAmount(cellType.percentage) : undefined,
      2,
      2,
    );
  }
  if (cellType instanceof DetailCellType.AmountType) {
    value = "-";
    displayValue = "-";
    if (cellType.amount) {
      value = getAmountStringOrHyphen(getAmount(cellType.amount.amount), 2, 2);
      displayValue = getAmountStringOrHyphen(
        getAmount(cellType.amount.amount),
        2,
        2,
      );
      currency = createCurrencyModel(cellType.amount.currency);
    }
  }
  if (cellType instanceof DetailCellType.Unstyled) {
    value = cellType.text ?? undefined;
    displayValue = cellType.text ?? undefined;
    isHidden = cellType.hidden;
  }
  if (cellType instanceof DetailCellType.Time) {
    value = cellType.time.timestamp;
    displayValue = getFormattedTimeDateWithComma(
      new Date(cellType.time.timestamp),
    );
  }
  if (cellType instanceof DetailCellType.Date) {
    const date = cellType.date ? new Date(cellType.date.date) : undefined;
    value = getFormattedDateOrHyphen(date);
    displayValue = getFormattedDateOrHyphen(date);
  }
  if (cellType instanceof DetailCellType.Duration) {
    value = { duration: cellType.duration, unit: cellType.unit };
    displayValue = `${cellType.duration} ${getPeriodUnitName(cellType.unit)}`;
  }
  if (cellType instanceof DetailCellType.Interest) {
    value = cellType.interestType;
    displayValue = getInterestTypeName(cellType.interestType);
  }
  if (cellType instanceof DetailCellType.PayoutFrequencyType) {
    value = cellType.frequency;
    displayValue = getPayoutFrequencyName(cellType.frequency);
  }
  if (cellType instanceof DetailCellType.CompoundingFrequencyType) {
    value = cellType.frequency;
    displayValue = getCompoundingFrequencyName(cellType.frequency);
  }
  if (cellType instanceof DetailCellType.TranslationKey) {
    /* @ts-ignore */
    value = i18next.t(cellType.key);
    displayValue = value as string;
  }
  if (cellType instanceof DetailCellType.None) {
    value = undefined;
    displayValue = undefined;
  }
  return { value, displayValue, hidden: isHidden, currency };
};

export const getDetailCellValue = (
  items: Instance<typeof DetailType>[],
  key: string,
): {
  value: CellType;
  hidden: boolean;
  currency: Instance<typeof CurrencyModel> | undefined;
} => {
  const cell = items.find((item) => item.label === key);
  if (!cell) {
    return { value: undefined, hidden: true, currency: undefined };
  }

  return getCellValueFromCellType(cell.cellType);
};

export const getTransactionTypeColor = (
  module: Module,
  transactionType: String,
): ChipColor => {
  switch (module) {
    case Module.Equity:
      return transactionType === EquityTransactionType.EquityTransactionType.BUY
        ? "blueSubtle"
        : "orangeSubtle";
    case Module.Fi:
      return transactionType === FiTransactionType.FiTransactionType.FI_PURCHASE
        ? "blueSubtle"
        : "orangeSubtle";
    case Module.Fd:
      return "neutral";
    case Module.MF:
      return transactionType === MFTransactionType.MFTransactionType.PURCHASE
        ? "blueSubtle"
        : "orangeSubtle";
  }
};

const getStatusTypeColor = (
  module: Module,
  status: string,
  isMaturity: boolean,
): ChipColor => {
  switch (module) {
    case Module.Equity:
      return getEQStatusColor(
        EquityDealRequestStatus.fromDTO({ case: status }),
      );
    case Module.Fi:
      if (isMaturity) {
        return getFiMaturityStatusColor(
          FiMaturityDealRequestStatus.fromDTO({ case: status }),
        );
      }
      return getFiStatusColor(FiDealRequestStatus.fromDTO({ case: status }));
    case Module.Fd:
      return getFdStatusColor(FDDealRequestStatus.fromDTO({ case: status }));
    case Module.MF:
      return getMFStatusColor(MFDealRequestStatus.fromDTO({ case: status }));
  }
};

const getStatusTypeName = (
  module: Module,
  status: string,
  isMaturity: boolean,
): string => {
  switch (module) {
    case Module.Equity:
      return getRequestStatusValue(
        i18next.t,
        EquityDealRequestStatus.fromDTO({ case: status }),
      );
    case Module.Fi:
      if (isMaturity) {
        return getMaturityRequestStatusValue(
          i18next.t,
          FiMaturityDealRequestStatus.fromDTO({ case: status }),
        );
      }
      return getFiContractNoteRequestStatusValue(
        FiContractNoteRequestStatus.fromDTO({ case: status }),
      );
    case Module.Fd:
      return getFDRequestStatusValue(
        FDDealRequestStatus.fromDTO({ case: status }),
      );
    case Module.MF:
      return getMFRequestStatusValue(
        MFDealRequestStatus.fromDTO({ case: status }),
      );
  }
};

export const getDetailCellRows = (
  items: Instance<typeof DetailType>[],
  module: Module,
  isMaturity = false,
): DetailsRowProps[] => {
  const rows: DetailsRowProps[] = [];
  items.forEach((item) => {
    if (item.cellType instanceof DetailCellType.TransactionType) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Chip,
          chips: [
            {
              label: item.cellType.text,
              color: getTransactionTypeColor(module, item.cellType.text),
            },
          ],
        },
      });
    }
    if (item.cellType instanceof DetailCellType.StatusType) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Chip,
          chips: [
            {
              label: getStatusTypeName(
                module,
                item.cellType.text,
                isMaturity,
              ).toUpperCase(),
              color: getStatusTypeColor(module, item.cellType.text, isMaturity),
            },
          ],
        },
      });
    }
    if (item.cellType instanceof DetailCellType.QuantityType) {
      const quantity = item.cellType.quantity;
      if (quantity === null) {
        rows.push({
          /* @ts-ignore */
          label: i18next.t(item.label, { currencySymbol: undefined }),
          cellType: {
            type: DetailRowCellEnum.Unstyled,
            text: "-",
          },
        });
      } else {
        rows.push({
          /* @ts-ignore */
          label: i18next.t(item.label),
          cellType: {
            type: DetailRowCellEnum.Unstyled,
            text: getFormattedAmountString(quantity.quantity),
          },
        });
      }
    }
    if (item.cellType instanceof DetailCellType.BigQuantityType) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Unstyled,
          text: i18next.t("common.decimal4", {
            val: item.cellType.quantity?.decimalValue,
          }),
        },
      });
    }
    if (item.cellType instanceof DetailCellType.PercentageType) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Unstyled,
          text: getAmountStringOrHyphen(
            getAmountOrUndefined(item.cellType.percentage),
            2,
            2,
          ),
        },
      });
    }
    if (item.cellType instanceof DetailCellType.AmountType) {
      const amount = item.cellType.amount;
      if (amount === null) {
        rows.push({
          /* @ts-ignore */
          label: i18next.t(item.label, { currencySymbol: undefined }),
          cellType: {
            type: DetailRowCellEnum.Unstyled,
            text: "-",
          },
        });
      } else {
        rows.push({
          /* @ts-ignore */
          label: i18next.t(item.label, {
            currencySymbol: `(${amount.currency.symbol})`,
          }),
          cellType: {
            type: DetailRowCellEnum.Unstyled,
            text: getAmountStringOrHyphen(
              getAmountOrUndefined(amount.amount),
              2,
              2,
            ),
          },
        });
      }
    }
    if (item.cellType instanceof DetailCellType.BigAmountType) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label, {
          val: item.cellType.amount?.currency.symbol,
        }),
        cellType: {
          type: DetailRowCellEnum.Unstyled,
          text: i18next.t("common.decimal2", {
            val: item.cellType.amount?.amount.decimalValue,
          }),
        },
      });
    }
    if (
      item.cellType instanceof DetailCellType.Unstyled &&
      !item.cellType.hidden
    ) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Unstyled,
          text: item.cellType.text ?? "-",
        },
      });
    }
    if (item.cellType instanceof DetailCellType.Time) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Unstyled,
          text: getFormattedTimeDateWithComma(
            new Date(item.cellType.time.timestamp),
          ),
        },
      });
    }
    if (item.cellType instanceof DetailCellType.Date) {
      const date = item.cellType.date
        ? new Date(item.cellType.date.date)
        : undefined;
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Unstyled,
          text: getFormattedDateOrHyphen(date),
        },
      });
    }
    if (item.cellType instanceof DetailCellType.Duration) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Unstyled,
          text: `${item.cellType.duration} ${getPeriodUnitName(
            item.cellType.unit,
          )}`,
        },
      });
    }
    if (item.cellType instanceof DetailCellType.Interest) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Unstyled,
          text: getInterestTypeName(item.cellType.interestType),
        },
      });
    }
    if (item.cellType instanceof DetailCellType.PayoutFrequencyType) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Unstyled,
          text: getPayoutFrequencyName(item.cellType.frequency),
        },
      });
    }
    if (item.cellType instanceof DetailCellType.CompoundingFrequencyType) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Unstyled,
          text: getCompoundingFrequencyName(item.cellType.frequency),
        },
      });
    }
    if (item.cellType instanceof DetailCellType.TranslationKey) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Unstyled,
          /* @ts-ignore */
          text: i18next.t(item.cellType.key),
        },
      });
    }
  });
  return rows;
};
