import { KHTimelineItem } from "../components/timeline/KHTimeline";
import { CashflowTimelineModel } from "../models/CashflowTimelineModel";
import { Instance } from "mobx-state-tree";
import { getFormattedDate } from "../../../utils";
import i18next from "i18next";

export const getTimelineItem = (
  items: Instance<typeof CashflowTimelineModel>[] = [],
): KHTimelineItem[] => {
  return items.map((item) => {
    let amount = "";
    let title = "";
    item.timelineAmount.map((amt, index) => {
      if (amt.isPositive) {
        if (index !== 0) {
          title = title.concat(" + ");
          amount = amount.concat(" + ");
        }
        title = title.concat(
          // @ts-ignore
          i18next.t(`${amt.localizedTextId}`, {
            symbol: amt.currency,
            val: amt.faceValue,
          }),
        );
        amount = amount.concat(
          i18next.t("common.decimal2WithCurrency", {
            symbol: amt.currency?.symbol,
            val: amt.amount,
          }),
        );
      } else {
        if (index !== 0) {
          title = title.concat(" - ");
          amount = amount = amount.concat(" - ");
        }
        title = title.concat(
          // @ts-ignore
          i18next.t(`${amt.localizedTextId}`, {
            symbol: amt.currency,
            val: amt.faceValue,
          }),
        );
        amount = amount.concat(
          i18next.t("common.decimal2WithCurrency", {
            symbol: amt.currency?.symbol,
            val: amt.amount,
          }),
        );
      }
    });
    return {
      date: getFormattedDate(item.cashflowDate),
      amount,
      title,
    };
  });
};
