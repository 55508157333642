import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { ModuleType, PeriodInput } from "@khazana/khazana-rpcs";
import { Period, getDateOrNull } from "../../../utils/ReportUtils";
import { ReportTablePage } from "../../../components/reports/ReportTablePage";
import { useEQHoldingSummaryStore } from "../store/hooks";
import { downloadFile } from "../../../../../utils/FileDownloadUtils";
import {
  DatePickerInput,
  LoadingIndicator,
  TableReloadHandle,
  TableSortOption,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  ReportTableData,
  ReportTableHeaderProps,
  ReportTableHeaderWidth,
} from "../../../components/reports/ReportTable";
import { useUserStore } from "../../../store/hooks";
import { EquityUserPrivileges } from "../../../../user/UserPrivileges";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { observer } from "mobx-react";
import { Stack } from "@mui/material";
import { DashboardLineGraph } from "../../../components/dashboard/DashboardLineGraph";
import { DataSeries } from "../../../components/charts/LineChart";
import { useSpacing } from "@surya-digital/leo-reactjs-core";
import { DashboardDoughnutGraph } from "../../../components/dashboard/DashboardDoughnutGraph";
import { DoughnutSeriesItem } from "../../../components/charts/DoughnutChart";
import { DashboardStatModel } from "../../../models/DashboardStatModel";
import { Instance } from "mobx-state-tree";
import {
  DashboardStats,
  DashboardStatsItem,
} from "../../../components/dashboard/DashboardStats";

export const EqHoldingSummary = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const spacing = useSpacing();
  const store = useEQHoldingSummaryStore();
  const privileges = useUserStore().privileges;
  const [viewFilter, setViewFilter] = useState(false);
  const tableRef = useRef<TableReloadHandle>();

  const getEqHoldingSummaryDetails = useCallback(async () => {
    await store.getHoldingSummary(undefined, 0, store.itemsPerPage());
    await store.getReturns();
    await store.getEquityHoldingDistributionByIsin();
    await store.dashboardStats.getEquityDashboardStats(
      store.entityDropdownStore.selectedEntity,
      store.portfolioDropdownStore.selectedPortfolio.id,
      store.asOnDate,
    );
  }, [
    store.entityDropdownStore.selectedEntity,
    store.portfolioDropdownStore.selectedPortfolio.id,
    store.asOnDate,
  ]);

  useEffect(() => {
    getEqHoldingSummaryDetails();
    return () => {
      store.reset();
    };
  }, []);

  const getStat = (
    label: string,
    stat: Instance<typeof DashboardStatModel>,
  ): DashboardStatsItem => {
    return {
      title: label,
      value: stat.amount,
      fullValue: stat.fullAmount,
      isPositive: stat.isGain,
      percentage: stat.percentage ? stat.percentage + "%" : undefined,
    };
  };

  const getStats = (): DashboardStatsItem[] => {
    const stats = store.dashboardStats;
    return [
      getStat(
        t("equity.dashboard.stats.investedValueLabel", {
          val: stats.investedValue.currency?.symbol,
        }),
        stats.investedValue,
      ),
      getStat(
        t("equity.dashboard.stats.marketValueLabel", {
          val: stats.investedValue.currency?.symbol,
        }),
        stats.marketValue,
      ),
      getStat(
        t("equity.dashboard.stats.todayPnlLabel", {
          val: stats.investedValue.currency?.symbol,
        }),
        stats.todayPnL,
      ),
      getStat(
        t("equity.dashboard.stats.unrealisedPnlLabel", {
          val: stats.investedValue.currency?.symbol,
        }),
        stats.unrealisedGain,
      ),
      getStat(t("equity.dashboard.stats.irrLabel"), stats.irr),
    ];
  };

  const headers: ReportTableHeaderProps[] = [
    {
      name: t("common.symbol"),
      width: ReportTableHeaderWidth.TableWidthMD,
      sortable: true,
    },
    {
      name: t("equity.reports.holdingSummaryHeaders.activeCost"),
      alignment: "right",
    },
    {
      name: t("equity.reports.holdingSummaryHeaders.activeMarketValue"),
      alignment: "right",
    },
    {
      name: t("equity.reports.holdingSummaryHeaders.netGain"),
      alignment: "right",
    },
    {
      name: t("equity.reports.holdingSummaryHeaders.change"),
      alignment: "right",
    },
    {
      name: t("equity.reports.holdingSummaryHeaders.irr"),
      alignment: "right",
    },
    {
      name: t("equity.reports.holdingSummaryHeaders.residualQuantity"),
      alignment: "right",
    },
    {
      name: t("equity.reports.holdingSummaryHeaders.activeUnrealizedGain"),
      alignment: "right",
    },
    {
      name: t("equity.reports.holdingSummaryHeaders.activeRealizedGain"),
      alignment: "right",
    },
  ];

  const getRows = async (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ): Promise<ReportTableData> => {
    if (
      store.entityDropdownStore.selectedEntity &&
      store.portfolioDropdownStore.selectedPortfolio
    ) {
      await store.getHoldingSummary(sort, page, itemsPerPage);
    }
    return {
      rows: store.eqHoldingSummaryList.map((item) => {
        return [
          item.symbol,
          item.activeCost,
          item.activeMarketValue,
          item.netGain,
          item.change,
          item.irr,
          item.residualQuantity,
          item.activeUnrealizedGain,
          item.activeRealizedGain,
        ];
      }),
      totalItems: store.totalItems,
    };
  };

  const getAsOnDate = (): DatePickerInput => {
    return getDateOrNull(store.asOnDate);
  };

  const getReturns = (): DataSeries[] => {
    return [
      {
        data: store.returns.map((item) => {
          return {
            x: item.date,
            y: item.percentage,
          };
        }),
        name: t("dashboard.returnsLabel"),
      },
    ];
  };

  const getHoldingDistribution = (): DoughnutSeriesItem[] => {
    return store.distribution.map((item) => {
      return {
        name: item.label,
        value: item.amount.amount,
      };
    });
  };

  const getAdditionalReportElements = (): React.ReactElement => {
    return (
      <>
        <DashboardStats key={"equityStats"} stats={getStats()} />
        <Stack spacing={spacing.spaceMD} direction={"row"}>
          <DashboardLineGraph
            title={t("dashboard.returnVsBenchmarkTitle")}
            series={getReturns()}
          />
          <Stack flexBasis={0}>
            <DashboardDoughnutGraph
              title={t("equity.dashboard.holdingsDistributionTitle")}
              series={getHoldingDistribution()}
            />
          </Stack>
        </Stack>
      </>
    );
  };

  return (
    <>
      {store.isScreenLoading && (
        <LoadingIndicator isLoading={store.isScreenLoading} />
      )}
      <ErrorDialog
        title={t("errors.internalServerError")}
        errorMessage={t("errors.internalServerErrorDescription")}
        isErrorDialogOpen={store.hasError}
        onClose={(): void => {
          store.clearError();
        }}
      />
      {store.isDataFetched && (
        <ReportTablePage
          pageHeader={{
            title: t("equity.reports.holdingSummary"),
          }}
          reloadTable={tableRef as RefObject<TableReloadHandle>}
          previousPage={{
            label: t("common.reports"),
            onLabelClick: (): void => {
              navigate(getPath(Module.Equity, Route.Reports), {
                replace: true,
              });
            },
          }}
          headers={headers}
          getRows={getRows}
          itemsPerPage={store.itemsPerPage()}
          isExportable={privileges.includes(
            EquityUserPrivileges.DownloadEquityHoldingSummary,
          )}
          onEditFilter={(open) => setViewFilter(open)}
          reportFilterDialogProps={{
            isAllOptionEnabled: true,
            open: viewFilter,
            onClose: () => setViewFilter(false),
            module: ModuleType.ModuleType.EQUITY,
            showReportType: false,
            period: {
              reportType: Period.AS_ON_DATE,
              asOnDate: getAsOnDate(),
              betweenTwoDatesDateRange: { startDate: null, endDate: null },
            },
            onViewReport: async (period): Promise<void> => {
              if (period instanceof PeriodInput.AsOnDate) {
                store.setAsOnDate(period.date);
              }
              tableRef.current?.reload();
              await getEqHoldingSummaryDetails();
              return setViewFilter(false);
            },
            entityDropdownStore: store.entityDropdownStore,
            portfolioDropdownStore: store.portfolioDropdownStore,
          }}
          onExport={async (exportFormat): Promise<void> => {
            if (
              store.entityDropdownStore.selectedEntity &&
              store.portfolioDropdownStore.selectedPortfolio &&
              store.asOnDate
            ) {
              await store.getDownloadURL(exportFormat);
              if (store.downloadURL) {
                downloadFile(store.downloadURL);
              }
            }
            return Promise.resolve();
          }}
          downloadButtonDisabled={store.eqHoldingSummaryList.length === 0}
          additionalSection={
            store.eqHoldingSummaryList.length > 0 ? (
              getAdditionalReportElements()
            ) : (
              <></>
            )
          }
        />
      )}
    </>
  );
});
