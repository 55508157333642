import { Instance } from "mobx-state-tree";
import { AutoCompleteItem, ChipColor } from "@surya-digital/leo-reactjs-core";
import { SchemeModel } from "../models/SchemeModel";
import {
  MFDealRequestStatus,
  MFDividendType,
  MFPlanType,
  MFRedeemType,
  MFSchemeType,
} from "@khazana/khazana-rpcs";
import i18next from "i18next";

export const getSchemeDropdownOptions = (
  schemeList: Instance<typeof SchemeModel>[],
): AutoCompleteItem[] => {
  return schemeList.map((scheme) => ({
    id: scheme.isin,
    label: scheme.name,
    subLabel: scheme.isin,
  }));
};

export const getRedeemTypeName = (type: MFRedeemType.MFRedeemType): string => {
  switch (type) {
    case MFRedeemType.MFRedeemType.ALL:
      return i18next.t("mf.redeemType.all");
    case MFRedeemType.MFRedeemType.AMOUNT:
      return i18next.t("mf.redeemType.amount");
    case MFRedeemType.MFRedeemType.UNITS:
      return i18next.t("mf.redeemType.units");
  }
};

export const getSchemeTypeName = (
  type: MFSchemeType.MFSchemeType | undefined,
): string => {
  if (!type) {
    return "";
  }
  switch (type) {
    case MFSchemeType.MFSchemeType.EQUITY:
      return i18next.t("mf.schemeType.equity");
    case MFSchemeType.MFSchemeType.DEBT:
      return i18next.t("mf.schemeType.debt");
    case MFSchemeType.MFSchemeType.HYBRID:
      return i18next.t("mf.schemeType.hybrid");
    case MFSchemeType.MFSchemeType.FUND_OF_FUNDS:
      return i18next.t("mf.schemeType.fundOfFunds");
    case MFSchemeType.MFSchemeType.INDEX_FUNDS:
      return i18next.t("mf.schemeType.indexFunds");
    case MFSchemeType.MFSchemeType.SOLUTION_ORIENTED:
      return i18next.t("mf.schemeType.solutionOriented");
  }
};

export const getDividendTypeName = (
  type: MFDividendType.MFDividendType | undefined,
): string => {
  if (!type) {
    return "";
  }
  switch (type) {
    case MFDividendType.MFDividendType.PAYOUT:
      return i18next.t("mf.dividendType.payout");
    case MFDividendType.MFDividendType.GROWTH:
      return i18next.t("mf.dividendType.growth");
  }
};

export const getPlanTypeName = (
  type: MFPlanType.MFPlanType | undefined,
): string => {
  if (!type) {
    return "";
  }
  switch (type) {
    case MFPlanType.MFPlanType.REGULAR:
      return i18next.t("mf.planType.regular");
    case MFPlanType.MFPlanType.DIRECT:
      return i18next.t("mf.planType.direct");
  }
};

export const getMFStatusColor = (
  status: MFDealRequestStatus.MFDealRequestStatus,
): ChipColor => {
  switch (status) {
    case MFDealRequestStatus.MFDealRequestStatus.ACCOUNTS_PENDING:
    case MFDealRequestStatus.MFDealRequestStatus.IC_PENDING:
    case MFDealRequestStatus.MFDealRequestStatus.IC_APPROVED:
    case MFDealRequestStatus.MFDealRequestStatus.DEAL_OPEN:
    case MFDealRequestStatus.MFDealRequestStatus.ACCOUNTS_APPROVED:
      return "orange";
    case MFDealRequestStatus.MFDealRequestStatus.CANCELLED:
    case MFDealRequestStatus.MFDealRequestStatus.EXPIRED:
      return "neutral";
    case MFDealRequestStatus.MFDealRequestStatus.ACCOUNTS_REJECTED:
    case MFDealRequestStatus.MFDealRequestStatus.IC_REJECTED:
      return "red";
    case MFDealRequestStatus.MFDealRequestStatus.SETTLED:
    case MFDealRequestStatus.MFDealRequestStatus.SYSTEM_SETTLED:
      return "green";
    default:
      return "neutral";
  }
};

export const getMFRequestStatusValue = (
  value: MFDealRequestStatus.MFDealRequestStatus,
): string => {
  const statusType = MFDealRequestStatus.MFDealRequestStatus;
  let requestStatus;
  switch (value) {
    case statusType.IC_PENDING:
      requestStatus = i18next.t("common.icPending");
      break;
    case statusType.IC_REJECTED:
      requestStatus = i18next.t("common.icRejected");
      break;
    case statusType.IC_APPROVED:
      requestStatus = i18next.t("common.icApproved");
      break;
    case statusType.ACCOUNTS_PENDING:
      requestStatus = i18next.t("common.accountsPending");
      break;
    case statusType.ACCOUNTS_APPROVED:
      requestStatus = i18next.t("common.accountsApproved");
      break;
    case statusType.ACCOUNTS_REJECTED:
      requestStatus = i18next.t("common.accountsRejected");
      break;
    case statusType.EXPIRED:
      requestStatus = i18next.t("common.expired");
      break;
    case statusType.CANCELLED:
      requestStatus = i18next.t("common.cancelled");
      break;
    case statusType.DEAL_OPEN:
      requestStatus = i18next.t("common.dealOpen");
      break;
    case statusType.SETTLED:
      requestStatus = i18next.t("common.settled");
      break;
    case statusType.SYSTEM_SETTLED:
      requestStatus = i18next.t("common.systemSettled");
      break;
  }
  return requestStatus;
};

export const getMFHistoryTitleText = (
  firstName: string,
  lastName: string | null,
  status: MFDealRequestStatus.MFDealRequestStatus,
): string => {
  switch (status) {
    case MFDealRequestStatus.MFDealRequestStatus.IC_PENDING:
      return i18next.t("common.hasRaisedARequest", {
        firstName,
        lastName,
      });
    case MFDealRequestStatus.MFDealRequestStatus.IC_APPROVED:
    case MFDealRequestStatus.MFDealRequestStatus.ACCOUNTS_PENDING:
      return i18next.t("common.hasApprovedTheDealRequest", {
        firstName,
        lastName,
      });
    case MFDealRequestStatus.MFDealRequestStatus.DEAL_OPEN:
      return i18next.t("common.hasPlacedAnOrder", {
        firstName,
        lastName,
      });
    case MFDealRequestStatus.MFDealRequestStatus.ACCOUNTS_APPROVED:
      return i18next.t("common.hasApprovedTheDealRequest", {
        firstName,
        lastName,
      });
    case MFDealRequestStatus.MFDealRequestStatus.IC_REJECTED:
    case MFDealRequestStatus.MFDealRequestStatus.ACCOUNTS_REJECTED:
      return i18next.t("common.hasRejectedTheDealRequest", {
        firstName,
        lastName,
      });
    case MFDealRequestStatus.MFDealRequestStatus.CANCELLED:
      return i18next.t("common.hasCancelledTheDealRequest", {
        firstName,
        lastName,
      });
    case MFDealRequestStatus.MFDealRequestStatus.EXPIRED:
      return i18next.t("common.hasMarkedTheDealExpired", {
        firstName,
        lastName,
      });
    case MFDealRequestStatus.MFDealRequestStatus.SETTLED:
    case MFDealRequestStatus.MFDealRequestStatus.SYSTEM_SETTLED:
      return i18next.t("common.hasSettledTheDeal", {
        firstName,
        lastName,
      });
  }
};
