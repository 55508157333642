"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFixedIncomeInvestmentRegisterSortOrder = exports.GetFixedIncomeInvestmentRegisterSortOrderEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const reportsSortOrder_1 = require("./reportsSortOrder");
class InvalidGetFixedIncomeInvestmentRegisterSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFixedIncomeInvestmentRegisterSortOrderError";
    }
}
var GetFixedIncomeInvestmentRegisterSortOrderEnums;
(function (GetFixedIncomeInvestmentRegisterSortOrderEnums) {
    let ColumnName;
    (function (ColumnName_1) {
        let ColumnName;
        (function (ColumnName) {
            ColumnName["SYMBOL"] = "SYMBOL";
            ColumnName["INSTRUMENT_TYPE"] = "INSTRUMENT_TYPE";
        })(ColumnName = ColumnName_1.ColumnName || (ColumnName_1.ColumnName = {}));
        ColumnName_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "SYMBOL":
                    return ColumnName.SYMBOL;
                case "INSTRUMENT_TYPE":
                    return ColumnName.INSTRUMENT_TYPE;
                default:
                    throw new InvalidGetFixedIncomeInvestmentRegisterSortOrderError(`Case ${dto.case} is not valid case of ColumnName`);
            }
        };
        ColumnName_1.toDTO = (columnName) => {
            const ret = {
                case: ColumnName[columnName],
            };
            return ret;
        };
        ColumnName_1.isInstanceOf = (other) => {
            if (other in ColumnName) {
                return true;
            }
            return false;
        };
    })(ColumnName = GetFixedIncomeInvestmentRegisterSortOrderEnums.ColumnName || (GetFixedIncomeInvestmentRegisterSortOrderEnums.ColumnName = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(GetFixedIncomeInvestmentRegisterSortOrderEnums = exports.GetFixedIncomeInvestmentRegisterSortOrderEnums || (exports.GetFixedIncomeInvestmentRegisterSortOrderEnums = {}));
class GetFixedIncomeInvestmentRegisterSortOrder {
    constructor(columnName, order) {
        this.isGetFixedIncomeInvestmentRegisterSortOrder = true;
        GetFixedIncomeInvestmentRegisterSortOrder.validateColumnName(columnName);
        this.columnName = columnName;
        GetFixedIncomeInvestmentRegisterSortOrder.validateOrder(order);
        this.order = order;
    }
    static fromDTO(dto) {
        const columnName = GetFixedIncomeInvestmentRegisterSortOrderEnums.ColumnName.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "columnName", InvalidGetFixedIncomeInvestmentRegisterSortOrderError));
        const order = reportsSortOrder_1.ReportsSortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "order", InvalidGetFixedIncomeInvestmentRegisterSortOrderError));
        return new GetFixedIncomeInvestmentRegisterSortOrder(columnName, order);
    }
    toDTO() {
        const ret = {
            columnName: GetFixedIncomeInvestmentRegisterSortOrderEnums.ColumnName.toDTO(this.columnName),
            order: reportsSortOrder_1.ReportsSortOrder.toDTO(this.order),
        };
        return ret;
    }
    copy(columnName = this.columnName, order = this.order) {
        return new GetFixedIncomeInvestmentRegisterSortOrder(columnName, order);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFixedIncomeInvestmentRegisterSortOrderValue = other.isGetFixedIncomeInvestmentRegisterSortOrder;
        if (!(other instanceof GetFixedIncomeInvestmentRegisterSortOrder) || Boolean(isGetFixedIncomeInvestmentRegisterSortOrderValue)) {
            return false;
        }
        if (this.columnName !== other.columnName) {
            return false;
        }
        if (this.order !== other.order) {
            return false;
        }
        return true;
    }
}
exports.GetFixedIncomeInvestmentRegisterSortOrder = GetFixedIncomeInvestmentRegisterSortOrder;
GetFixedIncomeInvestmentRegisterSortOrder.validateColumnName = (columnName) => {
    if (!GetFixedIncomeInvestmentRegisterSortOrderEnums.ColumnName.isInstanceOf(columnName)) {
        throw new InvalidGetFixedIncomeInvestmentRegisterSortOrderError(`Attribute columnName is not a GetFixedIncomeInvestmentRegisterSortOrderEnums.ColumnName`);
    }
};
GetFixedIncomeInvestmentRegisterSortOrder.validateOrder = (order) => {
    if (!reportsSortOrder_1.ReportsSortOrder.isInstanceOf(order)) {
        throw new InvalidGetFixedIncomeInvestmentRegisterSortOrderError(`Attribute order is not a ReportsSortOrder.ReportsSortOrder`);
    }
};
GetFixedIncomeInvestmentRegisterSortOrder.prototype.toString = function toString() {
    return `GetFixedIncomeInvestmentRegisterSortOrder(columnName=${this.columnName},order=${this.order})`;
};
