import React, { RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { ModuleType } from "@khazana/khazana-rpcs";
import { Period, getDateOrNull } from "../../../utils/ReportUtils";
import { ReportTablePage } from "../../../components/reports/ReportTablePage";
import {
  DatePickerInput,
  LoadingIndicator,
  TableReloadHandle,
  TableSortOption,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  ReportTableData,
  ReportTableHeaderProps,
  ReportTableHeaderWidth,
} from "../../../components/reports/ReportTable";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { observer } from "mobx-react";
import { downloadFile } from "../../../../../utils/FileDownloadUtils";
import { useMfInvestmentReturnsGrowthStore } from "../store/hooks";
import { Typography } from "@mui/material";
import { useFoundationColorTokens } from "@surya-digital/leo-reactjs-core";

export const MfInvestmentReturnsGrowth = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const store = useMfInvestmentReturnsGrowthStore();
  const [viewFilter, setViewFilter] = useState(false);
  const tableRef = useRef<TableReloadHandle>();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();

  useEffect(() => {
    store.getInvestmentReturnsGrowthSummary(0, store.itemsPerPage(), undefined);
    return () => {
      store.reset();
    };
  }, []);

  const headers: ReportTableHeaderProps[] = [
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.purchaseId"),
      sortable: true,
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.schemeName"),
      width: ReportTableHeaderWidth.TableWidthXL,
      sortable: true,
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.broker"),
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.entity"),
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.portfolio"),
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.investmentAmount"),
      alignment: "right",
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.totalUnits"),
      alignment: "right",
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.outstandingUnits"),
      alignment: "right",
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.navOnDoi"),
      alignment: "right",
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.purchaseDate"),
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.latestNavDate"),
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.latestNav"),
      alignment: "right",
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.noOfDays"),
      alignment: "right",
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.growthReturn"),
      alignment: "right",
    },
    {
      name: t("mf.reports.investmentReturnsGrowthHeaders.amountRealisable"),
      alignment: "right",
    },
  ];

  const getAsOnDate = (): DatePickerInput => {
    return getDateOrNull(store.asOnDate);
  };

  const getTypography = (
    value: string,
    isPositive?: boolean | undefined,
  ): React.ReactElement => {
    let color = tokens.label;
    if (isPositive === true) {
      color = tokens.labelSuccess;
    } else if (isPositive === false) {
      color = tokens.labelError;
    }
    return (
      <Typography sx={{ typography: typography.b1, color }}>{value}</Typography>
    );
  };

  const getRows = async (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ): Promise<ReportTableData> => {
    if (
      store.entityDropdownStore.selectedEntity &&
      store.portfolioDropdownStore.selectedPortfolio
    ) {
      await store.getInvestmentReturnsGrowthSummary(page, itemsPerPage, sort);
    }

    return {
      rows: store.mfInvestmentReturnsGrowthList.map((item) => {
        return [
          item.purchaseId,
          item.schemeName,
          item.brokerName,
          item.entityName,
          item.portfolioName,
          t("common.decimal2", { val: item.investmentAmount }),
          t("common.decimal4", { val: item.totalUnits }),
          t("common.decimal4", { val: item.outstandingUnits }),
          t("common.decimal2", { val: item.navOnDoi }),
          item.purchaseDate,
          item.latestNavDate,
          t("common.decimal2", { val: item.latestNav }),
          item.numberOfDays,
          getTypography(item.growthReturn ?? "-", item.isGrowthReturnPositive),
          t("common.decimal2", { val: item.amountRealisable }),
        ];
      }),
      totalItems: store.totalItems,
    };
  };

  return (
    <>
      <LoadingIndicator isLoading={store.isScreenLoading} />
      <ErrorDialog
        title={t("errors.internalServerError")}
        errorMessage={t("errors.internalServerErrorDescription")}
        isErrorDialogOpen={store.hasError}
        onClose={(): void => {
          store.clearError();
        }}
      />
      {store.isDataFetched && (
        <ReportTablePage
          pageHeader={{
            title: t("mf.reports.investmentReturnsGrowthLabel"),
          }}
          reloadTable={tableRef as RefObject<TableReloadHandle>}
          previousPage={{
            label: t("common.reports"),
            onLabelClick: (): void => {
              navigate(getPath(Module.MF, Route.Reports), {
                replace: true,
              });
            },
          }}
          onEditFilter={(open) => setViewFilter(open)}
          reportFilterDialogProps={{
            isAllOptionEnabled: true,
            isPeriodRequired: false,
            open: viewFilter,
            onClose: () => setViewFilter(false),
            onViewReport: async (period): Promise<void> => {
              tableRef.current?.reload();
              await store.getInvestmentReturnsGrowthSummary(
                0,
                store.itemsPerPage(),
                undefined,
                period,
              );
              return setViewFilter(false);
            },
            module: ModuleType.ModuleType.MUTUAL_FUND,
            showReportType: false,
            period: {
              reportType: Period.AS_ON_DATE,
              asOnDate: getAsOnDate(),
              betweenTwoDatesDateRange: {
                startDate: null,
                endDate: null,
              },
            },
            entityDropdownStore: store.entityDropdownStore,
            portfolioDropdownStore: store.portfolioDropdownStore,
          }}
          headers={headers}
          getRows={getRows}
          itemsPerPage={store.itemsPerPage()}
          summary={undefined}
          isExportable={true}
          onExport={async (exportFormat): Promise<void> => {
            if (
              store.entityDropdownStore.selectedEntity &&
              store.portfolioDropdownStore.selectedPortfolio
            ) {
              await store.getDownloadURL(exportFormat);
              if (store.downloadURL) {
                downloadFile(store.downloadURL);
              }
            }
            return Promise.resolve();
          }}
          downloadButtonDisabled={
            store.mfInvestmentReturnsGrowthList.length === 0
          }
        />
      )}
    </>
  );
});
