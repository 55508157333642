import {
  Instance,
  types,
  getSnapshot,
  applySnapshot,
  flow,
} from "mobx-state-tree";
import {
  InvestmentCashflowBannerStore,
  createInvestmentCashflowBannerStore,
} from "../../../components/cash-flow-summary/InvestmentCashflowBannerStore";
import { ModuleType } from "@khazana/khazana-rpcs";
import {
  FdInvestmentDetailsStore,
  createFdInvestmentDetailsStore,
} from "../../components/investment-details/FdInvestmentDetailsStore";
import { FDAccrualStore, createFDAccrualStore } from "./FDAccrualStore";
import { FDCashflowsStore, createFDCashflowsStore } from "./FDCashflowsStore";

export const FDViewCashflowsStore = types
  .model("FDViewCashflowsStore", {
    error: types.optional(types.boolean, false),
    investmentDetailsBannerStore: InvestmentCashflowBannerStore,
    investmentDetailsStore: FdInvestmentDetailsStore,
    cashflowsStore: FDCashflowsStore,
    accrualStore: FDAccrualStore,
    currencySymbol: types.maybeNull(types.string),
    previousDealRequestId: types.maybe(types.number),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    setPreviousId: (dealRequestId?: number): void => {
      store.previousDealRequestId = dealRequestId;
    },
    softReset: (): void => {
      store.investmentDetailsBannerStore.reset();
      store.investmentDetailsStore.resetStore();
      store.accrualStore.reset();
      store.cashflowsStore.reset();
    },
    getInvestmentBannerData: flow(function* (requestId: number) {
      store.error = false;
      yield store.investmentDetailsBannerStore.getInvestmentCashflowBannerDetails(
        requestId,
        null,
        ModuleType.ModuleType.FIXED_DEPOSIT,
      );
      store.error = Boolean(store.investmentDetailsBannerStore.error);
    }),
    getInvestmentDetails: flow(function* (investmentId: number) {
      yield store.investmentDetailsStore.getInvestmentDetails(
        undefined,
        investmentId,
      );
      store.error = store.investmentDetailsStore.error;
    }),
  }));

export const createFDViewCashflowsStore = (): Instance<
  typeof FDViewCashflowsStore
> => {
  return FDViewCashflowsStore.create({
    investmentDetailsBannerStore: createInvestmentCashflowBannerStore(),
    investmentDetailsStore: createFdInvestmentDetailsStore(),
    cashflowsStore: createFDCashflowsStore(),
    accrualStore: createFDAccrualStore(),
  });
};
