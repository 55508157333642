import {
  Entity,
  FixedDepositHoldingSummaryResponse,
  GetFixedDepositHoldingSummaryReportRPC,
  Portfolio,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFixedDepositHoldingSummaryReportRPC extends GetFixedDepositHoldingSummaryReportRPC {
  execute(
    _request: GetFixedDepositHoldingSummaryReportRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFixedDepositHoldingSummaryReportRPC.Response,
      GetFixedDepositHoldingSummaryReportRPC.Errors.Errors
    >
  > {
    const response = new GetFixedDepositHoldingSummaryReportRPC.Response(
      new Entity(new LeoUUID(), "Udit"),
      new Portfolio(new LeoUUID(), "Compounding"),
      new LeoDate(),
      new LeoDate(),
      [new FixedDepositHoldingSummaryResponse("HDFC")],
      10,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFixedDepositHoldingSummaryReportRPC.Response,
        GetFixedDepositHoldingSummaryReportRPC.Errors.Errors
      >
    >;
  }
}
