import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  Avatar,
  Chip,
  DetailsRow,
  Dialog,
  useCornerRadius,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  DetailRowCellEnum,
  DetailsRowProps,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import {
  ContractNoteDealRequestDetails,
  EquityContractNoteHistoryDetailModel,
} from "../models/EquityContractNoteHistoryDetailModel";
import { Instance } from "mobx-state-tree";
import { RequestNoteModel } from "../../../models/RequestNoteModel";
import {
  getFormattedAmount,
  getFormattedAmountString,
  getFormattedTimeDateWithComma,
} from "../../../../../utils";
import { getRequestStatusValue } from "../../utils/SearchUtils";
import {
  ContractNoteRequestStatus,
  EquityDealRequestStatus,
} from "@khazana/khazana-rpcs";
import { useContractNoteDetailsStore } from "../store/hooks";
import { getContractNoteRequestStatusValue } from "../utils/SearchUtils";
import {
  eqContractNoteTransformCell,
  getCell,
  getContractNoteStatusColor,
} from "../utils/UIUtils";
import { useBorder } from "../../../../../utils/BorderUtils";
import { ContractNoteDetailType } from "../models/ContractNoteDetailType";
import { ContractNoteChargeType } from "../../../models/ContractNoteChargeType";

export interface ContractNoteHistoryDialogProps {
  onClose: () => void;
  isOpen: boolean;
}

const Size = {
  container: {
    width: "100%",
    height: "48px",
  },
  row: {
    maxWidth: "730px",
  },
  dealDetailsContainer: "356px",
  dealDetailsHeading: "48px",
  dealDetailsSubheading: "182px",
  dialogWidth: "960px",
};

export const ContractNoteHistoryDialog = ({
  onClose,
  isOpen,
}: ContractNoteHistoryDialogProps): React.ReactElement => {
  const { t } = useTranslation();
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const typography = useTypography();
  const store = useContractNoteDetailsStore();
  const [searchParam] = useSearchParams();
  const contractNoteId = searchParam.get("contractNoteId")
    ? Number(searchParam.get("contractNoteId"))
    : undefined;

  useEffect(() => {
    const getHistory = async (): Promise<void> => {
      store.viewContractNoteHistoryStore.getEquityContractNoteHistory(
        contractNoteId,
      );
    };
    getHistory();
  }, []);

  const getTitleText = (
    status: ContractNoteRequestStatus.ContractNoteRequestStatus | null,
    firstName: string | undefined,
    lastName: string | undefined,
  ): string => {
    switch (status) {
      case ContractNoteRequestStatus.ContractNoteRequestStatus.LINKED:
        return t("contractNotes.linkedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.UNPARSED:
        return t("contractNotes.unparsedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.PARSED:
        return t("contractNotes.parsedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.ACCOUNTS_PENDING:
        return t("contractNotes.accountsPendingMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus
        .UNKNOWN_REQUESTED:
        return t("contractNotes.unknownRequestedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus
        .ACCOUNTS_APPROVED:
        return t("contractNotes.accountsApprovedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus
        .ACCOUNTS_REJECTED:
        return t("contractNotes.accountsRejectedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.DATA_MISMATCH:
        return t("contractNotes.dataMismatchMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.DISCARD_PENDING:
        return t("contractNotes.discardPendingMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.DISCARDED:
        return t("contractNotes.discardedMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.LINKING_PENDING:
        return t("contractNotes.linkRequestMessage", {
          firstName,
          lastName,
        });
      case ContractNoteRequestStatus.ContractNoteRequestStatus.UNKNOWN:
        return t("contractNotes.unknownMessage", {
          firstName,
          lastName,
        });
      default:
        return "";
    }
  };

  const getDealDetails = (
    details: Instance<typeof ContractNoteDealRequestDetails>,
  ): React.ReactElement => {
    return (
      <Stack
        width={Size.dealDetailsContainer}
        sx={{
          border: border.default,
          borderRadius: cornerRadius.radiusXXS,
        }}
      >
        <Stack
          height={Size.dealDetailsHeading}
          width={Size.container}
          justifyContent={"center"}
          sx={{
            background: tokens.backgroundSubtle,
            borderRadius: cornerRadius.radiusXXS,
          }}
          padding={"10px"}
        >
          <Typography
            sx={{
              ...typography.sh3,
            }}
          >
            {t("equity.dealRequestDetails.equityDealRequestDetails")}
          </Typography>
        </Stack>
        <Divider />
        <Stack spacing={spacing.spaceXS} padding={spacing.spaceMD}>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.b2, width: Size.dealDetailsSubheading }}
            >
              {t("contractNotes.dematAccountNumber")}
            </Typography>
            <Typography sx={{ ...typography.b2 }}>
              {details.dematAccountNumber}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.b2, width: Size.dealDetailsSubheading }}
            >
              {t("common.entity")}
            </Typography>
            <Typography sx={{ ...typography.b2 }}>{details.entity}</Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.b2, width: Size.dealDetailsSubheading }}
            >
              {t("common.amountWithPostfixLabel", {
                val: details.amount?.currency.symbol,
              })}
            </Typography>
            <Typography sx={{ ...typography.b2 }}>
              {details.amount ? getFormattedAmount(details.amount.amount) : "-"}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.b2, width: Size.dealDetailsSubheading }}
            >
              {t("contractNotes.quantity")}
            </Typography>
            <Typography sx={{ ...typography.b2 }}>
              {details.quantity ?? "-"}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.b2, width: Size.dealDetailsSubheading }}
            >
              {t("contractNotes.transactionType")}
            </Typography>
            <Typography sx={{ ...typography.b2 }}>
              {details.transactionType}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.b2, width: Size.dealDetailsSubheading }}
            >
              {t("common.symbol")}
            </Typography>
            <Typography sx={{ ...typography.b2 }}>{details.symbol}</Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.b2, width: Size.dealDetailsSubheading }}
            >
              {t("common.requestId")}
            </Typography>
            <Typography sx={{ ...typography.b2 }}>
              {details.requestId}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography
              sx={{ ...typography.b2, width: Size.dealDetailsSubheading }}
            >
              {t("common.requestStatus")}
            </Typography>
            <Typography sx={{ ...typography.b2 }}>
              {getRequestStatusValue(
                t,
                EquityDealRequestStatus.fromDTO({ case: details.status }),
              )}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const getDiffRow = (
    diffData: Instance<typeof ContractNoteDetailType>[],
    charges: Instance<typeof ContractNoteChargeType>[],
  ): DetailsRowProps[] => {
    const rows: DetailsRowProps[] = [
      {
        label: "",
        cellType: {
          currentDetailsLabel: t(`contractNotes.sourceData`),
          updatedDetailsLabel: t(`contractNotes.suggestedEdits`),
          type: DetailRowCellEnum.DiffHeader,
        },
      },
    ];

    diffData.map((data) =>
      rows.push({
        /* @ts-ignore */
        label: t(data.localizedTextId),
        cellType: eqContractNoteTransformCell(
          data.localizedTextId,
          data.originalData,
          data.diffData,
        ) ?? {
          text: "",
          type: DetailRowCellEnum.Unstyled,
        },
      }),
    );

    charges.map((charge) => {
      if (charge.diffValue !== null) {
        rows.push({
          label: store.currency
            ? // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
              /* @ts-ignore */
              t(charge.displayName, { val: ` (${store.currency.symbol})` })
            : // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
              /* @ts-ignore */
              t(charge.displayName, { val: null }),
          cellType: getCell(
            charge.amount ? getFormattedAmountString(charge.amount) : "-",
            getFormattedAmountString(charge.diffValue),
            false,
          ),
        });
      }
    });

    return rows;
  };

  const getRow = (
    index: number,
    item: Instance<typeof EquityContractNoteHistoryDetailModel>,
  ): React.ReactElement => {
    const requestNote: Instance<typeof RequestNoteModel> | null =
      item.requestNote;
    return (
      <Stack
        key={index}
        width={Size.container.width}
        padding={spacing.spaceXL}
        sx={{ borderBottom: border.default }}
      >
        <Stack flexDirection={"row"}>
          <Avatar
            userName={`${requestNote?.userName.firstName} ${
              requestNote?.userName.lastName ?? ""
            }`}
            size="large"
            src={requestNote?.profilePhoto}
          />
          <Box sx={{ marginLeft: spacing.spaceMD }}>
            <Typography
              sx={{
                ...typography.sh3,
                color: tokens.labelHighEmphasis,
              }}
            >
              {getTitleText(
                item.status,
                item.requestNote?.userName.firstName,
                item.requestNote?.userName.lastName ?? undefined,
              )}
            </Typography>
            <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
              {requestNote?.requestedAt
                ? getFormattedTimeDateWithComma(
                    new Date(requestNote?.requestedAt),
                  )
                : null}
            </Typography>
          </Box>
        </Stack>
        <Stack
          padding={spacing.spaceXL}
          gap={spacing.spaceXL}
          marginLeft={spacing.space3XL}
        >
          {item.status && (
            <Box>
              <Chip
                label={getContractNoteRequestStatusValue(
                  t,
                  item.status,
                ).toLocaleUpperCase()}
                color={getContractNoteStatusColor(item.status)}
              />
            </Box>
          )}
          {requestNote?.note && (
            <Stack>
              <Typography {...typography.s1}>{t("common.note")}</Typography>
              <Typography {...typography.b1}>{requestNote.note}</Typography>
            </Stack>
          )}
          {item.dealRequestDetails && getDealDetails(item.dealRequestDetails)}
          {(item.diffData.length > 0 || item.charges.length > 0) && (
            <Stack maxWidth={Size.row.maxWidth}>
              {getDiffRow(item.diffData, item.charges).map(
                ({ label, cellType }) => (
                  <Box key={label} borderBottom={border.default}>
                    <DetailsRow label={label} cellType={cellType} />
                  </Box>
                ),
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <Dialog
      title={t("contractNotes.contractNoteHistory")}
      open={isOpen}
      onSecondaryButtonClick={onClose}
      secondaryButtonText={t("common.close")}
      disableBackdropClick={true}
      dialogWidth={Size.dialogWidth}
    >
      <Stack width={Size.container.width}>
        {store.viewContractNoteHistoryStore.equityContractNoteHistoryDetail?.map(
          (item, index) => {
            return getRow(index, item);
          },
        )}
      </Stack>
    </Dialog>
  );
};
