import { Instance, types } from "mobx-state-tree";
import { FiContractNoteCharge } from "@khazana/khazana-rpcs";
import { getAmountOrUndefined, getServerAmount } from "../../../../../utils";

export const FiContractNoteChargeType = types.model({
  displayName: types.string,
  chargeType: types.string,
  chargeRuleId: types.maybeNull(types.number),
  amount: types.number,
  diffValue: types.maybeNull(types.number),
});

export const createFiContractNoteChargeType = (
  charge: FiContractNoteCharge,
): Instance<typeof FiContractNoteChargeType> => {
  return FiContractNoteChargeType.create({
    displayName: charge.displayName,
    chargeType: charge.chargeType,
    chargeRuleId: charge.chargeRuleId,
    amount: getAmountOrUndefined(charge?.amount) ?? 0,
    diffValue: getAmountOrUndefined(charge?.amount) ?? null,
  });
};

export const getServerFiContractNoteCharge = (
  charge: Instance<typeof FiContractNoteChargeType>,
): FiContractNoteCharge => {
  return new FiContractNoteCharge(
    charge.displayName,
    charge.chargeType,
    charge.chargeRuleId,
    getServerAmount(charge.amount),
    charge.diffValue ? getServerAmount(charge.diffValue) : null,
  );
};
