import React, { Fragment } from "react";
import { Divider, Stack, Tooltip, Typography } from "@mui/material";
import {
  spacing,
  useCornerRadius,
  useFoundationColorTokens,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useBorder } from "../../../../utils/BorderUtils";
import { CheveronsUp } from "../../../../assets/CheveronsUp";
import { CheveronsDown } from "../../../../assets/CheveronsDown";

const Size = {
  separatorHeight: "32px",
  cheveronsSize: "20px",
};

export interface DashboardStatsItem {
  title: string;
  value: string;
  fullValue?: string;
  isPositive?: boolean;
  percentage?: string;
}

export interface DashboardStatsProps {
  stats: DashboardStatsItem[];
}
export const DashboardStats = ({
  stats,
}: DashboardStatsProps): React.ReactElement => {
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();

  const getTextColor = (isPositive: boolean | undefined): string => {
    if (isPositive === true) {
      return tokens.labelSuccess;
    } else if (isPositive === false) {
      return tokens.labelError;
    }
    return tokens.label;
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        border: border.default,
        background: tokens.backgroundElevatedLevel1,
        borderRadius: cornerRadius.radiusXS,
        padding: spacing.spaceXL,
        gap: spacing.spaceSM,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      {stats.map((item, index) => (
        <Fragment key={index}>
          {index !== 0 && (
            <Stack>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{
                  color: tokens.border,
                  height: Size.separatorHeight,
                }}
              />
            </Stack>
          )}
          <Stack>
            <Typography
              sx={{ typography: typography.b2, color: tokens.labelSubtle }}
            >
              {item.title}
            </Typography>
            <Stack
              alignItems={"flex-end"}
              direction={"row"}
              spacing={spacing.spaceXS}
            >
              <Tooltip title={item.fullValue} placement="top">
                <Typography
                  sx={{
                    typography: typography.h5,
                    color: getTextColor(item.isPositive),
                  }}
                >
                  {item.value}
                </Typography>
              </Tooltip>
              {item.isPositive !== undefined &&
                item.percentage !== undefined && (
                  <Stack direction={"row"} paddingBottom={spacing.spaceXXS}>
                    {item.isPositive === true && (
                      <CheveronsUp
                        height={Size.cheveronsSize}
                        width={Size.cheveronsSize}
                        color={getTextColor(item.isPositive)}
                      />
                    )}
                    {item.isPositive === false && (
                      <CheveronsDown
                        height={Size.cheveronsSize}
                        width={Size.cheveronsSize}
                        color={getTextColor(item.isPositive)}
                      />
                    )}
                    <Typography
                      sx={{
                        typography: typography.b2,
                        color: getTextColor(item.isPositive),
                      }}
                    >
                      {item.percentage}
                    </Typography>
                  </Stack>
                )}
            </Stack>
          </Stack>
        </Fragment>
      ))}
    </Stack>
  );
};
