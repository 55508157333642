import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  FileExtension,
  FixedIncomeHoldingCategory,
  GetFixedIncomeInvestmentRegisterDownloadURLRPC,
  GetFixedIncomeInvestmentRegisterReportRPC,
  ModuleType,
  PeriodInput,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import {
  Instance,
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  types,
} from "mobx-state-tree";
import {
  useGetFixedIncomeInvestmentRegisterDownloadURLRPCClientImpl,
  useGetFixedIncomeInvestmentRegisterReportRPCClientImpl,
} from "../rpcs/RPC";
import {
  FiInvestmentRegisterSummaryModel,
  createFiInvestmentRegisterSummaryModel,
} from "../models/FiInvestmentRegisterSummaryModel";
import { getInvestmentRegisterSummarySortOrder } from "../utils/SortUtils";
import { TableSortOption } from "@surya-digital/leo-reactjs-core";
import {
  PortfolioDropdownStore,
  createPortfolioDropdownStore,
} from "../../../components/portfolio/PortfolioDropdownStore";
import {
  EntityDropdownStore,
  createEntityDropdownStore,
} from "../../../components/entity/EntityDropdownStore";
import {
  ReportExportFormatEnum,
  getLeoDateOrNull,
  getIdOrNull,
} from "../../../utils/ReportUtils";
import { createPortfolioModel } from "../../../models/PortfolioModel";
import {
  getFixedIncomeHoldingCategoryOrNull,
  getFixedIncomeHoldingCategoryOrAll,
} from "./utils";
import { AllEnum } from "../../../../../types/EnumTypes";

export const FiInvestmentRegisterStore = types
  .model("FiInvestmentRegisterStore", {
    entityDropdownStore: EntityDropdownStore,
    portfolioDropdownStore: PortfolioDropdownStore,
    hasError: types.boolean,
    totalItems: types.number,
    downloadURL: types.maybe(types.string),
    fiInvestmentRegisterSummaryList: types.array(
      FiInvestmentRegisterSummaryModel,
    ),
    fromDate: types.maybe(types.string),
    toDate: types.maybe(types.string),
    holdingCategory: types.union(
      types.enumeration<FixedIncomeHoldingCategory.FixedIncomeHoldingCategory>(
        "FixedIncomeHoldingCategory",
        Object.values(FixedIncomeHoldingCategory.FixedIncomeHoldingCategory),
      ),
      types.enumeration<AllEnum>("AllEnum", Object.values(AllEnum)),
    ),
    isScreenLoading: types.boolean,
    isDataFetched: types.boolean,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    clearError: (): void => {
      store.hasError = false;
    },
    getInvestmentRegisterSummary: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      sort: TableSortOption | undefined,
      period?: PeriodInput.PeriodInput,
    ) {
      store.isScreenLoading = true;
      if (period instanceof PeriodInput.BetweenTwoDates) {
        store.toDate = period.endDate.date;
        store.fromDate = period.startDate.date;
      }
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        yield store.entityDropdownStore.getEntityList();
        yield store.portfolioDropdownStore.getPortfolioList(
          store.entityDropdownStore.entityList[0].id,
          ModuleType.ModuleType.FIXED_INCOME,
        );
        const request = new GetFixedIncomeInvestmentRegisterReportRPC.Request(
          getIdOrNull(store.entityDropdownStore.selectedEntity),
          getIdOrNull(store.portfolioDropdownStore.selectedPortfolio?.id),
          getLeoDateOrNull(store.fromDate),
          getLeoDateOrNull(store.toDate),
          getFixedIncomeHoldingCategoryOrNull(store.holdingCategory),
          pageIndex,
          itemsPerPage,
          getInvestmentRegisterSummarySortOrder(sort),
        );
        const result: LeoRPCResult<
          GetFixedIncomeInvestmentRegisterReportRPC.Response,
          GetFixedIncomeInvestmentRegisterReportRPC.Errors.Errors
        > =
          yield useGetFixedIncomeInvestmentRegisterReportRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.totalItems = response.totalItems;
          store.entityDropdownStore.selectedEntity = response.entity.id.uuid;
          store.fromDate = response.fromDate.date;
          store.toDate = response.toDate.date;
          store.portfolioDropdownStore.selectedPortfolio = createPortfolioModel(
            response.portfolio,
          );
          store.fiInvestmentRegisterSummaryList = cast(
            response.investmentRegisterResponse.map((item) => {
              return createFiInvestmentRegisterSummaryModel(item);
            }),
          );
          store.isDataFetched = true;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetFixedIncomeInvestmentRegisterReportRPC`,
          );
        }
        store.isScreenLoading = false;
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in getInvestmentRegisterSummary`,
          );
        }
        store.isScreenLoading = false;
      }
    }),
    getDownloadURL: flow(function* (fileExtension: string) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request =
          new GetFixedIncomeInvestmentRegisterDownloadURLRPC.Request(
            new LeoUUID(store.entityDropdownStore.selectedEntity),
            new LeoUUID(store.portfolioDropdownStore.selectedPortfolio?.id),
            new LeoDate(store.fromDate),
            new LeoDate(store.toDate),
            getFixedIncomeHoldingCategoryOrNull(store.holdingCategory),
            fileExtension === ReportExportFormatEnum.CSV
              ? FileExtension.FileExtension.CSV
              : FileExtension.FileExtension.XLSX,
          );
        const result: LeoRPCResult<
          GetFixedIncomeInvestmentRegisterDownloadURLRPC.Response,
          GetFixedIncomeInvestmentRegisterDownloadURLRPC.Errors.Errors
        > =
          yield useGetFixedIncomeInvestmentRegisterDownloadURLRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.downloadURL = response.downloadUrl.toString();
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetFixedIncomeInvestmentRegisterDownloadURLRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetFixedIncomeInvestmentRegisterDownloadURLRPC`,
          );
        }
      }
    }),
    setHoldingCategory: (value: string): void => {
      store.holdingCategory = getFixedIncomeHoldingCategoryOrAll(value);
    },
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createFiInvestmentRegisterStore = (): Instance<
  typeof FiInvestmentRegisterStore
> => {
  return FiInvestmentRegisterStore.create({
    entityDropdownStore: createEntityDropdownStore(),
    portfolioDropdownStore: createPortfolioDropdownStore(),
    totalItems: 0,
    hasError: false,
    isScreenLoading: false,
    isDataFetched: false,
    holdingCategory: AllEnum.All,
  });
};
