import {
  Entity,
  FixedIncomeInvestmentRegisterResponse,
  GetFixedIncomeInvestmentRegisterReportRPC,
  Portfolio,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFixedIncomeInvestmentRegisterReportRPC extends GetFixedIncomeInvestmentRegisterReportRPC {
  execute(
    _request: GetFixedIncomeInvestmentRegisterReportRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFixedIncomeInvestmentRegisterReportRPC.Response,
      GetFixedIncomeInvestmentRegisterReportRPC.Errors.Errors
    >
  > {
    const response = new GetFixedIncomeInvestmentRegisterReportRPC.Response(
      new Entity(new LeoUUID(), "Udit"),
      new Portfolio(new LeoUUID(), "Compounding"),
      new LeoDate(),
      new LeoDate(),
      [new FixedIncomeInvestmentRegisterResponse("HDFC")],
      10,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFixedIncomeInvestmentRegisterReportRPC.Response,
        GetFixedIncomeInvestmentRegisterReportRPC.Errors.Errors
      >
    >;
  }
}
