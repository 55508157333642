import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { ModuleType } from "@khazana/khazana-rpcs";
import {
  Period,
  getDateOrNull,
  getHoldingCategoryOptionsWithAll,
} from "../../../utils/ReportUtils";
import { ReportTablePage } from "../../../components/reports/ReportTablePage";
import {
  DateRangePickerInput,
  DropdownInputFieldSeparateLabel,
  DropdownItem,
  LoadingIndicator,
  TableReloadHandle,
  TableSortOption,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  ReportTableData,
  ReportTableHeaderProps,
  ReportTableHeaderWidth,
} from "../../../components/reports/ReportTable";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { observer } from "mobx-react";
import { useFiInvestmentRegisterStore } from "../store/hooks";
import { downloadFile } from "../../../../../utils/FileDownloadUtils";

export const FiInvestmentRegister = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const store = useFiInvestmentRegisterStore();
  const [viewFilter, setViewFilter] = useState(false);
  const tableRef = useRef<TableReloadHandle>();

  const getInvestmentRegisterDetails = useCallback(async () => {
    await store.getInvestmentRegisterSummary(
      0,
      store.itemsPerPage(),
      undefined,
    );
  }, []);

  useEffect(() => {
    getInvestmentRegisterDetails();

    return () => {
      store.reset();
    };
  }, []);

  const Size = {
    securityHeaderWidth: "344px",
    fieldWidth: "250px",
  };

  const headers: ReportTableHeaderProps[] = [
    {
      name: t("fi.security"),
      width: ReportTableHeaderWidth.TableWidthXL,
      sortable: true,
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.instrumentType"),
      sortable: true,
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.openingAmount"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.purchaseAmount"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.maturedAmount"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.closingAmount"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.daysInHolding"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.couponRate"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.startDate"),
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.maturityDate"),
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.nextCallDate"),
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.openingInterest"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.openingTds"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.openingOtherCharges"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.interestReceived"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.taxPaid"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.otherChargesPaid"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.netInterestReceived"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.interestAccrued"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.taxOnInterestAccrued"),
      alignment: "right",
    },
    {
      name: t(
        "fi.reports.investmentRegisterHeaders.otherTaxesOnInterestAccrued",
      ),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.netInterestReceivable"),
      alignment: "right",
    },
    {
      name: t("fi.reports.investmentRegisterHeaders.status"),
    },
  ];

  const getRows = async (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ): Promise<ReportTableData> => {
    if (
      store.entityDropdownStore.selectedEntity &&
      store.portfolioDropdownStore.selectedPortfolio
    ) {
      await store.getInvestmentRegisterSummary(page, itemsPerPage, sort);
    }

    return {
      rows: store.fiInvestmentRegisterSummaryList.map((item) => {
        return [
          item.symbol,
          item.instrumentType,
          item.openingAmount,
          item.purchaseAmount,
          item.maturedAmount,
          item.closingAmount,
          item.daysInHolding,
          item.couponRate,
          item.startDate,
          item.maturityDate,
          item.nextCallDate,
          item.openingInterest,
          item.openingOtherCharges,
          item.openingTds,
          item.interestReceived,
          item.taxPaid,
          item.otherChargesPaid,
          item.netInterestReceived,
          item.interestAccrued,
          item.taxOnInterestAccrued,
          item.otherTaxesOnInterestAccrued,
          item.netInterestReceivable,
          item.status,
        ];
      }),
      totalItems: store.totalItems,
    };
  };

  const getDateRange = (): DateRangePickerInput => {
    return {
      startDate: getDateOrNull(store.fromDate),
      endDate: getDateOrNull(store.toDate),
    };
  };

  return (
    <>
      {store.isScreenLoading && (
        <LoadingIndicator isLoading={store.isScreenLoading} />
      )}
      <ErrorDialog
        title={t("errors.internalServerError")}
        errorMessage={t("errors.internalServerErrorDescription")}
        isErrorDialogOpen={store.hasError}
        onClose={(): void => {
          store.clearError();
        }}
      />
      {store.isDataFetched && (
        <ReportTablePage
          pageHeader={{
            title: t("fi.reports.investmentRegisterReportLabel"),
          }}
          reloadTable={tableRef as RefObject<TableReloadHandle>}
          previousPage={{
            label: t("common.reports"),
            onLabelClick: (): void => {
              navigate(getPath(Module.Fi, Route.Reports), {
                replace: true,
              });
            },
          }}
          onEditFilter={(open) => setViewFilter(open)}
          reportFilterDialogProps={{
            isAllOptionEnabled: false,
            open: viewFilter,
            onClose: () => setViewFilter(false),
            onViewReport: async (period): Promise<void> => {
              tableRef.current?.reload();
              await store.getInvestmentRegisterSummary(
                0,
                store.itemsPerPage(),
                undefined,
                period,
              );
              return setViewFilter(false);
            },
            module: ModuleType.ModuleType.FIXED_INCOME,
            showReportType: false,
            period: {
              reportType: Period.BETWEEN_TWO_DATES,
              asOnDate: null,
              betweenTwoDatesDateRange: getDateRange(),
            },
            entityDropdownStore: store.entityDropdownStore,
            portfolioDropdownStore: store.portfolioDropdownStore,
            additionalFields: [
              {
                element: (
                  <DropdownInputFieldSeparateLabel
                    key={"holdingCategory"}
                    name={"holdingCategory"}
                    label={t("fi.reports.holdingCategoryLabel")}
                    isRequired={true}
                    style={{ width: Size.fieldWidth }}
                    placeholder={t(
                      "fi.reports.selectHoldingCategoryPlaceholder",
                    )}
                    value={store.holdingCategory}
                    options={getHoldingCategoryOptionsWithAll()}
                    onSelect={function (value: DropdownItem): void {
                      store.setHoldingCategory(value.value);
                    }}
                  />
                ),
                selectedValue: store.holdingCategory,
                isRequired: false,
              },
            ],
          }}
          headers={headers}
          getRows={getRows}
          itemsPerPage={store.itemsPerPage()}
          summary={undefined}
          isExportable={true}
          onExport={async (exportFormat): Promise<void> => {
            if (
              store.entityDropdownStore.selectedEntity &&
              store.portfolioDropdownStore.selectedPortfolio
            ) {
              await store.getDownloadURL(exportFormat);
              if (store.downloadURL) {
                downloadFile(store.downloadURL);
              }
            }
            return Promise.resolve();
          }}
          downloadButtonDisabled={
            store.fiInvestmentRegisterSummaryList.length === 0
          }
        />
      )}
    </>
  );
});
