"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFTransactionType = exports.InvalidMFTransactionTypeError = void 0;
class InvalidMFTransactionTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFTransactionTypeError";
    }
}
exports.InvalidMFTransactionTypeError = InvalidMFTransactionTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var MFTransactionType;
(function (MFTransactionType_1) {
    let MFTransactionType;
    (function (MFTransactionType) {
        MFTransactionType["PURCHASE"] = "PURCHASE";
        MFTransactionType["REDEEM"] = "REDEEM";
    })(MFTransactionType = MFTransactionType_1.MFTransactionType || (MFTransactionType_1.MFTransactionType = {}));
    MFTransactionType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "PURCHASE":
                return MFTransactionType.PURCHASE;
            case "REDEEM":
                return MFTransactionType.REDEEM;
            default:
                throw new InvalidMFTransactionTypeError(`Case ${dto.case} is not valid case of MFTransactionType`);
        }
    };
    MFTransactionType_1.toDTO = (mFTransactionType) => {
        const ret = {
            case: MFTransactionType[mFTransactionType],
        };
        return ret;
    };
    MFTransactionType_1.isInstanceOf = (other) => {
        if (other in MFTransactionType) {
            return true;
        }
        return false;
    };
})(MFTransactionType = exports.MFTransactionType || (exports.MFTransactionType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
