import {
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { getAPIClient, mfCreateDealErrorStore } from "./hooks";
import {
  BigAmount,
  BigDecimal,
  GetMFDealRequestSummaryRPC,
  ISIN,
  MFDividendType,
  MFPlanType,
  MFSchemeType,
  MFTransactionType,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetMFDealRequestSummaryRPC } from "../../rpc/RPC";
import {
  createCurrencyRPCType,
  CurrencyModel,
} from "../../../../models/CurrencyModel";
import { CreateMFDealError } from "./MFCreateDealRequestErrorStore";

export const MFDealRequestSummaryStore = types
  .model("MFDealRequestSummaryStore", {
    isin: types.maybe(types.string),
    amc: types.maybe(types.string),
    schemeType: types.maybe(
      types.enumeration<MFSchemeType.MFSchemeType>(
        "MFSchemeType",
        Object.values(MFSchemeType.MFSchemeType),
      ),
    ),
    planType: types.maybe(
      types.enumeration<MFPlanType.MFPlanType>(
        "MFPlanType",
        Object.values(MFPlanType.MFPlanType),
      ),
    ),
    dividendType: types.maybe(
      types.enumeration<MFDividendType.MFDividendType>(
        "MFDividendType",
        Object.values(MFDividendType.MFDividendType),
      ),
    ),
    entryLoad: types.maybe(types.string),
    exitLoad: types.maybe(types.string),
    grossAmount: types.maybe(types.string),
    totalAmount: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getMFDealRequestSummary: flow(function* (
        isin: string,
        transactionType: MFTransactionType.MFTransactionType,
        grossAmount: string | undefined,
        units: string | undefined,
        approxNav: string | undefined,
        currency: Instance<typeof CurrencyModel>,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        try {
          const request = new GetMFDealRequestSummaryRPC.Request(
            new ISIN(isin),
            transactionType,
            approxNav
              ? new BigAmount(
                  new BigDecimal(approxNav),
                  createCurrencyRPCType(currency),
                )
              : null,
            grossAmount
              ? new BigAmount(
                  new BigDecimal(grossAmount),
                  createCurrencyRPCType(currency),
                )
              : null,
            units ? new BigDecimal(units) : null,
          );
          store.isLoading = true;
          const result: LeoRPCResult<
            GetMFDealRequestSummaryRPC.Response,
            GetMFDealRequestSummaryRPC.Errors.Errors
          > = yield useGetMFDealRequestSummaryRPC(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.isin = isin;
            store.amc = response.transactionDetails.amc;
            store.schemeType = response.transactionDetails.schemeType;
            store.planType = response.transactionDetails.planType;
            store.dividendType = response.transactionDetails.dividendType;
            if (response.transactionDetails.entryLoad) {
              store.entryLoad =
                response.transactionDetails.entryLoad.decimalValue;
            }
            if (response.transactionDetails.exitLoad) {
              store.exitLoad =
                response.transactionDetails.exitLoad.decimalValue;
            }
            if (response.transactionDetails.grossAmount) {
              store.grossAmount =
                response.transactionDetails.grossAmount.decimalValue;
            }
            if (response.transactionDetails.totalAmount) {
              store.totalAmount =
                response.transactionDetails.totalAmount.decimalValue;
            }
          } else if (result instanceof LeoRPCResult.Error) {
            const { error } = result;
            switch (error.code) {
              default:
                logger.error(
                  `Unhandled error: ${error} occurred in getMFDealRequestSummaryRPC`,
                );
            }
          } else {
            logger.error("Unhandled error");
          }
        } catch (e) {
          const errorStore = mfCreateDealErrorStore(store);
          errorStore.setError(CreateMFDealError.ClientError);
        }
        store.isLoading = false;
      }),
    };
  });

export const createMFDealRequestSummaryStore = (): Instance<
  typeof MFDealRequestSummaryStore
> => {
  return MFDealRequestSummaryStore.create({});
};
