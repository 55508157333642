import { useSearchParams } from "react-router-dom";
import { useMFConfirmationRequestDetailsStore } from "../store/confirmationRequestDetailsStore/hooks";
import React, { useCallback, useEffect } from "react";
import { Dialog } from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { getFormattedTimeDateWithComma } from "../../../../../utils";
import {
  getMFConfirmationHistoryTitle,
  getMFConfirmationRequestStatusValue,
  getMFConfirmationStatusBackgroundColor,
} from "../utils/UIUtils";
import { ContractNoteHistoryRow } from "../../../components/contract-note/ContractNoteHistoryRow";
import { Module } from "../../../../../routes/RoutesEnum";

export interface ConfirmationHistoryDialogProps {
  onClose: () => void;
  isOpen: boolean;
}

const Size = {
  container: {
    width: "100%",
  },
  dialogWidth: "960px",
};

export const ConfirmationHistoryDialog = ({
  onClose,
  isOpen,
}: ConfirmationHistoryDialogProps): React.ReactElement => {
  const { t } = useTranslation();
  const store = useMFConfirmationRequestDetailsStore().historyStore;
  const [searchParam] = useSearchParams();
  const requestId = Number(searchParam.get("requestId"));

  const getHistory = useCallback(async (): Promise<void> => {
    store.getMFConfirmationHistory(requestId);
  }, []);

  useEffect(() => {
    getHistory();
    return () => {
      store.reset();
    };
  }, []);

  return (
    <Dialog
      title={t("mf.confirmationHistory.pageTitle")}
      open={isOpen}
      onSecondaryButtonClick={onClose}
      secondaryButtonText={t("common.close")}
      disableBackdropClick={true}
      dialogWidth={Size.dialogWidth}
    >
      <Stack width={Size.container.width}>
        {store.historyDetails.map((item, index) => {
          return (
            <ContractNoteHistoryRow
              key={index}
              index={index}
              firstName={item.requestNote?.userName.firstName}
              lastName={item.requestNote?.userName.lastName ?? ""}
              profilePhoto={item.requestNote?.profilePhoto}
              titleText={getMFConfirmationHistoryTitle(
                item.status,
                item.requestNote?.userName.firstName,
                item.requestNote?.userName.lastName ?? undefined,
              )}
              requestedAt={
                item.requestNote?.requestedAt
                  ? getFormattedTimeDateWithComma(
                      new Date(item.requestNote?.requestedAt),
                    )
                  : null
              }
              chipText={
                item.status
                  ? getMFConfirmationRequestStatusValue(
                      item.status,
                    ).toLocaleUpperCase()
                  : undefined
              }
              backgroundColor={
                item.status
                  ? getMFConfirmationStatusBackgroundColor(item.status)
                  : undefined
              }
              note={item.requestNote?.note}
              diffData={item.diffData}
              module={Module.MF}
            />
          );
        })}
      </Stack>
    </Dialog>
  );
};
