import {
  BigDecimal,
  Currency,
  GetMFDealRequestsPaginationResponse,
  GetMFDealRequestsRPC,
  MFDealRequestStatus,
  MFTransactionType,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoTimestamp } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFDealRequestsRPCImpl extends GetMFDealRequestsRPC {
  execute(
    _request: GetMFDealRequestsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFDealRequestsRPC.Response,
      GetMFDealRequestsRPC.Errors.Errors
    >
  > {
    const response = new GetMFDealRequestsRPC.Response(
      [
        new GetMFDealRequestsPaginationResponse(
          1,
          MFDealRequestStatus.MFDealRequestStatus.IC_PENDING,
          "HDFC Felxi Cap - Fund Direct Plan",
          "HDFC Asset Management Company Limited",
          MFTransactionType.MFTransactionType.PURCHASE,
          new BigDecimal("300000000"),
          null,
          new BigDecimal("20000000"),
          "Clarkson",
          "Opportunities",
          "AXI123456",
          new LeoTimestamp(),
          new Currency("INR", "₹"),
        ),
        new GetMFDealRequestsPaginationResponse(
          1,
          MFDealRequestStatus.MFDealRequestStatus.IC_PENDING,
          "Axis Bluechip Fund",
          "Axis Asset Management Company Limited",
          MFTransactionType.MFTransactionType.PURCHASE,
          new BigDecimal("300000000"),
          null,
          new BigDecimal("20000000"),
          "Clarkson",
          "Opportunities",
          "AXI123456",
          new LeoTimestamp(),
          new Currency("INR", "₹"),
        ),
        new GetMFDealRequestsPaginationResponse(
          1,
          MFDealRequestStatus.MFDealRequestStatus.IC_PENDING,
          "Axis Bluechip Fund",
          "Axis Asset Management Company Limited",
          MFTransactionType.MFTransactionType.PURCHASE,
          new BigDecimal("300000000"),
          null,
          new BigDecimal("20000000"),
          "Clarkson",
          "Opportunities",
          "AXI123456",
          new LeoTimestamp(),
          new Currency("INR", "₹"),
        ),
        new GetMFDealRequestsPaginationResponse(
          1,
          MFDealRequestStatus.MFDealRequestStatus.IC_PENDING,
          "Axis Bluechip Fund",
          "Axis Asset Management Company Limited",
          MFTransactionType.MFTransactionType.PURCHASE,
          new BigDecimal("300000000"),
          null,
          new BigDecimal("20000000"),
          "Clarkson",
          "Opportunities",
          "AXI123456",
          new LeoTimestamp(),
          new Currency("INR", "₹"),
        ),
      ],
      4,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFDealRequestsRPC.Response,
        GetMFDealRequestsRPC.Errors.Errors
      >
    >;
  }
}
