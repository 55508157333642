import {
  GetFixedIncomeHoldingSummarySortOrder,
  GetFixedIncomeInvestmentRegisterSortOrder,
  ReportsSortOrder,
} from "@khazana/khazana-rpcs";
import { GetFixedIncomeHoldingSummarySortOrderEnums } from "@khazana/khazana-rpcs/build/reports/getFixedIncomeHoldingSummarySortOrder";
import { GetFixedIncomeInvestmentRegisterSortOrderEnums } from "@khazana/khazana-rpcs/build/reports/getFixedIncomeInvestmentRegisterSortOrder";
import { TableSortOption } from "@surya-digital/leo-reactjs-core";
import i18next from "i18next";

export const getInvestmentRegisterSummarySortOrder = (
  sort?: TableSortOption,
): GetFixedIncomeInvestmentRegisterSortOrder => {
  let sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
  switch (sort?.order) {
    case "asc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
      break;
    case "desc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.DESCENDING;
      break;
  }

  let column =
    GetFixedIncomeInvestmentRegisterSortOrderEnums.ColumnName.ColumnName.SYMBOL;

  switch (sort?.id) {
    case i18next.t("common.symbol"):
      column =
        GetFixedIncomeInvestmentRegisterSortOrderEnums.ColumnName.ColumnName
          .SYMBOL;
      break;
    case i18next.t("fi.reports.investmentRegisterHeaders.instrumentType"):
      column =
        GetFixedIncomeInvestmentRegisterSortOrderEnums.ColumnName.ColumnName
          .INSTRUMENT_TYPE;
      break;
  }

  return new GetFixedIncomeInvestmentRegisterSortOrder(column, sortOrder);
};

export const getFiHoldingSummarySortOrder = (
  sort?: TableSortOption,
): GetFixedIncomeHoldingSummarySortOrder => {
  let sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
  switch (sort?.order) {
    case "asc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
      break;
    case "desc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.DESCENDING;
      break;
  }

  let column =
    GetFixedIncomeHoldingSummarySortOrderEnums.ColumnName.ColumnName
      .SECURITY_NAME;

  switch (sort?.id) {
    case i18next.t("fi.reports.holdingSummary.headers.securityName"):
      column =
        GetFixedIncomeHoldingSummarySortOrderEnums.ColumnName.ColumnName
          .SECURITY_NAME;
      break;
    case i18next.t("fi.reports.holdingSummary.headers.instrumentType"):
      column =
        GetFixedIncomeHoldingSummarySortOrderEnums.ColumnName.ColumnName
          .INSTRUMENT_TYPE;
      break;
  }

  return new GetFixedIncomeHoldingSummarySortOrder(column, sortOrder);
};
