"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFConfirmationHistoryDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const requestNote_1 = require("../types/requestNote");
const detailCellDiff_1 = require("../types/detailCellDiff");
const mFConfirmationRequestStatus_1 = require("./mFConfirmationRequestStatus");
class InvalidMFConfirmationHistoryDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFConfirmationHistoryDetailError";
    }
}
class MFConfirmationHistoryDetail {
    constructor(requestNote, status, diffDetails) {
        this.isMFConfirmationHistoryDetail = true;
        MFConfirmationHistoryDetail.validateRequestNote(requestNote);
        this.requestNote = requestNote;
        MFConfirmationHistoryDetail.validateStatus(status);
        this.status = status;
        MFConfirmationHistoryDetail.validateDiffDetails(diffDetails);
        this.diffDetails = diffDetails;
    }
    static fromDTO(dto) {
        const requestNote = requestNote_1.RequestNote.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestNote", InvalidMFConfirmationHistoryDetailError));
        const status = mFConfirmationRequestStatus_1.MFConfirmationRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidMFConfirmationHistoryDetailError));
        const diffDetails = (0, leo_ts_runtime_1.getList)(dto, "diffDetails", detailCellDiff_1.DetailCellDiff.fromDTO, InvalidMFConfirmationHistoryDetailError);
        return new MFConfirmationHistoryDetail(requestNote, status, diffDetails);
    }
    toDTO() {
        const ret = {
            requestNote: this.requestNote.toDTO(),
            status: mFConfirmationRequestStatus_1.MFConfirmationRequestStatus.toDTO(this.status),
            diffDetails: this.diffDetails.map((e) => {
                return e.toDTO();
            }),
        };
        return ret;
    }
    copy(requestNote = this.requestNote, status = this.status, diffDetails = this.diffDetails) {
        return new MFConfirmationHistoryDetail(requestNote, status, diffDetails);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMFConfirmationHistoryDetailValue = other.isMFConfirmationHistoryDetail;
        if (!(other instanceof MFConfirmationHistoryDetail) || Boolean(isMFConfirmationHistoryDetailValue)) {
            return false;
        }
        if (!this.requestNote.equals(other.requestNote)) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.diffDetails, other.diffDetails)) {
            return false;
        }
        return true;
    }
}
exports.MFConfirmationHistoryDetail = MFConfirmationHistoryDetail;
MFConfirmationHistoryDetail.validateRequestNote = (requestNote) => {
    const isRequestNoteValue = requestNote.isRequestNote;
    if (!(requestNote instanceof requestNote_1.RequestNote || Boolean(isRequestNoteValue))) {
        throw new InvalidMFConfirmationHistoryDetailError(`Attribute requestNote is not a RequestNote`);
    }
};
MFConfirmationHistoryDetail.validateStatus = (status) => {
    if (!mFConfirmationRequestStatus_1.MFConfirmationRequestStatus.isInstanceOf(status)) {
        throw new InvalidMFConfirmationHistoryDetailError(`Attribute status is not a MFConfirmationRequestStatus.MFConfirmationRequestStatus`);
    }
};
MFConfirmationHistoryDetail.validateDiffDetails = (diffDetails) => {
    if (!Array.isArray(diffDetails)) {
        throw new InvalidMFConfirmationHistoryDetailError(`Attribute diffDetails is not a List`);
    }
    for (let i = 0; i < diffDetails.length; i += 1) {
        const isDetailCellDiffValue = diffDetails[i].isDetailCellDiff;
        if (!(diffDetails[i] instanceof detailCellDiff_1.DetailCellDiff || Boolean(isDetailCellDiffValue))) {
            throw new InvalidMFConfirmationHistoryDetailError(`Attribute diffDetails at index ${i} is not a DetailCellDiff.`);
        }
    }
};
MFConfirmationHistoryDetail.prototype.toString = function toString() {
    return `MFConfirmationHistoryDetail(requestNote=${this.requestNote},status=${this.status},diffDetails=${this.diffDetails})`;
};
