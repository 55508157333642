import {
  FixedIncomeHoldingCategory,
  FixedIncomeInstrumentType,
} from "@khazana/khazana-rpcs";
import { DropdownItem } from "@surya-digital/leo-reactjs-core";
import { LeoDate, LeoUUID } from "@surya-digital/leo-ts-runtime";
import i18n from "i18next";
import { AllEnum } from "../../../types/EnumTypes";

export enum Period {
  BETWEEN_TWO_DATES = "BETWEEN_TWO_DATES",
  AS_ON_DATE = "AS_ON_DATE",
}

const getTranslation = (period: Period): string => {
  switch (period) {
    case Period.AS_ON_DATE:
      return i18n.t("reports.asOnDate");
    case Period.BETWEEN_TWO_DATES:
      return i18n.t("reports.betweenTwoDates");
    default:
      return "";
  }
};

export const getPeriodOptions = (): DropdownItem[] => {
  return [
    {
      name: getTranslation(Period.BETWEEN_TWO_DATES),
      value: Period.BETWEEN_TWO_DATES,
    },
    {
      name: getTranslation(Period.AS_ON_DATE),
      value: Period.AS_ON_DATE,
    },
  ];
};

const getHoldingCategoryOptionsTranslations = (
  category: FixedIncomeHoldingCategory.FixedIncomeHoldingCategory,
): string => {
  switch (category) {
    case FixedIncomeHoldingCategory.FixedIncomeHoldingCategory
      .AVAILABLE_FOR_SALE:
      return i18n.t("fi.reports.holdingCategoryOptions.availableForSale");
    case FixedIncomeHoldingCategory.FixedIncomeHoldingCategory.HOLD_FOR_TRADING:
      return i18n.t("fi.reports.holdingCategoryOptions.holdForTrading");
    case FixedIncomeHoldingCategory.FixedIncomeHoldingCategory
      .HOLD_TILL_MATURITY:
      return i18n.t("fi.reports.holdingCategoryOptions.holdTillMaturity");
  }
};

export const getHoldingCategoryOptionsWithAll = (): DropdownItem[] => {
  const dropdownItemList: DropdownItem[] = [];
  Object.values(FixedIncomeHoldingCategory.FixedIncomeHoldingCategory).forEach(
    (value) => {
      dropdownItemList.push({
        name: getHoldingCategoryOptionsTranslations(value),
        value,
      });
    },
  );
  return [
    {
      name: i18n.t("common.all"),
      value: AllEnum.All,
    },
    ...dropdownItemList,
  ];
};

const getInstrumentTypeOptionsTranslations = (
  category: FixedIncomeInstrumentType.FixedIncomeInstrumentType,
): string => {
  switch (category) {
    case FixedIncomeInstrumentType.FixedIncomeInstrumentType.CORPORATE_BOND:
      return i18n.t("fi.reports.instrumentTypeOptions.corporateBond");
    case FixedIncomeInstrumentType.FixedIncomeInstrumentType.G_SEC:
      return i18n.t("fi.reports.instrumentTypeOptions.gSec");
    case FixedIncomeInstrumentType.FixedIncomeInstrumentType.T_BILL:
      return i18n.t("fi.reports.instrumentTypeOptions.tBill");
  }
};

export const getInstrumentTypeOptionsWithAll = (): DropdownItem[] => {
  const dropdownItemList: DropdownItem[] = [];
  Object.values(FixedIncomeInstrumentType.FixedIncomeInstrumentType).forEach(
    (value) => {
      dropdownItemList.push({
        name: getInstrumentTypeOptionsTranslations(value),
        value,
      });
    },
  );
  return [
    {
      name: i18n.t("common.all"),
      value: AllEnum.All,
    },
    ...dropdownItemList,
  ];
};

export enum ReportExportFormatEnum {
  CSV = "CSV",
  XLSX = "XLSX",
}

export const reportExportFormats = [
  ReportExportFormatEnum.CSV,
  ReportExportFormatEnum.XLSX,
];

export const getExportReportDialogOptionValue = (
  exportFormat: ReportExportFormatEnum,
): DropdownItem => {
  switch (exportFormat) {
    case ReportExportFormatEnum.CSV:
      return {
        name: i18n.t("reports.csv"),
        value: ReportExportFormatEnum.CSV,
      };
    case ReportExportFormatEnum.XLSX:
      return {
        name: i18n.t("reports.xlsx"),
        value: ReportExportFormatEnum.XLSX,
      };
  }
};

export const getIdOrNull = (id: string | null | undefined): LeoUUID | null => {
  return id ? new LeoUUID(id) : null;
};

export function checkAllOptionAndGetId(id: string | undefined): LeoUUID | null {
  if (id === AllEnum.All) {
    return null;
  }
  return getIdOrNull(id);
}

export const getLeoDateOrNull = (date: string | undefined): LeoDate | null => {
  return date !== undefined && date !== "" ? new LeoDate(date) : null;
};

export const getDateOrNull = (date: string | undefined): Date | null => {
  return date !== undefined && date !== "" ? new Date(date) : null;
};
