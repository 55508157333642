"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetActiveAccrualsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
const currency_1 = require("../types/currency");
class InvalidGetActiveAccrualsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetActiveAccrualsPaginationResponseError";
    }
}
class GetActiveAccrualsPaginationResponse {
    constructor(accrualId, moduleName, correlationId, accuralType, rate, amountCCY, amountHCY, accrualCCYOverridden = null, accrualHCYOverridden = null, accrualStartDate, accrualEndDate, accrualPostedDate = null, isCredited, isPosted, currency) {
        this.isGetActiveAccrualsPaginationResponse = true;
        GetActiveAccrualsPaginationResponse.validateAccrualId(accrualId);
        this.accrualId = accrualId;
        GetActiveAccrualsPaginationResponse.validateModuleName(moduleName);
        this.moduleName = moduleName;
        GetActiveAccrualsPaginationResponse.validateCorrelationId(correlationId);
        this.correlationId = correlationId;
        GetActiveAccrualsPaginationResponse.validateAccuralType(accuralType);
        this.accuralType = accuralType;
        GetActiveAccrualsPaginationResponse.validateRate(rate);
        this.rate = rate;
        GetActiveAccrualsPaginationResponse.validateAmountCCY(amountCCY);
        this.amountCCY = amountCCY;
        GetActiveAccrualsPaginationResponse.validateAmountHCY(amountHCY);
        this.amountHCY = amountHCY;
        if (accrualCCYOverridden !== undefined && accrualCCYOverridden !== null) {
            GetActiveAccrualsPaginationResponse.validateAccrualCCYOverridden(accrualCCYOverridden);
        }
        this.accrualCCYOverridden = accrualCCYOverridden;
        if (accrualHCYOverridden !== undefined && accrualHCYOverridden !== null) {
            GetActiveAccrualsPaginationResponse.validateAccrualHCYOverridden(accrualHCYOverridden);
        }
        this.accrualHCYOverridden = accrualHCYOverridden;
        GetActiveAccrualsPaginationResponse.validateAccrualStartDate(accrualStartDate);
        this.accrualStartDate = accrualStartDate;
        GetActiveAccrualsPaginationResponse.validateAccrualEndDate(accrualEndDate);
        this.accrualEndDate = accrualEndDate;
        if (accrualPostedDate !== undefined && accrualPostedDate !== null) {
            GetActiveAccrualsPaginationResponse.validateAccrualPostedDate(accrualPostedDate);
        }
        this.accrualPostedDate = accrualPostedDate;
        GetActiveAccrualsPaginationResponse.validateIsCredited(isCredited);
        this.isCredited = isCredited;
        GetActiveAccrualsPaginationResponse.validateIsPosted(isPosted);
        this.isPosted = isPosted;
        GetActiveAccrualsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const accrualId = (0, leo_ts_runtime_1.getInt64)(dto, "accrualId", InvalidGetActiveAccrualsPaginationResponseError);
        const moduleName = (0, leo_ts_runtime_1.getString)(dto, "moduleName", InvalidGetActiveAccrualsPaginationResponseError);
        const correlationId = (0, leo_ts_runtime_1.getInt64)(dto, "correlationId", InvalidGetActiveAccrualsPaginationResponseError);
        const accuralType = (0, leo_ts_runtime_1.getString)(dto, "accuralType", InvalidGetActiveAccrualsPaginationResponseError);
        const rate = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "rate", InvalidGetActiveAccrualsPaginationResponseError));
        const amountCCY = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amountCCY", InvalidGetActiveAccrualsPaginationResponseError));
        const amountHCY = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amountHCY", InvalidGetActiveAccrualsPaginationResponseError));
        let accrualCCYOverridden = (0, leo_ts_runtime_1.getOptionalObject)(dto, "accrualCCYOverridden");
        if (accrualCCYOverridden !== undefined && accrualCCYOverridden !== null) {
            accrualCCYOverridden = bigDecimal_1.BigDecimal.fromDTO(accrualCCYOverridden);
        }
        let accrualHCYOverridden = (0, leo_ts_runtime_1.getOptionalObject)(dto, "accrualHCYOverridden");
        if (accrualHCYOverridden !== undefined && accrualHCYOverridden !== null) {
            accrualHCYOverridden = bigDecimal_1.BigDecimal.fromDTO(accrualHCYOverridden);
        }
        const accrualStartDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "accrualStartDate", InvalidGetActiveAccrualsPaginationResponseError);
        const accrualEndDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "accrualEndDate", InvalidGetActiveAccrualsPaginationResponseError);
        const accrualPostedDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "accrualPostedDate", InvalidGetActiveAccrualsPaginationResponseError);
        const isCredited = (0, leo_ts_runtime_1.getBoolean)(dto, "isCredited", InvalidGetActiveAccrualsPaginationResponseError);
        const isPosted = (0, leo_ts_runtime_1.getBoolean)(dto, "isPosted", InvalidGetActiveAccrualsPaginationResponseError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetActiveAccrualsPaginationResponseError));
        return new GetActiveAccrualsPaginationResponse(accrualId, moduleName, correlationId, accuralType, rate, amountCCY, amountHCY, accrualCCYOverridden, accrualHCYOverridden, accrualStartDate, accrualEndDate, accrualPostedDate, isCredited, isPosted, currency);
    }
    toDTO() {
        const ret = {
            accrualId: this.accrualId,
            moduleName: this.moduleName,
            correlationId: this.correlationId,
            accuralType: this.accuralType,
            rate: this.rate.toDTO(),
            amountCCY: this.amountCCY.toDTO(),
            amountHCY: this.amountHCY.toDTO(),
            accrualStartDate: this.accrualStartDate.toDTO(),
            accrualEndDate: this.accrualEndDate.toDTO(),
            isCredited: this.isCredited,
            isPosted: this.isPosted,
            currency: this.currency.toDTO(),
        };
        if (this.accrualCCYOverridden) {
            ret.accrualCCYOverridden = this.accrualCCYOverridden.toDTO();
        }
        if (this.accrualHCYOverridden) {
            ret.accrualHCYOverridden = this.accrualHCYOverridden.toDTO();
        }
        if (this.accrualPostedDate) {
            ret.accrualPostedDate = this.accrualPostedDate.toDTO();
        }
        return ret;
    }
    copy(accrualId = this.accrualId, moduleName = this.moduleName, correlationId = this.correlationId, accuralType = this.accuralType, rate = this.rate, amountCCY = this.amountCCY, amountHCY = this.amountHCY, accrualCCYOverridden = this.accrualCCYOverridden, accrualHCYOverridden = this.accrualHCYOverridden, accrualStartDate = this.accrualStartDate, accrualEndDate = this.accrualEndDate, accrualPostedDate = this.accrualPostedDate, isCredited = this.isCredited, isPosted = this.isPosted, currency = this.currency) {
        return new GetActiveAccrualsPaginationResponse(accrualId, moduleName, correlationId, accuralType, rate, amountCCY, amountHCY, accrualCCYOverridden, accrualHCYOverridden, accrualStartDate, accrualEndDate, accrualPostedDate, isCredited, isPosted, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetActiveAccrualsPaginationResponseValue = other.isGetActiveAccrualsPaginationResponse;
        if (!(other instanceof GetActiveAccrualsPaginationResponse) || Boolean(isGetActiveAccrualsPaginationResponseValue)) {
            return false;
        }
        if (this.accrualId !== other.accrualId) {
            return false;
        }
        if (this.moduleName !== other.moduleName) {
            return false;
        }
        if (this.correlationId !== other.correlationId) {
            return false;
        }
        if (this.accuralType !== other.accuralType) {
            return false;
        }
        if (!this.rate.equals(other.rate)) {
            return false;
        }
        if (!this.amountCCY.equals(other.amountCCY)) {
            return false;
        }
        if (!this.amountHCY.equals(other.amountHCY)) {
            return false;
        }
        if (this.accrualCCYOverridden) {
            if (!this.accrualCCYOverridden.equals(other.accrualCCYOverridden)) {
                return false;
            }
        }
        if (this.accrualHCYOverridden) {
            if (!this.accrualHCYOverridden.equals(other.accrualHCYOverridden)) {
                return false;
            }
        }
        if (!this.accrualStartDate.equals(other.accrualStartDate)) {
            return false;
        }
        if (!this.accrualEndDate.equals(other.accrualEndDate)) {
            return false;
        }
        if (this.accrualPostedDate) {
            if (!this.accrualPostedDate.equals(other.accrualPostedDate)) {
                return false;
            }
        }
        if (this.isCredited !== other.isCredited) {
            return false;
        }
        if (this.isPosted !== other.isPosted) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.GetActiveAccrualsPaginationResponse = GetActiveAccrualsPaginationResponse;
GetActiveAccrualsPaginationResponse.validateAccrualId = (accrualId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(accrualId)) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute accrualId is not an Int64`);
    }
};
GetActiveAccrualsPaginationResponse.validateModuleName = (moduleName) => {
    if (!(0, leo_ts_runtime_1.isString)(moduleName)) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute moduleName is not a String`);
    }
    if (moduleName.trim().length === 0) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute moduleName cannot be an empty string.`);
    }
};
GetActiveAccrualsPaginationResponse.validateCorrelationId = (correlationId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(correlationId)) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute correlationId is not an Int64`);
    }
};
GetActiveAccrualsPaginationResponse.validateAccuralType = (accuralType) => {
    if (!(0, leo_ts_runtime_1.isString)(accuralType)) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute accuralType is not a String`);
    }
    if (accuralType.trim().length === 0) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute accuralType cannot be an empty string.`);
    }
};
GetActiveAccrualsPaginationResponse.validateRate = (rate) => {
    const isBigDecimalValue = rate.isBigDecimal;
    if (!(rate instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute rate is not a BigDecimal`);
    }
};
GetActiveAccrualsPaginationResponse.validateAmountCCY = (amountCCY) => {
    const isBigDecimalValue = amountCCY.isBigDecimal;
    if (!(amountCCY instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute amountCCY is not a BigDecimal`);
    }
};
GetActiveAccrualsPaginationResponse.validateAmountHCY = (amountHCY) => {
    const isBigDecimalValue = amountHCY.isBigDecimal;
    if (!(amountHCY instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute amountHCY is not a BigDecimal`);
    }
};
GetActiveAccrualsPaginationResponse.validateAccrualCCYOverridden = (accrualCCYOverridden) => {
    const isBigDecimalValue = accrualCCYOverridden.isBigDecimal;
    if (!(accrualCCYOverridden instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute accrualCCYOverridden is not a BigDecimal`);
    }
};
GetActiveAccrualsPaginationResponse.validateAccrualHCYOverridden = (accrualHCYOverridden) => {
    const isBigDecimalValue = accrualHCYOverridden.isBigDecimal;
    if (!(accrualHCYOverridden instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute accrualHCYOverridden is not a BigDecimal`);
    }
};
GetActiveAccrualsPaginationResponse.validateAccrualStartDate = (accrualStartDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(accrualStartDate)) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute accrualStartDate is not a Date`);
    }
};
GetActiveAccrualsPaginationResponse.validateAccrualEndDate = (accrualEndDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(accrualEndDate)) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute accrualEndDate is not a Date`);
    }
};
GetActiveAccrualsPaginationResponse.validateAccrualPostedDate = (accrualPostedDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(accrualPostedDate)) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute accrualPostedDate is not a Date`);
    }
};
GetActiveAccrualsPaginationResponse.validateIsCredited = (isCredited) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isCredited)) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute isCredited is not a Boolean`);
    }
};
GetActiveAccrualsPaginationResponse.validateIsPosted = (isPosted) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isPosted)) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute isPosted is not a Boolean`);
    }
};
GetActiveAccrualsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetActiveAccrualsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetActiveAccrualsPaginationResponse.prototype.toString = function toString() {
    return `GetActiveAccrualsPaginationResponse(accrualId=${this.accrualId},moduleName='${this.moduleName}',correlationId=${this.correlationId},accuralType='${this.accuralType}',rate=${this.rate},amountCCY=${this.amountCCY},amountHCY=${this.amountHCY},accrualCCYOverridden=${this.accrualCCYOverridden},accrualHCYOverridden=${this.accrualHCYOverridden},accrualStartDate=${this.accrualStartDate},accrualEndDate=${this.accrualEndDate},accrualPostedDate=${this.accrualPostedDate},isCredited=${this.isCredited},isPosted=${this.isPosted},currency=${this.currency})`;
};
