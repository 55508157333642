import {
  Instance,
  types,
  getSnapshot,
  applySnapshot,
  flow,
} from "mobx-state-tree";
import {
  InvestmentCashflowBannerStore,
  createInvestmentCashflowBannerStore,
} from "../../../components/cash-flow-summary/InvestmentCashflowBannerStore";
import { ModuleType } from "@khazana/khazana-rpcs";
import {
  FiInvestmentDetailsStore,
  createFiInvestmentDetailsStore,
} from "../../components/investment-details/FiInvestmentDetailsStore";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { FiCashflowsStore, createFiCashflowsStore } from "./FiCashflowsStore";
import { FiAccrualStore, createFiAccrualStore } from "./FiAccrualStore";
import i18next from "i18next";
import { getPath } from "../../../../../utils/RoutesUtils";

export const FiViewCashflowsStore = types
  .model("FiViewCashflowsStore", {
    error: types.optional(types.boolean, false),
    investmentDetailsBannerStore: InvestmentCashflowBannerStore,
    investmentDetailsStore: FiInvestmentDetailsStore,
    cashflowsStore: FiCashflowsStore,
    accrualStore: FiAccrualStore,
    currencySymbol: types.maybeNull(types.string),
    previousContractNoteId: types.maybe(types.number),
    previousMaturityRequestId: types.maybe(types.number),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    setPreviousId: (
      contractNoteId?: number,
      maturityRequestId?: number,
    ): void => {
      store.previousContractNoteId = contractNoteId;
      store.previousMaturityRequestId = maturityRequestId;
    },
    softReset: (): void => {
      store.investmentDetailsBannerStore.reset();
      store.investmentDetailsStore.resetStore();
      store.accrualStore.reset();
      store.cashflowsStore.reset();
    },
    getInvestmentBannerData: flow(function* (requestId: number) {
      store.error = false;
      yield store.investmentDetailsBannerStore.getInvestmentCashflowBannerDetails(
        requestId,
        null,
        ModuleType.ModuleType.FIXED_INCOME,
      );
      store.error = Boolean(store.investmentDetailsBannerStore.error);
    }),
    getInvestmentDetails: flow(function* (investmentId: number) {
      yield store.investmentDetailsStore.getInvestmentDetails(
        undefined,
        undefined,
        investmentId,
        undefined,
      );
      store.error = store.investmentDetailsStore.error;
    }),
  }))
  .views((store) => ({
    getBaseRoute: (): string | undefined => {
      if (store.previousContractNoteId !== undefined) {
        return getPath(Module.Fi, Route.ManageContractNotes);
      } else if (store.previousMaturityRequestId !== undefined) {
        return getPath(Module.Fi, Route.ManageMaturityRequest);
      }
    },
    getBaseBreadcrumbLabel: (): string => {
      if (store.previousContractNoteId !== undefined) {
        return i18next.t("common.manageContractNotes");
      } else if (store.previousMaturityRequestId !== undefined) {
        return i18next.t("common.manageMaturityRequest");
      }
      return "";
    },
    getLevel1Route: (): string | undefined => {
      if (store.previousContractNoteId !== undefined) {
        return getPath(
          Module.Fi,
          Route.ContractNotesDetailsWithParams,
          store.previousContractNoteId.toString(),
        );
      } else if (store.previousMaturityRequestId !== undefined) {
        return getPath(
          Module.Fi,
          Route.ManageMaturityRequestDetailsWithParams,
          store.previousMaturityRequestId.toString(),
        );
      }
    },
    getLevel1BreadcrumbLabel: (): string => {
      if (store.previousContractNoteId !== undefined) {
        return i18next.t("fi.contractNotes.contractNoteDetailTitle");
      } else if (store.previousMaturityRequestId !== undefined) {
        return i18next.t("fi.fiMaturityRequestDetails");
      }
      return "";
    },
  }));

export const createFiViewCashflowsStore = (): Instance<
  typeof FiViewCashflowsStore
> => {
  return FiViewCashflowsStore.create({
    investmentDetailsBannerStore: createInvestmentCashflowBannerStore(),
    investmentDetailsStore: createFiInvestmentDetailsStore(),
    cashflowsStore: createFiCashflowsStore(),
    accrualStore: createFiAccrualStore(),
  });
};
