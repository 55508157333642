"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedIncomeHoldingCategory = exports.InvalidFixedIncomeHoldingCategoryError = void 0;
class InvalidFixedIncomeHoldingCategoryError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedIncomeHoldingCategoryError";
    }
}
exports.InvalidFixedIncomeHoldingCategoryError = InvalidFixedIncomeHoldingCategoryError;
/* eslint-disable @typescript-eslint/no-shadow */
var FixedIncomeHoldingCategory;
(function (FixedIncomeHoldingCategory_1) {
    let FixedIncomeHoldingCategory;
    (function (FixedIncomeHoldingCategory) {
        FixedIncomeHoldingCategory["AVAILABLE_FOR_SALE"] = "AVAILABLE_FOR_SALE";
        FixedIncomeHoldingCategory["HOLD_TILL_MATURITY"] = "HOLD_TILL_MATURITY";
        FixedIncomeHoldingCategory["HOLD_FOR_TRADING"] = "HOLD_FOR_TRADING";
    })(FixedIncomeHoldingCategory = FixedIncomeHoldingCategory_1.FixedIncomeHoldingCategory || (FixedIncomeHoldingCategory_1.FixedIncomeHoldingCategory = {}));
    FixedIncomeHoldingCategory_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "AVAILABLE_FOR_SALE":
                return FixedIncomeHoldingCategory.AVAILABLE_FOR_SALE;
            case "HOLD_TILL_MATURITY":
                return FixedIncomeHoldingCategory.HOLD_TILL_MATURITY;
            case "HOLD_FOR_TRADING":
                return FixedIncomeHoldingCategory.HOLD_FOR_TRADING;
            default:
                throw new InvalidFixedIncomeHoldingCategoryError(`Case ${dto.case} is not valid case of FixedIncomeHoldingCategory`);
        }
    };
    FixedIncomeHoldingCategory_1.toDTO = (fixedIncomeHoldingCategory) => {
        const ret = {
            case: FixedIncomeHoldingCategory[fixedIncomeHoldingCategory],
        };
        return ret;
    };
    FixedIncomeHoldingCategory_1.isInstanceOf = (other) => {
        if (other in FixedIncomeHoldingCategory) {
            return true;
        }
        return false;
    };
})(FixedIncomeHoldingCategory = exports.FixedIncomeHoldingCategory || (exports.FixedIncomeHoldingCategory = {}));
/* eslint-enable @typescript-eslint/no-shadow */
