import { FiDealType } from "../../../../../types/EnumTypes";
import {
  ChipColor,
  DropdownItem,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-core";
import {
  FiDealRequestStatus,
  FiHoldingCategory,
  FiMaturityDealRequestStatus,
  FixedIncomeInstrumentType,
} from "@khazana/khazana-rpcs";
import { ToggleButtonItems } from "../../../components/toggleButton/KHToggleButton";
import i18next from "i18next";

export const getDealRequestTabBackgroundColor = (
  fiDealType: FiDealType,
  tokens: FoundationColorTokens<string>,
): string => {
  switch (fiDealType) {
    case FiDealType.Purchase:
      return tokens.backgroundInfoSubtle;
    case FiDealType.Sell:
      return tokens.backgroundWarningSubtle;
  }
};

export const getFiStatusTextColor = (
  status: FiDealRequestStatus.FiDealRequestStatus,
): ChipColor => {
  switch (status) {
    case FiDealRequestStatus.FiDealRequestStatus.CANCELLED:
    case FiDealRequestStatus.FiDealRequestStatus.EXPIRED:
      return "neutral";
    default:
      return "primary";
  }
};

export const getFiStatusColor = (
  status: FiDealRequestStatus.FiDealRequestStatus,
): ChipColor => {
  switch (status) {
    case FiDealRequestStatus.FiDealRequestStatus.ACCOUNTS_PENDING:
    case FiDealRequestStatus.FiDealRequestStatus.IC_PENDING:
    case FiDealRequestStatus.FiDealRequestStatus.IC_APPROVED:
    case FiDealRequestStatus.FiDealRequestStatus.DEAL_OPEN:
    case FiDealRequestStatus.FiDealRequestStatus.ACCOUNTS_APPROVED:
      return "orange";
    case FiDealRequestStatus.FiDealRequestStatus.CANCELLED:
    case FiDealRequestStatus.FiDealRequestStatus.EXPIRED:
      return "neutral";
    case FiDealRequestStatus.FiDealRequestStatus.ACCOUNTS_REJECTED:
    case FiDealRequestStatus.FiDealRequestStatus.IC_REJECTED:
      return "red";
    case FiDealRequestStatus.FiDealRequestStatus.SETTLED:
    case FiDealRequestStatus.FiDealRequestStatus.SYSTEM_SETTLED:
      return "green";
    default:
      return "neutral";
  }
};

export const getFiMaturityStatusColor = (
  status: FiMaturityDealRequestStatus.FiMaturityDealRequestStatus,
): ChipColor => {
  switch (status) {
    case FiMaturityDealRequestStatus.FiMaturityDealRequestStatus
      .SETTLEMENT_PENDING:
      return "orange";
    case FiMaturityDealRequestStatus.FiMaturityDealRequestStatus.SETTLED:
      return "green";
    default:
      return "neutral";
  }
};

export const getFISecurityTypeLabel = (
  type: FixedIncomeInstrumentType.FixedIncomeInstrumentType,
): string => {
  switch (type) {
    case FixedIncomeInstrumentType.FixedIncomeInstrumentType.G_SEC:
      return i18next.t("fi.instrumentTypeOptions.gSec");
    case FixedIncomeInstrumentType.FixedIncomeInstrumentType.T_BILL:
      return i18next.t("fi.instrumentTypeOptions.tBill");
    case FixedIncomeInstrumentType.FixedIncomeInstrumentType.CORPORATE_BOND:
      return i18next.t("fi.instrumentTypeOptions.corporateBond");
  }
};

export const getFIHoldingCategoryLabel = (
  type: FiHoldingCategory.FiHoldingCategory,
): string => {
  switch (type) {
    case FiHoldingCategory.FiHoldingCategory.HOLD_FOR_TRADING:
      return i18next.t("fi.holdingCategoryOptions.HFT");
    case FiHoldingCategory.FiHoldingCategory.AVAILABLE_FOR_SALE:
      return i18next.t("fi.holdingCategoryOptions.AFS");
    case FiHoldingCategory.FiHoldingCategory.HOLD_TILL_MATURITY:
      return i18next.t("fi.holdingCategoryOptions.HTM");
  }
};

export const getFISecurityTypeToggleButtonOptions = (): ToggleButtonItems[] => {
  return Object.values(FixedIncomeInstrumentType.FixedIncomeInstrumentType).map(
    (value) => {
      return {
        label: getFISecurityTypeLabel(value),
        value,
      };
    },
  );
};

export const getFISecurityTypeDropdownOptions = (): DropdownItem[] => {
  return Object.values(FixedIncomeInstrumentType.FixedIncomeInstrumentType).map(
    (value) => {
      return {
        name: getFISecurityTypeLabel(value),
        value,
      };
    },
  );
};

export const getFIAllocationToggleButtonOptions = (): ToggleButtonItems[] => {
  return Object.values(FiHoldingCategory.FiHoldingCategory).map((value) => {
    return {
      label: getFIHoldingCategoryLabel(value),
      value,
    };
  });
};
