"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateFiSellDealRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const note_1 = require("../types/note");
const quantity_1 = require("../types/quantity");
const fiCollection_1 = require("./fiCollection");
const fiSellDealValue_1 = require("./fiSellDealValue");
const fiPriceYtm_1 = require("./fiPriceYtm");
/* eslint-disable import/export */
class CreateFiSellDealRequestRPC {
}
exports.CreateFiSellDealRequestRPC = CreateFiSellDealRequestRPC;
(function (CreateFiSellDealRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CreateFiSellDealRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId, fiCollection, isin, dealValue, priceYtm, bankId, bankAccountNumber, note = null) {
            this.isCreateFiSellDealRequestRPCRequest = true;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            Request.validateFiCollection(fiCollection);
            this.fiCollection = fiCollection;
            Request.validateIsin(isin);
            this.isin = isin;
            Request.validateDealValue(dealValue);
            this.dealValue = dealValue;
            Request.validatePriceYtm(priceYtm);
            this.priceYtm = priceYtm;
            Request.validateBankId(bankId);
            this.bankId = bankId;
            Request.validateBankAccountNumber(bankAccountNumber);
            this.bankAccountNumber = bankAccountNumber;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const fiCollection = fiCollection_1.FiCollection.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "fiCollection", InvalidRequestError));
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidRequestError));
            const dealValue = fiSellDealValue_1.FiSellDealValue.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "dealValue", InvalidRequestError));
            const priceYtm = fiPriceYtm_1.FiPriceYtm.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "priceYtm", InvalidRequestError));
            const bankId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "bankId", InvalidRequestError);
            const bankAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "bankAccountNumber", InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(entityId, fiCollection, isin, dealValue, priceYtm, bankId, bankAccountNumber, note);
        }
        toDTO() {
            const ret = {
                entityId: this.entityId.toDTO(),
                fiCollection: this.fiCollection.toDTO(),
                isin: this.isin.toDTO(),
                dealValue: this.dealValue.toDTO(),
                priceYtm: this.priceYtm.toDTO(),
                bankId: this.bankId.toDTO(),
                bankAccountNumber: this.bankAccountNumber,
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(entityId = this.entityId, fiCollection = this.fiCollection, isin = this.isin, dealValue = this.dealValue, priceYtm = this.priceYtm, bankId = this.bankId, bankAccountNumber = this.bankAccountNumber, note = this.note) {
            return new Request(entityId, fiCollection, isin, dealValue, priceYtm, bankId, bankAccountNumber, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCreateFiSellDealRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (!this.fiCollection.equals(other.fiCollection)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            if (!this.dealValue.equals(other.dealValue)) {
                return false;
            }
            if (!this.priceYtm.equals(other.priceYtm)) {
                return false;
            }
            if (!this.bankId.equals(other.bankId)) {
                return false;
            }
            if (this.bankAccountNumber !== other.bankAccountNumber) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validateFiCollection = (fiCollection) => {
        if (!fiCollection_1.FiCollection.isInstanceOf(fiCollection)) {
            throw new InvalidRequestError(`Attribute fiCollection is not a FiCollection.FiCollection`);
        }
    };
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validateDealValue = (dealValue) => {
        if (!fiSellDealValue_1.FiSellDealValue.isInstanceOf(dealValue)) {
            throw new InvalidRequestError(`Attribute dealValue is not a FiSellDealValue.FiSellDealValue`);
        }
    };
    Request.validatePriceYtm = (priceYtm) => {
        if (!fiPriceYtm_1.FiPriceYtm.isInstanceOf(priceYtm)) {
            throw new InvalidRequestError(`Attribute priceYtm is not a FiPriceYtm.FiPriceYtm`);
        }
    };
    Request.validateBankId = (bankId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(bankId)) {
            throw new InvalidRequestError(`Attribute bankId is not a UUID`);
        }
    };
    Request.validateBankAccountNumber = (bankAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(bankAccountNumber)) {
            throw new InvalidRequestError(`Attribute bankAccountNumber is not a String`);
        }
        if (bankAccountNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute bankAccountNumber cannot be an empty string.`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    CreateFiSellDealRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CreateFiSellDealRequestRPC.Request(entityId=${this.entityId},fiCollection=${this.fiCollection},isin=${this.isin},dealValue=${this.dealValue},priceYtm=${this.priceYtm},bankId=${this.bankId},bankAccountNumber='${this.bankAccountNumber}',note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CreateFiSellDealRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(dealRequestId) {
            this.isCreateFiSellDealRequestRPCResponse = true;
            Response.validateDealRequestId(dealRequestId);
            this.dealRequestId = dealRequestId;
        }
        static fromDTO(dto) {
            const dealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "dealRequestId", InvalidResponseError);
            return new Response(dealRequestId);
        }
        toDTO() {
            const ret = {
                dealRequestId: this.dealRequestId,
            };
            return ret;
        }
        copy(dealRequestId = this.dealRequestId) {
            return new Response(dealRequestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isCreateFiSellDealRequestRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.dealRequestId !== other.dealRequestId) {
                return false;
            }
            return true;
        }
    }
    Response.validateDealRequestId = (dealRequestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(dealRequestId)) {
            throw new InvalidResponseError(`Attribute dealRequestId is not an Int64`);
        }
    };
    CreateFiSellDealRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `CreateFiSellDealRequestRPC.Response(dealRequestId=${this.dealRequestId})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `CreateFiSellDealRequestRPC.INVALID_ISIN()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `CreateFiSellDealRequestRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidPortfolioId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO_ID") {
                super(code);
                this.isInvalidPortfolioId = true;
                InvalidPortfolioId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolioId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolioId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
                if (!(other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolioId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolioId = InvalidPortfolioId;
        InvalidPortfolioId.prototype.toString = function toString() {
            return `CreateFiSellDealRequestRPC.INVALID_PORTFOLIO_ID()`;
        };
        class InvalidDematAccountNumber extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEMAT_ACCOUNT_NUMBER") {
                super(code);
                this.isInvalidDematAccountNumber = true;
                InvalidDematAccountNumber.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDematAccountNumber(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDematAccountNumber(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDematAccountNumberValue = other.isInvalidDematAccountNumber;
                if (!(other instanceof InvalidDematAccountNumber || Boolean(isInvalidDematAccountNumberValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDematAccountNumber.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEMAT_ACCOUNT_NUMBER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDematAccountNumber = InvalidDematAccountNumber;
        InvalidDematAccountNumber.prototype.toString = function toString() {
            return `CreateFiSellDealRequestRPC.INVALID_DEMAT_ACCOUNT_NUMBER()`;
        };
        class CurrencyMismatch extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CURRENCY_MISMATCH") {
                super(code);
                this.isCurrencyMismatch = true;
                CurrencyMismatch.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CurrencyMismatch(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CurrencyMismatch(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCurrencyMismatchValue = other.isCurrencyMismatch;
                if (!(other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CurrencyMismatch.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CURRENCY_MISMATCH") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CurrencyMismatch = CurrencyMismatch;
        CurrencyMismatch.prototype.toString = function toString() {
            return `CreateFiSellDealRequestRPC.CURRENCY_MISMATCH()`;
        };
        class InsufficientGrossQuantity extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INSUFFICIENT_GROSS_QUANTITY", grossQuantity) {
                super(code);
                this.isInsufficientGrossQuantity = true;
                InsufficientGrossQuantity.validateCode(code);
                InsufficientGrossQuantity.validateGrossQuantity(grossQuantity);
                this.grossQuantity = grossQuantity;
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                const grossQuantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "grossQuantity", InvalidResponseError));
                return new InsufficientGrossQuantity(code, grossQuantity);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                    grossQuantity: this.grossQuantity.toDTO(),
                };
                return ret;
            }
            copy(code = this.code, grossQuantity = this.grossQuantity) {
                return new InsufficientGrossQuantity(code, grossQuantity);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInsufficientGrossQuantityValue = other.isInsufficientGrossQuantity;
                if (!(other instanceof InsufficientGrossQuantity || Boolean(isInsufficientGrossQuantityValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                if (!this.grossQuantity.equals(other.grossQuantity)) {
                    return false;
                }
                return true;
            }
        }
        InsufficientGrossQuantity.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INSUFFICIENT_GROSS_QUANTITY") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        InsufficientGrossQuantity.validateGrossQuantity = (grossQuantity) => {
            const isQuantityValue = grossQuantity.isQuantity;
            if (!(grossQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
                throw new InvalidResponseError(`Attribute grossQuantity is not a Quantity`);
            }
        };
        Errors.InsufficientGrossQuantity = InsufficientGrossQuantity;
        InsufficientGrossQuantity.prototype.toString = function toString() {
            return `CreateFiSellDealRequestRPC.INSUFFICIENT_GROSS_QUANTITY(grossQuantity=${this.grossQuantity})`;
        };
        class InsufficientNetQuantity extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INSUFFICIENT_NET_QUANTITY", netQuantity) {
                super(code);
                this.isInsufficientNetQuantity = true;
                InsufficientNetQuantity.validateCode(code);
                InsufficientNetQuantity.validateNetQuantity(netQuantity);
                this.netQuantity = netQuantity;
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                const netQuantity = quantity_1.Quantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "netQuantity", InvalidResponseError));
                return new InsufficientNetQuantity(code, netQuantity);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                    netQuantity: this.netQuantity.toDTO(),
                };
                return ret;
            }
            copy(code = this.code, netQuantity = this.netQuantity) {
                return new InsufficientNetQuantity(code, netQuantity);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInsufficientNetQuantityValue = other.isInsufficientNetQuantity;
                if (!(other instanceof InsufficientNetQuantity || Boolean(isInsufficientNetQuantityValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                if (!this.netQuantity.equals(other.netQuantity)) {
                    return false;
                }
                return true;
            }
        }
        InsufficientNetQuantity.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INSUFFICIENT_NET_QUANTITY") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        InsufficientNetQuantity.validateNetQuantity = (netQuantity) => {
            const isQuantityValue = netQuantity.isQuantity;
            if (!(netQuantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
                throw new InvalidResponseError(`Attribute netQuantity is not a Quantity`);
            }
        };
        Errors.InsufficientNetQuantity = InsufficientNetQuantity;
        InsufficientNetQuantity.prototype.toString = function toString() {
            return `CreateFiSellDealRequestRPC.INSUFFICIENT_NET_QUANTITY(netQuantity=${this.netQuantity})`;
        };
        class AmountLimitExceeded extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "AMOUNT_LIMIT_EXCEEDED") {
                super(code);
                this.isAmountLimitExceeded = true;
                AmountLimitExceeded.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new AmountLimitExceeded(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new AmountLimitExceeded(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isAmountLimitExceededValue = other.isAmountLimitExceeded;
                if (!(other instanceof AmountLimitExceeded || Boolean(isAmountLimitExceededValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        AmountLimitExceeded.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "AMOUNT_LIMIT_EXCEEDED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.AmountLimitExceeded = AmountLimitExceeded;
        AmountLimitExceeded.prototype.toString = function toString() {
            return `CreateFiSellDealRequestRPC.AMOUNT_LIMIT_EXCEEDED()`;
        };
        class NeitherMaturityNorNextOptionDateFound extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND") {
                super(code);
                this.isNeitherMaturityNorNextOptionDateFound = true;
                NeitherMaturityNorNextOptionDateFound.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NeitherMaturityNorNextOptionDateFound(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NeitherMaturityNorNextOptionDateFound(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNeitherMaturityNorNextOptionDateFoundValue = other.isNeitherMaturityNorNextOptionDateFound;
                if (!(other instanceof NeitherMaturityNorNextOptionDateFound || Boolean(isNeitherMaturityNorNextOptionDateFoundValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NeitherMaturityNorNextOptionDateFound.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NeitherMaturityNorNextOptionDateFound = NeitherMaturityNorNextOptionDateFound;
        NeitherMaturityNorNextOptionDateFound.prototype.toString = function toString() {
            return `CreateFiSellDealRequestRPC.NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND()`;
        };
        class InvalidBankId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_BANK_ID") {
                super(code);
                this.isInvalidBankId = true;
                InvalidBankId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidBankId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidBankId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidBankIdValue = other.isInvalidBankId;
                if (!(other instanceof InvalidBankId || Boolean(isInvalidBankIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidBankId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_BANK_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidBankId = InvalidBankId;
        InvalidBankId.prototype.toString = function toString() {
            return `CreateFiSellDealRequestRPC.INVALID_BANK_ID()`;
        };
        class InvalidAccountNumber extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ACCOUNT_NUMBER") {
                super(code);
                this.isInvalidAccountNumber = true;
                InvalidAccountNumber.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidAccountNumber(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidAccountNumber(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidAccountNumberValue = other.isInvalidAccountNumber;
                if (!(other instanceof InvalidAccountNumber || Boolean(isInvalidAccountNumberValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidAccountNumber.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ACCOUNT_NUMBER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidAccountNumber = InvalidAccountNumber;
        InvalidAccountNumber.prototype.toString = function toString() {
            return `CreateFiSellDealRequestRPC.INVALID_ACCOUNT_NUMBER()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_PORTFOLIO_ID":
                    return InvalidPortfolioId.fromDTO(dto);
                case "INVALID_DEMAT_ACCOUNT_NUMBER":
                    return InvalidDematAccountNumber.fromDTO(dto);
                case "CURRENCY_MISMATCH":
                    return CurrencyMismatch.fromDTO(dto);
                case "INSUFFICIENT_GROSS_QUANTITY":
                    return InsufficientGrossQuantity.fromDTO(dto);
                case "INSUFFICIENT_NET_QUANTITY":
                    return InsufficientNetQuantity.fromDTO(dto);
                case "AMOUNT_LIMIT_EXCEEDED":
                    return AmountLimitExceeded.fromDTO(dto);
                case "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND":
                    return NeitherMaturityNorNextOptionDateFound.fromDTO(dto);
                case "INVALID_BANK_ID":
                    return InvalidBankId.fromDTO(dto);
                case "INVALID_ACCOUNT_NUMBER":
                    return InvalidAccountNumber.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
            if (other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue)) {
                return true;
            }
            const isInvalidDematAccountNumberValue = other.isInvalidDematAccountNumber;
            if (other instanceof InvalidDematAccountNumber || Boolean(isInvalidDematAccountNumberValue)) {
                return true;
            }
            const isCurrencyMismatchValue = other.isCurrencyMismatch;
            if (other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue)) {
                return true;
            }
            const isInsufficientGrossQuantityValue = other.isInsufficientGrossQuantity;
            if (other instanceof InsufficientGrossQuantity || Boolean(isInsufficientGrossQuantityValue)) {
                return true;
            }
            const isInsufficientNetQuantityValue = other.isInsufficientNetQuantity;
            if (other instanceof InsufficientNetQuantity || Boolean(isInsufficientNetQuantityValue)) {
                return true;
            }
            const isAmountLimitExceededValue = other.isAmountLimitExceeded;
            if (other instanceof AmountLimitExceeded || Boolean(isAmountLimitExceededValue)) {
                return true;
            }
            const isNeitherMaturityNorNextOptionDateFoundValue = other.isNeitherMaturityNorNextOptionDateFound;
            if (other instanceof NeitherMaturityNorNextOptionDateFound || Boolean(isNeitherMaturityNorNextOptionDateFoundValue)) {
                return true;
            }
            const isInvalidBankIdValue = other.isInvalidBankId;
            if (other instanceof InvalidBankId || Boolean(isInvalidBankIdValue)) {
                return true;
            }
            const isInvalidAccountNumberValue = other.isInvalidAccountNumber;
            if (other instanceof InvalidAccountNumber || Boolean(isInvalidAccountNumberValue)) {
                return true;
            }
            return false;
        };
    })(Errors = CreateFiSellDealRequestRPC.Errors || (CreateFiSellDealRequestRPC.Errors = {}));
})(CreateFiSellDealRequestRPC = exports.CreateFiSellDealRequestRPC || (exports.CreateFiSellDealRequestRPC = {}));
