import React, { useState } from "react";
import {
  Chip,
  Dialog,
  Table,
  TableHeader,
  TableOptions,
  TableRowItems,
  useCornerRadius,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import {
  ChevronRight,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import {
  getFormattedAmount,
  getFormattedAmountString,
  getAmountOrUndefined,
} from "../../../../../utils";
import { FiTransactionType } from "@khazana/khazana-rpcs";
import { useFiContractNoteDetailsStore } from "../store/hooks";
import { useBorder } from "../../../../../utils/BorderUtils";
import { FiContractNoteFields } from "../models/FiContractNoteEditModel";
import {
  getRequestStatusValue,
  getFIYieldTypeValue,
} from "../../deal-request/utils/SearchUtils";
import { getContractNoteFieldValue } from "../utils/FiContractNoteUtils";

interface FiLinkDealRequestDialogProps {
  onClose: () => void;
  isOpen: boolean;
  onContinue: (dealRequestId: number | undefined) => void;
  selectedDealRequestId: number | undefined;
}

const Size = {
  row: {
    height: "56px",
    width: "200px",
    textWidth: "280px",
  },
  arrowIcon: {
    columnWidth: "64px",
    height: "16px",
    width: "16px",
  },
};

export const FiLinkDealRequestDialog = ({
  onClose,
  isOpen,
  onContinue,
  selectedDealRequestId,
}: FiLinkDealRequestDialogProps): React.ReactElement => {
  const { t } = useTranslation();
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const typography = useTypography();
  const detailsStore = useFiContractNoteDetailsStore();
  const [selectedRequestId, setSelectedRequestId] = useState<
    number | undefined
  >(selectedDealRequestId);

  const getHeader = (title: string): React.ReactElement => {
    return (
      <Stack
        sx={{
          borderBottom: border.default,
          paddingBottom: spacing.spaceXS,
        }}
      >
        <Typography sx={{ ...typography.s1, color: tokens.label }}>
          {title}
        </Typography>
      </Stack>
    );
  };

  const getDetailRow = (
    label: string,
    value: string,
    type: "text" | "chip" = "text",
  ): React.ReactElement => {
    return (
      <Stack
        sx={{
          borderBottom: border.default,
          minHeight: Size.row.height,
          justifyContent: "center",
        }}
      >
        <Stack direction={"row"}>
          <Typography
            width={Size.row.width}
            sx={{ ...typography.s1, color: tokens.label }}
          >
            {label}
          </Typography>
          {type === "text" ? (
            <Typography
              sx={{
                ...typography.b1,
                color: tokens.label,
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: Size.row.textWidth,
              }}
            >
              {value}
            </Typography>
          ) : (
            <Chip
              label={value}
              color={
                value === FiTransactionType.FiTransactionType.FI_PURCHASE
                  ? "blueSubtle"
                  : "orangeSubtle"
              }
            />
          )}
        </Stack>
      </Stack>
    );
  };

  const getTableHeaders = (): TableHeader => {
    return [
      {
        id: "dematAccountNumber",
        name: t("contractNotes.dematAccountNumber"),
        width: "max-content",
      },
      {
        id: "security",
        name: t("fi.security"),
        width: "max-content",
      },
      {
        id: "ytm",
        name: t("fi.ytmYtc"),
        width: "max-content",
      },
      {
        id: "amount",
        name: t("common.amountWithPostfixLabel", {
          val: detailsStore.linkDealStore.currencySymbol,
        }),
        width: "max-content",
        align: "right",
      },
      {
        id: "quantity",
        name: t("common.quantity"),
        width: "max-content",
        align: "right",
      },
      {
        id: "entity",
        name: t("common.entity"),
        width: "max-content",
      },
      {
        id: "requestId",
        name: t("common.requestId"),
        width: "max-content",
      },
      {
        id: "arrow",
        name: "",
        width: Size.arrowIcon.columnWidth,
      },
    ];
  };

  const getTableData = async (
    option: TableOptions<undefined>,
    setTotalItems: React.Dispatch<React.SetStateAction<number>>,
  ): Promise<string | TableRowItems> => {
    await detailsStore.linkDealStore.getApprovedEquityDealRequests(
      option.page ? option.page - 1 : 0,
      detailsStore.linkDealStore.itemsPerPage(),
      getContractNoteFieldValue(
        FiContractNoteFields.dematAccountNumber,
        detailsStore.details,
        "diff",
      )?.toString() ??
        getContractNoteFieldValue(
          FiContractNoteFields.dematAccountNumber,
          detailsStore.details,
          "original",
        )?.toString() ??
        "-",
      getContractNoteFieldValue(
        FiContractNoteFields.isin,
        detailsStore.details,
        "diff",
      )?.toString() ??
        getContractNoteFieldValue(
          FiContractNoteFields.isin,
          detailsStore.details,
          "original",
        )?.toString() ??
        "-",
      getContractNoteFieldValue(
        FiContractNoteFields.transactionType,
        detailsStore.details,
        "diff",
      )?.toString() ??
        getContractNoteFieldValue(
          FiContractNoteFields.transactionType,
          detailsStore.details,
          "original",
        )?.toString() ??
        "-",
    );
    setTotalItems(detailsStore.linkDealStore.totalItems);
    return detailsStore.linkDealStore.requests.map((request) => {
      return [
        {
          data: request.dematAccountNumber,
        },
        {
          data: request.security,
        },
        {
          data: getFormattedAmount(request.ytm, 4, 2) ?? "-",
        },
        {
          data: request.amountHCY ? getFormattedAmount(request.amountHCY) : "-",
          align: "right",
        },
        {
          data: request.quantity
            ? getFormattedAmountString(request.quantity)
            : "-",
          align: "right",
        },
        {
          data: request.entityName,
        },
        {
          data: request.dealRequestId,
        },
        {
          data: (
            <Stack sx={{ alignItems: "center" }}>
              <ChevronRight
                width={Size.arrowIcon.width}
                height={Size.arrowIcon.height}
                color={tokens.iconPrimary}
              />
            </Stack>
          ),
        },
      ];
    });
  };

  const getTableView = (): React.ReactElement => {
    return (
      <Stack spacing={spacing.spaceSM}>
        {getHeader(t("contractNotes.linkContractNoteTo"))}
        <Table
          name="selectDealRequest"
          styleOverrides={{
            divider: "cell",
          }}
          headers={getTableHeaders()}
          onTableOptionsChange={getTableData}
          viewOverrides={{
            empty: { message: t("common.noResultsFound") },
            loading: { message: t("common.retrievingDealRequests") },
          }}
          paginationOption={{
            itemsPerPage: detailsStore.linkDealStore.itemsPerPage(),
            getPageIndicatorText(startItem, endItem, totalItems): string {
              return t("common.paginationIndicationText", {
                startItem,
                endItem,
                totalItems,
              });
            },
          }}
          onRowClick={(_, index): void => {
            setSelectedRequestId(
              detailsStore.linkDealStore.requests[index].dealRequestId,
            );
          }}
        />
      </Stack>
    );
  };

  const getBanner = (): React.ReactElement => {
    const deal = detailsStore.linkDealStore.requests.find(
      (request) => request.dealRequestId === selectedRequestId,
    );
    const exceededAmount = deal?.amountHCY
      ? parseFloat(
          getContractNoteFieldValue(
            FiContractNoteFields.settlementAmount,
            detailsStore.details,
            "diff",
          )?.toString() ??
            getContractNoteFieldValue(
              FiContractNoteFields.settlementAmount,
              detailsStore.details,
              "original",
            )?.toString() ??
            "-",
        ) - (getAmountOrUndefined(deal.amountHCY) ?? 0)
      : 0;
    const exceededQuantity = deal?.quantity
      ? parseFloat(
          getContractNoteFieldValue(
            FiContractNoteFields.quantity,
            detailsStore.details,
            "diff",
          )?.toString() ??
            getContractNoteFieldValue(
              FiContractNoteFields.quantity,
              detailsStore.details,
              "original",
            )?.toString() ??
            "-",
        ) - (deal.quantity ?? 0)
      : 0;
    if (deal) {
      if (exceededQuantity > 0 || exceededAmount > 0) {
        return (
          <Typography
            sx={{
              ...typography.b1,
              color: tokens.labelWarning,
              background: tokens.backgroundWarningSubtle,
              padding: `${spacing.spaceXS} ${spacing.spaceMD}`,
              borderRadius: cornerRadius.radiusXXS,
              marginBottom: spacing.spaceLG,
            }}
          >
            {exceededQuantity && exceededQuantity > 0
              ? `${t(
                  "fi.contractNotes.exceedsDealQuantity",
                )}${getFormattedAmountString(exceededQuantity, 0, 0)}`
              : `${t("fi.contractNotes.exceedsDealAmount")}${
                  deal.currency.symbol
                } ${getFormattedAmountString(exceededAmount, 2, 0)}`}
          </Typography>
        );
      }
    }
    return <></>;
  };

  const getDetailView = (): React.ReactElement => {
    const deal = detailsStore.linkDealStore.requests.find(
      (request) => request.dealRequestId === selectedRequestId,
    );
    if (deal) {
      return (
        <Stack>
          {getBanner()}
          {getHeader(t("fi.fiDealRequestDetails"))}
          {getDetailRow(
            t("equity.dealRequestDetails.requestStatus"),
            getRequestStatusValue(t, deal.status),
          )}
          {getDetailRow(
            getFIYieldTypeValue(deal.yieldType),
            getFormattedAmount(deal.ytm, 4, 2),
          )}
          {getDetailRow(
            t("common.amountWithPostfixLabel", {
              val: detailsStore.linkDealStore.currencySymbol,
            }),
            deal.amountHCY ? getFormattedAmount(deal.amountHCY) : "-",
          )}
          {getDetailRow(
            t("common.quantity"),
            deal.quantity ? getFormattedAmountString(deal.quantity) : "-",
          )}
          {getDetailRow(
            t("contractNotes.transactionType"),
            deal.transactionType,
            "chip",
          )}
          {getDetailRow(t("common.requestId"), deal.dealRequestId.toString())}
          {getDetailRow(t("fi.security"), deal.security)}
          {getDetailRow(t("common.entity"), deal.entityName)}
          {getDetailRow(
            t("contractNotes.dematAccountNumber"),
            deal.dematAccountNumber,
          )}
        </Stack>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Dialog
      title={
        selectedRequestId
          ? t("contractNotes.verifyDealRequestDetails")
          : t("contractNotes.selectDealRequest")
      }
      open={isOpen}
      isCloseIconPresent={selectedRequestId ? true : false}
      onClose={onClose}
      onSecondaryButtonClick={(): void => {
        selectedRequestId ? setSelectedRequestId(undefined) : onClose();
      }}
      secondaryButtonText={
        selectedRequestId ? t("common.back") : t("common.close")
      }
      primaryButtonText={selectedRequestId ? t("common.continue") : undefined}
      onPrimaryButtonClick={async (): Promise<void> => {
        onContinue(selectedRequestId);
      }}
      dialogWidth={selectedRequestId ? "560px" : "960px"}
      disableBackdropClick={true}
    >
      <Stack width={"100%"} spacing={spacing.spaceLG}>
        <Stack>
          {getHeader(t("fi.contractNotes.contractNoteDetailTitle"))}
          {getDetailRow(
            t("fi.contractNotes.contractNoteNumber"),
            getContractNoteFieldValue(
              FiContractNoteFields.contractNoteNumber,
              detailsStore.details,
              "original",
            )?.toString() ?? "-",
          )}
          {getDetailRow(
            t("contractNotes.broker"),
            getContractNoteFieldValue(
              FiContractNoteFields.broker,
              detailsStore.details,
              "original",
            )?.toString() ?? "-",
          )}
        </Stack>
        {selectedRequestId ? getDetailView() : getTableView()}
      </Stack>
    </Dialog>
  );
};
