import { Instance, types } from "mobx-state-tree";
import {
  createRequestNoteModel,
  RequestNoteModel,
} from "../../../models/RequestNoteModel";
import { MFConfirmationHistoryDetail } from "@khazana/khazana-rpcs";
import { DiffDetailType } from "../../../models/DiffDetailType";
import { MFConfirmationStatus } from "./MFConfirmationStatus";

export const MFConfirmationHistoryDetailModel = types.model(
  "MFConfirmationHistoryDetailModel",
  {
    requestNote: types.maybeNull(RequestNoteModel),
    status: MFConfirmationStatus,
    diffData: types.array(DiffDetailType),
  },
);

export const createMFConfirmationHistoryDetailModel = (
  historyDetails: MFConfirmationHistoryDetail | null,
): Instance<typeof MFConfirmationHistoryDetailModel> => {
  if (historyDetails) {
    const { status, requestNote, diffDetails } = historyDetails;
    return MFConfirmationHistoryDetailModel.create({
      status,
      requestNote: createRequestNoteModel(requestNote),
      diffData: diffDetails.map((diffDetail) =>
        DiffDetailType.create(diffDetail),
      ),
    });
  }
  return MFConfirmationHistoryDetailModel.create();
};
