import { Instance } from "mobx-state-tree";
import {
  MFDealRequestSearchBy,
  MFDealRequestStatus,
  MFTransactionType,
} from "@khazana/khazana-rpcs";
import { AllEnum } from "../../../../../types/EnumTypes";
import { MFDealRequestsFilterOptionsModel } from "../store/DealRequestListStore/MFDealRequestsStore";
import i18next, { TFunction } from "i18next";
import { DropdownItem } from "@surya-digital/leo-reactjs-core";

export const getRequestStatusValue = (
  value: MFDealRequestStatus.MFDealRequestStatus,
): string => {
  const statusType = MFDealRequestStatus.MFDealRequestStatus;
  let requestStatus;
  switch (value) {
    case statusType.IC_PENDING:
      requestStatus = i18next.t("common.icPending");
      break;
    case statusType.IC_REJECTED:
      requestStatus = i18next.t("common.icRejected");
      break;
    case statusType.IC_APPROVED:
      requestStatus = i18next.t("common.icApproved");
      break;
    case statusType.ACCOUNTS_PENDING:
      requestStatus = i18next.t("common.accountsPending");
      break;
    case statusType.ACCOUNTS_APPROVED:
      requestStatus = i18next.t("common.accountsApproved");
      break;
    case statusType.ACCOUNTS_REJECTED:
      requestStatus = i18next.t("common.accountsRejected");
      break;
    case statusType.EXPIRED:
      requestStatus = i18next.t("common.expired");
      break;
    case statusType.CANCELLED:
      requestStatus = i18next.t("common.cancelled");
      break;
    case statusType.DEAL_OPEN:
      requestStatus = i18next.t("common.dealOpen");
      break;
    case statusType.SETTLED:
      requestStatus = i18next.t("common.settled");
      break;
    case statusType.SYSTEM_SETTLED:
      requestStatus = i18next.t("common.systemSettled");
      break;
  }
  return requestStatus;
};

export const getDealRequestSearchByValue = (
  t: TFunction,
  value: MFDealRequestSearchBy.MFDealRequestSearchBy,
): string => {
  const searchByType = MFDealRequestSearchBy.MFDealRequestSearchBy;
  switch (value) {
    case searchByType.REQUEST_ID:
      return t("common.requestId");
    case searchByType.PORTFOLIO:
      return t("common.portfolio");
    case searchByType.SCHEME:
      return t("mf.fields.schemeLabel");
    case searchByType.AMC:
      return t("mf.fields.amcLabel");
  }
};

export const getDealRequestSearchByOptions = (t: TFunction): DropdownItem[] => {
  const values = Object.values(MFDealRequestSearchBy.MFDealRequestSearchBy);
  return values.map((value: string): DropdownItem => {
    const name = getDealRequestSearchByValue(
      t,
      value as MFDealRequestSearchBy.MFDealRequestSearchBy,
    );
    return { name, value };
  });
};

export const getDealRequestStatusOptionsWithAll = (
  t: TFunction,
): DropdownItem[] => {
  const values = Object.keys(MFDealRequestStatus.MFDealRequestStatus);
  const dropdownItems = values.map((value: string): DropdownItem => {
    const name = getRequestStatusValue(
      value as MFDealRequestStatus.MFDealRequestStatus,
    );
    return { name, value };
  });
  return [
    {
      name: t("common.all"),
      value: AllEnum.All,
    },
    ...dropdownItems,
  ];
};

const getMFTransactionTypeValue = (
  t: TFunction,
  value: MFTransactionType.MFTransactionType,
): string => {
  const transactionType = MFTransactionType.MFTransactionType;
  switch (value) {
    case transactionType.PURCHASE:
      return t("mf.transactionType.purchaseLabel");
    case transactionType.REDEEM:
      return t("mf.transactionType.redeemLabel");
  }
};

export const getTransactionTypeOptions = (
  t: TFunction,
  includeAll = false,
): DropdownItem[] => {
  const values = Object.keys(MFTransactionType.MFTransactionType);
  const dropdownItems = values.map((value: string): DropdownItem => {
    const name = getMFTransactionTypeValue(
      t,
      value as MFTransactionType.MFTransactionType,
    );
    return { name, value };
  });
  const objects = [];
  if (includeAll) {
    objects.push({
      name: t("common.all"),
      value: AllEnum.All,
    });
  }
  dropdownItems.forEach((item) => objects.push(item));
  return objects;
};

export const getMFDealRequestsInitialFilter = (): Instance<
  typeof MFDealRequestsFilterOptionsModel
> => {
  return MFDealRequestsFilterOptionsModel.create({
    searchBy: MFDealRequestSearchBy.MFDealRequestSearchBy.REQUEST_ID,
    searchText: "",
    requestStatus: AllEnum.All,
    transactionType: AllEnum.All,
  });
};
