"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityRealisedGain = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidEquityRealisedGainError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityRealisedGainError";
    }
}
class EquityRealisedGain {
    constructor(symbol, soldValue, realisedPnl, changePercentage, irr) {
        this.isEquityRealisedGain = true;
        EquityRealisedGain.validateSymbol(symbol);
        this.symbol = symbol;
        EquityRealisedGain.validateSoldValue(soldValue);
        this.soldValue = soldValue;
        EquityRealisedGain.validateRealisedPnl(realisedPnl);
        this.realisedPnl = realisedPnl;
        EquityRealisedGain.validateChangePercentage(changePercentage);
        this.changePercentage = changePercentage;
        EquityRealisedGain.validateIrr(irr);
        this.irr = irr;
    }
    static fromDTO(dto) {
        const symbol = (0, leo_ts_runtime_1.getString)(dto, "symbol", InvalidEquityRealisedGainError);
        const soldValue = (0, leo_ts_runtime_1.getInt64)(dto, "soldValue", InvalidEquityRealisedGainError);
        const realisedPnl = (0, leo_ts_runtime_1.getInt64)(dto, "realisedPnl", InvalidEquityRealisedGainError);
        const changePercentage = (0, leo_ts_runtime_1.getInt64)(dto, "changePercentage", InvalidEquityRealisedGainError);
        const irr = (0, leo_ts_runtime_1.getInt64)(dto, "irr", InvalidEquityRealisedGainError);
        return new EquityRealisedGain(symbol, soldValue, realisedPnl, changePercentage, irr);
    }
    toDTO() {
        const ret = {
            symbol: this.symbol,
            soldValue: this.soldValue,
            realisedPnl: this.realisedPnl,
            changePercentage: this.changePercentage,
            irr: this.irr,
        };
        return ret;
    }
    copy(symbol = this.symbol, soldValue = this.soldValue, realisedPnl = this.realisedPnl, changePercentage = this.changePercentage, irr = this.irr) {
        return new EquityRealisedGain(symbol, soldValue, realisedPnl, changePercentage, irr);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityRealisedGainValue = other.isEquityRealisedGain;
        if (!(other instanceof EquityRealisedGain) || Boolean(isEquityRealisedGainValue)) {
            return false;
        }
        if (this.symbol !== other.symbol) {
            return false;
        }
        if (this.soldValue !== other.soldValue) {
            return false;
        }
        if (this.realisedPnl !== other.realisedPnl) {
            return false;
        }
        if (this.changePercentage !== other.changePercentage) {
            return false;
        }
        if (this.irr !== other.irr) {
            return false;
        }
        return true;
    }
}
exports.EquityRealisedGain = EquityRealisedGain;
EquityRealisedGain.validateSymbol = (symbol) => {
    if (!(0, leo_ts_runtime_1.isString)(symbol)) {
        throw new InvalidEquityRealisedGainError(`Attribute symbol is not a String`);
    }
    if (symbol.trim().length === 0) {
        throw new InvalidEquityRealisedGainError(`Attribute symbol cannot be an empty string.`);
    }
};
EquityRealisedGain.validateSoldValue = (soldValue) => {
    if (!(0, leo_ts_runtime_1.isInt64)(soldValue)) {
        throw new InvalidEquityRealisedGainError(`Attribute soldValue is not an Int64`);
    }
};
EquityRealisedGain.validateRealisedPnl = (realisedPnl) => {
    if (!(0, leo_ts_runtime_1.isInt64)(realisedPnl)) {
        throw new InvalidEquityRealisedGainError(`Attribute realisedPnl is not an Int64`);
    }
};
EquityRealisedGain.validateChangePercentage = (changePercentage) => {
    if (!(0, leo_ts_runtime_1.isInt64)(changePercentage)) {
        throw new InvalidEquityRealisedGainError(`Attribute changePercentage is not an Int64`);
    }
};
EquityRealisedGain.validateIrr = (irr) => {
    if (!(0, leo_ts_runtime_1.isInt64)(irr)) {
        throw new InvalidEquityRealisedGainError(`Attribute irr is not an Int64`);
    }
};
EquityRealisedGain.prototype.toString = function toString() {
    return `EquityRealisedGain(symbol='${this.symbol}',soldValue=${this.soldValue},realisedPnl=${this.realisedPnl},changePercentage=${this.changePercentage},irr=${this.irr})`;
};
