"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetActiveAccrualsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const commonItemsPerPage_1 = require("./commonItemsPerPage");
const commonPageIndex_1 = require("./commonPageIndex");
const getActiveAccrualsPaginationResponse_1 = require("./getActiveAccrualsPaginationResponse");
/* eslint-disable import/export */
class GetActiveAccrualsRPC {
}
exports.GetActiveAccrualsRPC = GetActiveAccrualsRPC;
(function (GetActiveAccrualsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetActiveAccrualsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(moduleName = null, correlationId = null, itemsPerPage, pageIndex) {
            this.isGetActiveAccrualsRPCRequest = true;
            if (moduleName !== undefined && moduleName !== null) {
                Request.validateModuleName(moduleName);
            }
            this.moduleName = moduleName;
            if (correlationId !== undefined && correlationId !== null) {
                Request.validateCorrelationId(correlationId);
            }
            this.correlationId = correlationId;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const moduleName = (0, leo_ts_runtime_1.getOptionalString)(dto, "moduleName", InvalidRequestError);
            const correlationId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "correlationId", InvalidRequestError);
            const itemsPerPage = commonItemsPerPage_1.CommonItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = commonPageIndex_1.CommonPageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(moduleName, correlationId, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.moduleName !== undefined && this.moduleName !== null) {
                ret.moduleName = this.moduleName;
            }
            if (this.correlationId !== undefined && this.correlationId !== null) {
                ret.correlationId = this.correlationId;
            }
            return ret;
        }
        copy(moduleName = this.moduleName, correlationId = this.correlationId, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(moduleName, correlationId, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetActiveAccrualsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.moduleName !== other.moduleName) {
                return false;
            }
            if (this.correlationId !== other.correlationId) {
                return false;
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validateModuleName = (moduleName) => {
        if (!(0, leo_ts_runtime_1.isString)(moduleName)) {
            throw new InvalidRequestError(`Attribute moduleName is not a String`);
        }
        if (moduleName.trim().length === 0) {
            throw new InvalidRequestError(`Attribute moduleName cannot be an empty string.`);
        }
    };
    Request.validateCorrelationId = (correlationId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(correlationId)) {
            throw new InvalidRequestError(`Attribute correlationId is not an Int64`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isCommonItemsPerPageValue = itemsPerPage.isCommonItemsPerPage;
        if (!(itemsPerPage instanceof commonItemsPerPage_1.CommonItemsPerPage || Boolean(isCommonItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a CommonItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isCommonPageIndexValue = pageIndex.isCommonPageIndex;
        if (!(pageIndex instanceof commonPageIndex_1.CommonPageIndex || Boolean(isCommonPageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a CommonPageIndex`);
        }
    };
    GetActiveAccrualsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetActiveAccrualsRPC.Request(moduleName='${this.moduleName}',correlationId=${this.correlationId},itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetActiveAccrualsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getActiveAccrualsPaginationResponse, totalItems) {
            this.isGetActiveAccrualsRPCResponse = true;
            Response.validateGetActiveAccrualsPaginationResponse(getActiveAccrualsPaginationResponse);
            this.getActiveAccrualsPaginationResponse = getActiveAccrualsPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getActiveAccrualsPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getActiveAccrualsPaginationResponse", getActiveAccrualsPaginationResponse_1.GetActiveAccrualsPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getActiveAccrualsPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getActiveAccrualsPaginationResponse: this.getActiveAccrualsPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getActiveAccrualsPaginationResponse = this.getActiveAccrualsPaginationResponse, totalItems = this.totalItems) {
            return new Response(getActiveAccrualsPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetActiveAccrualsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getActiveAccrualsPaginationResponse, other.getActiveAccrualsPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetActiveAccrualsPaginationResponse = (getActiveAccrualsPaginationResponse) => {
        if (!Array.isArray(getActiveAccrualsPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getActiveAccrualsPaginationResponse is not a List`);
        }
        for (let i = 0; i < getActiveAccrualsPaginationResponse.length; i += 1) {
            const isGetActiveAccrualsPaginationResponseValue = getActiveAccrualsPaginationResponse[i].isGetActiveAccrualsPaginationResponse;
            if (!(getActiveAccrualsPaginationResponse[i] instanceof getActiveAccrualsPaginationResponse_1.GetActiveAccrualsPaginationResponse || Boolean(isGetActiveAccrualsPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getActiveAccrualsPaginationResponse at index ${i} is not a GetActiveAccrualsPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetActiveAccrualsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetActiveAccrualsRPC.Response(getActiveAccrualsPaginationResponse=${this.getActiveAccrualsPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetActiveAccrualsRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetActiveAccrualsRPC.Errors || (GetActiveAccrualsRPC.Errors = {}));
})(GetActiveAccrualsRPC = exports.GetActiveAccrualsRPC || (exports.GetActiveAccrualsRPC = {}));
