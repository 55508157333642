"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFdCashflowsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fixedDepositItemsPerPage_1 = require("./fixedDepositItemsPerPage");
const fixedDepositPageIndex_1 = require("./fixedDepositPageIndex");
const getFdCashflowsPaginationResponse_1 = require("./getFdCashflowsPaginationResponse");
/* eslint-disable import/export */
class GetFdCashflowsRPC {
}
exports.GetFdCashflowsRPC = GetFdCashflowsRPC;
(function (GetFdCashflowsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFdCashflowsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(purchaseTransactionId = null, itemsPerPage, pageIndex) {
            this.isGetFdCashflowsRPCRequest = true;
            if (purchaseTransactionId !== undefined && purchaseTransactionId !== null) {
                Request.validatePurchaseTransactionId(purchaseTransactionId);
            }
            this.purchaseTransactionId = purchaseTransactionId;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const purchaseTransactionId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "purchaseTransactionId", InvalidRequestError);
            const itemsPerPage = fixedDepositItemsPerPage_1.FixedDepositItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = fixedDepositPageIndex_1.FixedDepositPageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(purchaseTransactionId, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.purchaseTransactionId !== undefined && this.purchaseTransactionId !== null) {
                ret.purchaseTransactionId = this.purchaseTransactionId;
            }
            return ret;
        }
        copy(purchaseTransactionId = this.purchaseTransactionId, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(purchaseTransactionId, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFdCashflowsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.purchaseTransactionId !== other.purchaseTransactionId) {
                return false;
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validatePurchaseTransactionId = (purchaseTransactionId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(purchaseTransactionId)) {
            throw new InvalidRequestError(`Attribute purchaseTransactionId is not an Int64`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isFixedDepositItemsPerPageValue = itemsPerPage.isFixedDepositItemsPerPage;
        if (!(itemsPerPage instanceof fixedDepositItemsPerPage_1.FixedDepositItemsPerPage || Boolean(isFixedDepositItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a FixedDepositItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isFixedDepositPageIndexValue = pageIndex.isFixedDepositPageIndex;
        if (!(pageIndex instanceof fixedDepositPageIndex_1.FixedDepositPageIndex || Boolean(isFixedDepositPageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a FixedDepositPageIndex`);
        }
    };
    GetFdCashflowsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFdCashflowsRPC.Request(purchaseTransactionId=${this.purchaseTransactionId},itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFdCashflowsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getFdCashflowsPaginationResponse, totalItems) {
            this.isGetFdCashflowsRPCResponse = true;
            Response.validateGetFdCashflowsPaginationResponse(getFdCashflowsPaginationResponse);
            this.getFdCashflowsPaginationResponse = getFdCashflowsPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getFdCashflowsPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getFdCashflowsPaginationResponse", getFdCashflowsPaginationResponse_1.GetFdCashflowsPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getFdCashflowsPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getFdCashflowsPaginationResponse: this.getFdCashflowsPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getFdCashflowsPaginationResponse = this.getFdCashflowsPaginationResponse, totalItems = this.totalItems) {
            return new Response(getFdCashflowsPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFdCashflowsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getFdCashflowsPaginationResponse, other.getFdCashflowsPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetFdCashflowsPaginationResponse = (getFdCashflowsPaginationResponse) => {
        if (!Array.isArray(getFdCashflowsPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getFdCashflowsPaginationResponse is not a List`);
        }
        for (let i = 0; i < getFdCashflowsPaginationResponse.length; i += 1) {
            const isGetFdCashflowsPaginationResponseValue = getFdCashflowsPaginationResponse[i].isGetFdCashflowsPaginationResponse;
            if (!(getFdCashflowsPaginationResponse[i] instanceof getFdCashflowsPaginationResponse_1.GetFdCashflowsPaginationResponse || Boolean(isGetFdCashflowsPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getFdCashflowsPaginationResponse at index ${i} is not a GetFdCashflowsPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetFdCashflowsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFdCashflowsRPC.Response(getFdCashflowsPaginationResponse=${this.getFdCashflowsPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetFdCashflowsRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFdCashflowsRPC.Errors || (GetFdCashflowsRPC.Errors = {}));
})(GetFdCashflowsRPC = exports.GetFdCashflowsRPC || (exports.GetFdCashflowsRPC = {}));
