import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  EquityHoldings,
  GetEquityTopTenHoldingsRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetEquityTopTenHoldingsRPC } from "../rpcs/RPC";
import {
  createCurrencyModel,
  CurrencyModel,
} from "../../../models/CurrencyModel";

export const HoldingsDistributionModel = types.model(
  "HoldingsDistributionModel",
  {
    symbol: types.maybe(types.string),
    currentValue: types.maybe(types.number),
    pnl: types.maybe(types.number),
    changePercentage: types.maybe(types.number),
    irr: types.maybe(types.number),
  },
);

export const createHoldingsDistributionModel = (
  data?: EquityHoldings,
): Instance<typeof HoldingsDistributionModel> => {
  if (data) {
    return HoldingsDistributionModel.create({
      symbol: data.symbol,
      currentValue: data.currentValue,
      pnl: data.pnl,
      changePercentage: data.changePercentage,
      irr: data.irr,
    });
  }
  return HoldingsDistributionModel.create();
};
export const EquityDashboardTopHoldingsStore = types
  .model("EquityDashboardTopHoldingsStore", {
    list: types.array(HoldingsDistributionModel),
    currency: types.maybe(CurrencyModel),
    sortBy: types.enumeration(
      "SortBy",
      Object.values(GetEquityTopTenHoldingsRPC.RequestEnums.Sort.Sort),
    ),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      onSortByChange: (
        sort: GetEquityTopTenHoldingsRPC.RequestEnums.Sort.Sort,
      ): void => {
        store.sortBy = sort;
      },
      getEquityTopTenHoldings: flow(function* () {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetEquityTopTenHoldingsRPC.Request(
          null,
          null,
          store.sortBy,
        );
        const result: LeoRPCResult<
          GetEquityTopTenHoldingsRPC.Response,
          GetEquityTopTenHoldingsRPC.Errors.Errors
        > = yield useGetEquityTopTenHoldingsRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.list = cast(
            response.topTenHoldings.map((holding: EquityHoldings) =>
              createHoldingsDistributionModel(holding),
            ),
          );
          store.currency = createCurrencyModel(response.currency);
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            default:
              logger.error(
                `Unhandled error: ${error} occurred in getEquityTopTenHoldingsRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
      }),
    };
  });

export const createEquityDashboardTopHoldingsStore = (): Instance<
  typeof EquityDashboardTopHoldingsStore
> => {
  return EquityDashboardTopHoldingsStore.create({
    sortBy: GetEquityTopTenHoldingsRPC.RequestEnums.Sort.Sort.GAIN,
  });
};
