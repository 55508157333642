"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityHoldings = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidEquityHoldingsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityHoldingsError";
    }
}
class EquityHoldings {
    constructor(symbol, currentValue, pnl, changePercentage, irr) {
        this.isEquityHoldings = true;
        EquityHoldings.validateSymbol(symbol);
        this.symbol = symbol;
        EquityHoldings.validateCurrentValue(currentValue);
        this.currentValue = currentValue;
        EquityHoldings.validatePnl(pnl);
        this.pnl = pnl;
        EquityHoldings.validateChangePercentage(changePercentage);
        this.changePercentage = changePercentage;
        EquityHoldings.validateIrr(irr);
        this.irr = irr;
    }
    static fromDTO(dto) {
        const symbol = (0, leo_ts_runtime_1.getString)(dto, "symbol", InvalidEquityHoldingsError);
        const currentValue = (0, leo_ts_runtime_1.getInt64)(dto, "currentValue", InvalidEquityHoldingsError);
        const pnl = (0, leo_ts_runtime_1.getInt64)(dto, "pnl", InvalidEquityHoldingsError);
        const changePercentage = (0, leo_ts_runtime_1.getInt64)(dto, "changePercentage", InvalidEquityHoldingsError);
        const irr = (0, leo_ts_runtime_1.getInt64)(dto, "irr", InvalidEquityHoldingsError);
        return new EquityHoldings(symbol, currentValue, pnl, changePercentage, irr);
    }
    toDTO() {
        const ret = {
            symbol: this.symbol,
            currentValue: this.currentValue,
            pnl: this.pnl,
            changePercentage: this.changePercentage,
            irr: this.irr,
        };
        return ret;
    }
    copy(symbol = this.symbol, currentValue = this.currentValue, pnl = this.pnl, changePercentage = this.changePercentage, irr = this.irr) {
        return new EquityHoldings(symbol, currentValue, pnl, changePercentage, irr);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityHoldingsValue = other.isEquityHoldings;
        if (!(other instanceof EquityHoldings) || Boolean(isEquityHoldingsValue)) {
            return false;
        }
        if (this.symbol !== other.symbol) {
            return false;
        }
        if (this.currentValue !== other.currentValue) {
            return false;
        }
        if (this.pnl !== other.pnl) {
            return false;
        }
        if (this.changePercentage !== other.changePercentage) {
            return false;
        }
        if (this.irr !== other.irr) {
            return false;
        }
        return true;
    }
}
exports.EquityHoldings = EquityHoldings;
EquityHoldings.validateSymbol = (symbol) => {
    if (!(0, leo_ts_runtime_1.isString)(symbol)) {
        throw new InvalidEquityHoldingsError(`Attribute symbol is not a String`);
    }
    if (symbol.trim().length === 0) {
        throw new InvalidEquityHoldingsError(`Attribute symbol cannot be an empty string.`);
    }
};
EquityHoldings.validateCurrentValue = (currentValue) => {
    if (!(0, leo_ts_runtime_1.isInt64)(currentValue)) {
        throw new InvalidEquityHoldingsError(`Attribute currentValue is not an Int64`);
    }
};
EquityHoldings.validatePnl = (pnl) => {
    if (!(0, leo_ts_runtime_1.isInt64)(pnl)) {
        throw new InvalidEquityHoldingsError(`Attribute pnl is not an Int64`);
    }
};
EquityHoldings.validateChangePercentage = (changePercentage) => {
    if (!(0, leo_ts_runtime_1.isInt64)(changePercentage)) {
        throw new InvalidEquityHoldingsError(`Attribute changePercentage is not an Int64`);
    }
};
EquityHoldings.validateIrr = (irr) => {
    if (!(0, leo_ts_runtime_1.isInt64)(irr)) {
        throw new InvalidEquityHoldingsError(`Attribute irr is not an Int64`);
    }
};
EquityHoldings.prototype.toString = function toString() {
    return `EquityHoldings(symbol='${this.symbol}',currentValue=${this.currentValue},pnl=${this.pnl},changePercentage=${this.changePercentage},irr=${this.irr})`;
};
