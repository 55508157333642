import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { ModuleType } from "@khazana/khazana-rpcs";
import { Period } from "../../../utils/ReportUtils";
import { ReportTablePage } from "../../../components/reports/ReportTablePage";
import {
  LoadingIndicator,
  TableReloadHandle,
  TableSortOption,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  ReportTableData,
  ReportTableHeaderProps,
} from "../../../components/reports/ReportTable";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { observer } from "mobx-react";
import { useFdInvestmentRegisterStore } from "../store/hooks";
import { downloadFile } from "../../../../../utils/FileDownloadUtils";

export const FdInvestmentRegister = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const store = useFdInvestmentRegisterStore();
  const [viewFilter, setViewFilter] = useState(false);
  const tableRef = useRef<TableReloadHandle>();

  const getInvestmentRegisterDetails = useCallback(async () => {
    await store.getInvestmentRegisterSummary(
      0,
      store.itemsPerPage(),
      undefined,
    );
  }, []);

  useEffect(() => {
    getInvestmentRegisterDetails();

    return () => {
      store.reset();
    };
  }, []);

  const headers: ReportTableHeaderProps[] = [
    {
      name: t("fd.reports.investmentRegisterHeaders.investmentId"),
      sortable: true,
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.depositNumber"),
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.bank"),
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.openingAmount"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.purchaseAmount"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.closingAmount"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.cumulative"),
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.daysInHolding"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.interestRate"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.maturityAmount"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.startDate"),
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.maturityDate"),
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.isCallable"),
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.status"),
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.openingTaxes"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.openingTds"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.interestReceived"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.tdsPaid"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.otherTaxesPaid"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.netInterestReceived"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.interestAccrued"),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.tdsOnInterestAccrued"),
      alignment: "right",
    },
    {
      name: t(
        "fd.reports.investmentRegisterHeaders.otherTaxesOnInterestAccrued",
      ),
      alignment: "right",
    },
    {
      name: t("fd.reports.investmentRegisterHeaders.netInterestReceivable"),
      alignment: "right",
    },
  ];

  const getRows = async (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ): Promise<ReportTableData> => {
    if (
      store.entityDropdownStore.selectedEntity &&
      store.portfolioDropdownStore.selectedPortfolio
    ) {
      await store.getInvestmentRegisterSummary(page, itemsPerPage, sort);
    }

    return {
      rows: store.fdInvestmentRegisterSummaryList.map((item) => {
        return [
          item.investmentId,
          item.depositNumber,
          item.bankName,
          item.openingAmount,
          item.purchaseAmount,
          item.closingAmount,
          store.getFdTypeText(item.fdType),
          item.daysInHolding,
          item.interestRate,
          item.maturityAmount,
          item.startDate,
          item.maturityDate,
          store.getIsCallableText(item.isCallable),
          item.status,
          item.openingTaxes,
          item.openingTds,
          item.interestReceived,
          item.tdsPaid,
          item.otherTaxesPaid,
          item.netInterestReceived,
          item.interestAccrued,
          item.tdsOnInterestAccrued,
          item.otherTaxesOnInterestAccrued,
          item.netInterestReceivable,
        ];
      }),
      totalItems: store.totalItems,
    };
  };

  return (
    <>
      {store.isScreenLoading && (
        <LoadingIndicator isLoading={store.isScreenLoading} />
      )}
      <ErrorDialog
        title={t("errors.internalServerError")}
        errorMessage={t("errors.internalServerErrorDescription")}
        isErrorDialogOpen={store.hasError}
        onClose={(): void => {
          store.clearError();
        }}
      />
      {store.isDataFetched && (
        <ReportTablePage
          pageHeader={{
            title: t("fd.reports.investmentRegisterReportLabel"),
          }}
          reloadTable={tableRef as RefObject<TableReloadHandle>}
          previousPage={{
            label: t("common.reports"),
            onLabelClick: (): void => {
              navigate(getPath(Module.Fd, Route.Reports), {
                replace: true,
              });
            },
          }}
          onEditFilter={(open) => setViewFilter(open)}
          reportFilterDialogProps={{
            isAllOptionEnabled: false,
            open: viewFilter,
            onClose: () => setViewFilter(false),
            onViewReport: async (period): Promise<void> => {
              tableRef.current?.reload();
              await store.getInvestmentRegisterSummary(
                0,
                store.itemsPerPage(),
                undefined,
                period,
              );
              return setViewFilter(false);
            },
            module: ModuleType.ModuleType.FIXED_DEPOSIT,
            showReportType: false,
            period: {
              reportType: Period.BETWEEN_TWO_DATES,
              asOnDate: null,
              betweenTwoDatesDateRange: store.getDateRange(),
            },
            entityDropdownStore: store.entityDropdownStore,
            portfolioDropdownStore: store.portfolioDropdownStore,
            additionalFields: [],
          }}
          headers={headers}
          getRows={getRows}
          itemsPerPage={store.itemsPerPage()}
          summary={undefined}
          isExportable={true}
          onExport={async (exportFormat): Promise<void> => {
            if (
              store.entityDropdownStore.selectedEntity &&
              store.portfolioDropdownStore.selectedPortfolio
            ) {
              await store.getDownloadURL(exportFormat);
              if (store.downloadURL) {
                downloadFile(store.downloadURL);
              }
            }
            return Promise.resolve();
          }}
          downloadButtonDisabled={
            store.fdInvestmentRegisterSummaryList.length === 0
          }
        />
      )}
    </>
  );
});
