import React, { useState } from "react";
import {
  Chip,
  Dialog,
  Table,
  TableHeader,
  TableOptions,
  TableRowItems,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import {
  ChevronRight,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import { MFTransactionType } from "@khazana/khazana-rpcs";
import { useBorder } from "../../../../../utils/BorderUtils";
import { useMFConfirmationRequestDetailsStore } from "../store/confirmationRequestDetailsStore/hooks";
import { getDiffDetailCellValue } from "../../../utils/DiffDetailCellUtils";
import { MFConfirmationDetailsKeys } from "../models/MFConfirmationDetailsKeys";
import { getMFRequestStatusValue } from "../../deal-request/utils/UIUtils";

interface MFLinkDealRequestDialogProps {
  onClose: () => void;
  isOpen: boolean;
  onContinue: (dealRequestId: number | undefined) => void;
  selectedDealRequestId: number | undefined;
}

const Size = {
  row: {
    height: "56px",
    width: "200px",
    textWidth: "280px",
  },
  arrowIcon: {
    columnWidth: "64px",
    height: "16px",
    width: "16px",
  },
};

export const MFLinkDealRequestDialog = ({
  onClose,
  isOpen,
  onContinue,
  selectedDealRequestId,
}: MFLinkDealRequestDialogProps): React.ReactElement => {
  const { t } = useTranslation();
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const spacing = useSpacing();
  const typography = useTypography();
  const detailsStore = useMFConfirmationRequestDetailsStore();
  const [selectedRequestId, setSelectedRequestId] = useState<
    number | undefined
  >(selectedDealRequestId);

  const getHeader = (title: string): React.ReactElement => {
    return (
      <Stack
        sx={{
          borderBottom: border.default,
          paddingBottom: spacing.spaceXS,
        }}
      >
        <Typography sx={{ ...typography.s1, color: tokens.label }}>
          {title}
        </Typography>
      </Stack>
    );
  };

  const getDetailRow = (
    label: string,
    value: string,
    type: "text" | "chip" = "text",
  ): React.ReactElement => {
    return (
      <Stack
        sx={{
          borderBottom: border.default,
          minHeight: Size.row.height,
          justifyContent: "center",
        }}
      >
        <Stack direction={"row"}>
          <Typography
            width={Size.row.width}
            sx={{ ...typography.s1, color: tokens.labelLowEmphasis }}
          >
            {label}
          </Typography>
          {type === "text" ? (
            <Typography
              sx={{
                ...typography.b1,
                color: tokens.label,
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: Size.row.textWidth,
              }}
            >
              {value}
            </Typography>
          ) : (
            <Chip
              label={value}
              color={
                value === MFTransactionType.MFTransactionType.PURCHASE
                  ? "blueSubtle"
                  : "orangeSubtle"
              }
            />
          )}
        </Stack>
      </Stack>
    );
  };

  const getTableHeaders = (): TableHeader => {
    return [
      {
        id: "folioNumber",
        name: t("mf.fields.folioNumberLabel"),
        width: "max-content",
      },
      {
        id: "scheme",
        name: t("mf.fields.schemeLabel"),
        width: "max-content",
      },
      {
        id: "amount",
        name: t("common.amountWithPostfixLabel", {
          val: detailsStore.linkDealStore.currencySymbol,
        }),
        width: "max-content",
        align: "right",
      },
      {
        id: "units",
        name: t("mf.fields.unitsLabel"),
        width: "max-content",
        align: "right",
      },
      {
        id: "entity",
        name: t("common.entity"),
        width: "max-content",
      },
      {
        id: "requestId",
        name: t("common.requestId"),
        width: "max-content",
      },
      {
        id: "arrow",
        name: "",
        width: Size.arrowIcon.columnWidth,
      },
    ];
  };

  const getTableData = async (
    option: TableOptions<undefined>,
    setTotalItems: React.Dispatch<React.SetStateAction<number>>,
  ): Promise<string | TableRowItems> => {
    const folioNumber = getDiffDetailCellValue(
      detailsStore.details,
      MFConfirmationDetailsKeys.folioNumber,
    );
    const isin = getDiffDetailCellValue(
      detailsStore.details,
      MFConfirmationDetailsKeys.isin,
    );
    const transactionType = getDiffDetailCellValue(
      detailsStore.details,
      MFConfirmationDetailsKeys.transactionType,
    );
    await detailsStore.linkDealStore.getMFApprovedDealRequests(
      option.page ? option.page - 1 : 0,
      detailsStore.linkDealStore.itemsPerPage(),
      folioNumber?.diffValue?.toString() ??
        folioNumber?.value?.toString() ??
        "-",
      isin?.diffValue?.toString() ?? isin?.value?.toString() ?? "-",
      transactionType?.diffValue?.toString() ??
        transactionType?.value?.toString() ??
        "-",
    );
    setTotalItems(detailsStore.linkDealStore.totalItems);
    return detailsStore.linkDealStore.requests.map((request) => {
      return [
        {
          data: request.folioNumber,
        },
        {
          data: request.schemeName,
        },
        {
          data: t("common.decimal2", { val: request.amountHCY }),
          align: "right",
        },
        {
          data: t("common.decimal4", { val: request.units }),
          align: "right",
        },
        {
          data: request.entityName,
        },
        {
          data: request.dealRequestId,
        },
        {
          data: (
            <Stack sx={{ alignItems: "center" }}>
              <ChevronRight
                width={Size.arrowIcon.width}
                height={Size.arrowIcon.height}
                color={tokens.iconPrimary}
              />
            </Stack>
          ),
        },
      ];
    });
  };

  const getTableView = (): React.ReactElement => {
    return (
      <Stack spacing={spacing.spaceSM}>
        {getHeader(t("mf.confirmationRequest.linkConfirmationToTitle"))}
        <Table
          name="selectDealRequest"
          styleOverrides={{
            divider: "cell",
          }}
          headers={getTableHeaders()}
          onTableOptionsChange={getTableData}
          viewOverrides={{
            empty: { message: t("common.noResultsFound") },
            loading: { message: t("common.retrievingDealRequests") },
          }}
          paginationOption={{
            itemsPerPage: detailsStore.linkDealStore.itemsPerPage(),
            getPageIndicatorText(startItem, endItem, totalItems): string {
              return t("common.paginationIndicationText", {
                startItem,
                endItem,
                totalItems,
              });
            },
          }}
          onRowClick={(_, index): void => {
            setSelectedRequestId(
              detailsStore.linkDealStore.requests[index].dealRequestId,
            );
          }}
        />
      </Stack>
    );
  };

  const getDetailView = (): React.ReactElement => {
    const deal = detailsStore.linkDealStore.requests.find(
      (request) => request.dealRequestId === selectedRequestId,
    );
    if (deal) {
      return (
        <Stack>
          {getHeader(t("mf.confirmationRequest.dealDetailsSubtitle"))}
          {getDetailRow(
            t("common.requestStatus"),
            getMFRequestStatusValue(deal.status),
          )}
          {getDetailRow(
            t("common.amountWithPostfixLabel", {
              val: detailsStore.linkDealStore.currencySymbol,
            }),
            t("common.decimal2", { val: deal.amountHCY }),
          )}
          {getDetailRow(
            t("mf.fields.unitsLabel"),
            t("common.decimal4", { val: deal.units }),
          )}
          {getDetailRow(
            t("contractNotes.transactionType"),
            deal.transactionType,
            "chip",
          )}
          {getDetailRow(t("common.requestId"), deal.dealRequestId.toString())}
          {getDetailRow(t("mf.fields.schemeLabel"), deal.schemeName)}
          {getDetailRow(t("common.entity"), deal.entityName)}
          {getDetailRow(t("mf.fields.folioNumberLabel"), deal.folioNumber)}
        </Stack>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Dialog
      title={
        selectedRequestId
          ? t("contractNotes.verifyDealRequestDetails")
          : t("contractNotes.selectDealRequest")
      }
      open={isOpen}
      isCloseIconPresent={selectedRequestId ? true : false}
      onClose={onClose}
      onSecondaryButtonClick={(): void => {
        selectedRequestId ? setSelectedRequestId(undefined) : onClose();
      }}
      secondaryButtonText={
        selectedRequestId ? t("common.back") : t("common.close")
      }
      primaryButtonText={selectedRequestId ? t("common.continue") : undefined}
      onPrimaryButtonClick={async (): Promise<void> => {
        onContinue(selectedRequestId);
      }}
      dialogWidth={selectedRequestId ? "560px" : "960px"}
      disableBackdropClick={true}
    >
      <Stack width={"100%"} spacing={spacing.spaceLG}>
        <Stack>
          {getHeader(t("mf.confirmationRequest.confirmationDetailsSubtitle"))}
          {getDetailRow(
            t("mf.fields.referenceNumberLabel"),
            getDiffDetailCellValue(
              detailsStore.details,
              MFConfirmationDetailsKeys.referenceNumber,
            )?.value?.toString() ?? "-",
          )}
          {getDetailRow(
            t("common.broker"),
            getDiffDetailCellValue(
              detailsStore.details,
              MFConfirmationDetailsKeys.broker,
            )?.value?.toString() ?? "-",
          )}
          {getDetailRow(
            t("mf.fields.amcNameLabel"),
            getDiffDetailCellValue(
              detailsStore.details,
              MFConfirmationDetailsKeys.amc,
            )?.value?.toString() ?? "-",
          )}
        </Stack>
        {selectedRequestId ? getDetailView() : getTableView()}
      </Stack>
    </Dialog>
  );
};
